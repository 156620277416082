import styled from 'styled-components';

import { NumericInput, Stepper } from 'components';
import { COLORS } from 'constants/styles';

export const StyledStepper = styled(Stepper)`
  margin-top: 50px;
  margin-bottom: 37px;
`;

export const Text = styled.p`
  font-family: PT Sans;
  font-size: 14px;
  color: ${COLORS.GRAY_700};
`;

export const Title = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const Styled2FAInput = styled(NumericInput)`
  margin-top: 30px;
`;
