import Alert from '@mui/material/Alert';
import styled from 'styled-components';

import { Button } from 'components';
import CopyText from 'components/CopyText';
import { COLORS, GRADIENTS, SHADOWS } from 'constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background: ${GRADIENTS.SECONDARY};
  box-shadow: ${SHADOWS.SECONDARY};
  border-radius: 16px;
  padding: 22px 37px 15px 37px;
`;

export const CurrencyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const CurrencyAmount = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: ${COLORS.WHITE};
  align-items: center;
`;

export const CurrencyInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

export const StyledCurrencyAddress = styled(CopyText)`
  display: flex;
  color: ${COLORS.GRAY_300};
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
  letter-spacing: 0.01em;
  svg {
    font-size: 16px;
  }
`;

export const IconWrapper = styled.div`
  flex: 0 0 68px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  border-top: 1px solid #87abb8;
  padding-top: 16px;
  margin-top: 15px;
  width: 100%;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
`;

export const IconBG = styled.div`
  background: ${COLORS.GRAY_LIGHT_150};
  border-radius: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const Title = styled.p`
  color: ${COLORS.GRAY_300};
  font-weight: normal;
  font-size: 14px;
`;

export const Text = styled.div`
  color: ${COLORS.WHITE};
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
`;

export const SubText = styled(Title)`
  font-size: 12px;
`;

export const StyledAlert = styled(Alert)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px !important;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);

  color: ${({ severity }: { severity: string }) => {
    switch (severity) {
      case 'error':
        return COLORS.GRAY_700;
      case 'warning':
        return COLORS.YELLOW_600;
      default:
        return '';
    }
  }}!important;
`;

export const StyledMessageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const StyledReplaceButton = styled(Button)`
  margin-left: auto;
  color: ${COLORS.WHITE};
  border-color: ${COLORS.WHITE};
`;

export const CurrencyInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledConfirmationText = styled.p`
  font-weight: 400;
`;
