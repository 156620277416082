import { Switch, switchClasses } from '@private/components';
import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const StyledSwitch = styled(Switch)`
  margin-left: 0;

  ${switchClasses.root} {
    width: 36px;
    height: 10px;

    overflow: visible;
  }

  ${switchClasses.switchBase} {
    transition-duration: 0.3s;
  }

  ${switchClasses.thumb} {
    width: 20px;
    height: 20px;

    background: ${COLORS.WHITE};
    box-shadow: 0 0 2px rgba(62, 103, 120, 0.25), 0 2px 2px rgba(62, 103, 120, 0.25);
  }

  ${switchClasses.track} {
    border-radius: 20px;
    background-color: ${COLORS.LIGHT_SURFACE_3};
    opacity: 1;
    transition: all 0.3s ease;
  }

  ${switchClasses.track},
  ${switchClasses.thumb} {
    position: relative;
    top: -6px;
  }

  ${switchClasses.label} {
    margin-right: 0;
    margin-left: 0;

    font-family: inherit;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    color: ${COLORS.GRAY_700};

    &:not(:empty) {
      margin-left: 8px;
    }
  }
`;
