import { axiosInstance } from '../base';

import { ILoggerPayload } from './types';

const logger = {
  async createLog(log: ILoggerPayload): Promise<void> {
    await axiosInstance.post('/log', log);
  },
};

export default logger;
