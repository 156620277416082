import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: 50px;
`;

export const Text = styled.h4`
  font-family: PT Sans;
  font-size: 18px;
  color: ${COLORS.GRAY_700};
`;
