import styled from 'styled-components';

import { COLORS } from 'constants/styles';

interface IContainerProps {
  size?: 'small' | 'big';
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin: 0 auto;

  width: ${({ size = 'small' }) => {
    switch (size) {
      case 'big':
        return '984px';
      case 'small':
      default:
        return '610px';
    }
  }};

  padding: 60px ${({ size = 'small' }) => (size === 'small' ? '100px' : '50px')} 80px;
  transition: 0.2s;
`;

export const ContentContainer = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  padding: 0 ${({ size = 'small' }) => (size === 'small' ? '0' : '160px')};
  transition: 0.2s;
`;

export const Title = styled.h1`
  font-family: PT Sans;
  font-size: 32px;
  color: ${COLORS.GRAY_700};
`;

export const SubTitle = styled.h4`
  font-family: PT Sans;
  font-size: 18px;
  align-self: flex-start;
  color: ${COLORS.GRAY_700};
  margin-top: 16px;
`;

export const StepNumber = styled(SubTitle)`
  margin: 40px 0 13px 0;
`;

export const ButtonsRow = styled.div<{ shouldCenter?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${({ shouldCenter = false }) =>
    shouldCenter ? 'center' : 'space-between'};
`;
