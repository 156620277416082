import { Input, inputClasses } from '@private/components';
import styled, { css } from 'styled-components';

import { COLORS } from 'constants/styles';

export const inputStyles = css<{ disabled?: boolean }>`
  ${inputClasses.label} {
    color: ${COLORS.GRAY_700};
    font-weight: 800;
  }

  ${inputClasses.input} {
    height: 40px;
    border-radius: 5px;
    border-color: ${COLORS.GRAY_300};
    transition: all 0.3s ease;

    &::placeholder {
      font-size: 14px;
      color: ${COLORS.GRAY_300};
      font-weight: normal;
    }

    &:disabled,
    &:hover {
      background: ${COLORS.GRAY_LIGHT_100};
    }

    &:focus {
      border: 2px solid ${COLORS.BLUE_50};
      background: ${COLORS.WHITE};
      outline: none;
    }
  }

  ${inputClasses.iconButton} svg, ${inputClasses.iconWrapper} svg {
    color: ${COLORS.GRAY_500};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${inputClasses.label} {
        color: ${COLORS.GRAY_300};
      }
    `}
`;

export const StyledInput = styled(Input)`
  ${inputStyles};

  ${({ error }) =>
    error &&
    css`
      ${inputClasses.error} {
        color: ${COLORS.RED_700};
        margin-top: 4px;
        font-size: 13px;
      }
      ${inputClasses.input} {
        border-color: ${COLORS.RED_700};
      }
    `}
`;
