import React from 'react';
import { Link, useNavigationType } from 'react-router-dom';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { ISignInPayload } from 'api/auth/types';
import { FormikInput } from 'components';
import { ROUTES } from 'constants/routes';
import { appReactMemo } from 'hocs';
import { useAuthentication } from 'queries/session';

import { Body, ButtonStyled, Container, FormStyled, Hint, Title } from './styled';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

const SignIn = () => {
  const navigationType = useNavigationType();
  const { login: doLogin, isFetching, logout } = useAuthentication();

  const onSubmit = ({ email, password }: ISignInPayload) => {
    doLogin({
      email,
      password,
    });
  };

  React.useEffect(() => {
    if (navigationType === 'POP') {
      logout();
    }
  }, [navigationType]);

  return (
    <Container>
      <Body>
        <Title>Sign In</Title>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isValid }) => {
            return (
              <FormStyled onSubmit={handleSubmit}>
                <FormikInput
                  name='email'
                  autoFocus
                  autoComplete='username'
                  label='Email'
                  required
                />

                <FormikInput
                  name='password'
                  type='password'
                  label='Password'
                  autoComplete='current-password'
                  required
                />

                <ButtonStyled
                  variant='primary'
                  type='submit'
                  disabled={isFetching || !isValid}
                >
                  Sign In
                </ButtonStyled>
              </FormStyled>
            );
          }}
        </Formik>
        <Hint>
          Do not have account yet?
          <Link to={ROUTES.SIGN_UP.PATH}>Register!</Link>
        </Hint>
      </Body>
    </Container>
  );
};

export default appReactMemo(SignIn);
