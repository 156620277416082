import React from 'react';
import { useQuery } from 'react-query';

import { useUpdateEffect } from '@private/hooks';

import api from 'api';
import { appReactMemo } from 'hocs';
import { useAuthentication } from 'queries/session';
import { NOTIF_TYPES, notificationService } from 'utils/notifications';

const NetworkContext = React.createContext(true);
export enum NETWORK_KEYS {
  HEALTH = '@network/health',
}

const checkOnlineStatus = async () => {
  if (!navigator.onLine) {
    throw new Error('offline');
  }

  return api.common.checkHealth();
};

export const useNetworkStatus = (): boolean => {
  return React.useContext(NetworkContext);
};

export const NetworkProvider = appReactMemo(
  ({ children }: React.PropsWithChildren<unknown>) => {
    const [isOnline, setIsOnline] = React.useState(true);
    const { isAuthorized } = useAuthentication();
    const { refetch } = useQuery([NETWORK_KEYS.HEALTH], checkOnlineStatus, {
      retry: 1,
      refetchInterval: 10000,
      enabled: isAuthorized,
      onSettled: (data) => {
        setIsOnline(!!data);
      },
    });

    useUpdateEffect(() => {
      if (!isOnline) {
        notificationService.show({
          type: NOTIF_TYPES.LOADING,
          toastId: 'networkStatus',
          disableSkip: true,
          dismissIn: Infinity,
          message: 'You are offline. Please check your Internet or VPN connection',
        });
      } else {
        notificationService.show({
          type: NOTIF_TYPES.SUCCESS,
          updateId: 'networkStatus',
          message: 'You are back online',
        });
      }
    }, [isOnline]);

    React.useEffect(() => {
      const offlineListener = () => {
        setIsOnline(false);
      };
      const onlineListener = () => {
        refetch();
      };

      window.addEventListener('offline', offlineListener);
      window.addEventListener('online', onlineListener);

      return () => {
        window.removeEventListener('offline', offlineListener);
        window.removeEventListener('online', onlineListener);
      };
    }, [refetch]);

    return <NetworkContext.Provider value={isOnline}>{children}</NetworkContext.Provider>;
  }
);
