import { useMutation, UseMutationResult } from 'react-query';

import { AxiosError } from 'axios';

import api from 'api';
import { ISignUpPayload, ISignUpResult } from 'api/auth/types';

import useAuthentication from './useAuthentication';

const useRegister = (): UseMutationResult<ISignUpResult, AxiosError, ISignUpPayload> => {
  const { login } = useAuthentication();
  return useMutation(api.auth.signUp, {
    onSuccess: (_response, registerPayload) => {
      login(registerPayload);
    },
  });
};

export default useRegister;
