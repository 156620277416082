export const ROUTES = {
  HEADQUARTERS: {
    PATH: '/headquarters',
  },
  BRANDS: {
    PATH: '/brands',
  },
  SETTINGS: {
    PATH: '/settings',
  },
  HEADQUARTER: {
    PATH: '/headquarters/:headquarterID',
    PARAMS: {
      ID: 'headquarterID',
    },
  },
  GROUP: {
    PATH: '/headquarters/:headquarterID/group/:groupID',
    PARAMS: {
      hqID: 'headquarterID',
      groupID: 'groupID',
    },
  },
  SYSTEM_WALLETS: {
    PATH: '/system-wallets',
  },
  SYSTEM_WALLET_HISTORY: {
    PATH: '/system-wallets/history',
  },
  TRANSACTION_DETAILS: {
    PATH: '/headquarters/:headquarterID/group/:groupID/transaction-details/:internalID',
    PARAMS: {
      hqID: 'headquarterID',
      groupID: 'groupID',
      internalID: 'internalID',
    },
  },
  GROUPS: {
    PATH: '/groups',
  },
  MULTISENDER: {
    PATH: '/multisender',
  },
  MULTISENDER_HISTORY: {
    PATH: '/multisender/history',
  },
  MULTISENDER_TRANSACTIONS: {
    PATH: '/multisender/history/:id',
    PARAMS: {
      id: 'id',
    },
  },
  MULTISENDER_SEED: {
    PATH: '/multisender/seed',
  },
  WALLETS_MANAGEMENT: {
    PATH: '/wallets-management',
  },
  EXCHANGER: {
    PATH: '/exchanger',
  },
  EXCHANGER_SETTINGS: {
    PATH: '/exchanger/settings',
  },
  EXCHANGER_HISTORY: {
    PATH: '/exchanger/history',
  },
  EXCHANGER_TRANSACTIONS: {
    PATH: '/exchanger/history/:id',
    PARAMS: {
      id: 'id',
    },
  },
  EXCHANGER_SEED: {
    PATH: '/exchanger/seed',
  },
  SIGN_IN: {
    PATH: '/sign-in',
  },
  SECOND_FACTOR: {
    PATH: '/second-factor',
  },
  SIGN_UP: {
    PATH: '/sign-up',
  },
  SECURITY: {
    PATH: '/security',
  },
  NOT_FOUND: {
    PATH: '/not-found',
  },
  MAINTAINANCE: {
    PATH: 'maintainance',
  },
};
