export enum EXCHANGER_KEYS {
  CURRENCIES = 'exchanger/currencies',
  BACKUP_SEED = 'auth/seed',
  ACTIVE_WALLET = 'wallets/addresses/system/active',
  PLATFORMS = 'exchanger/exchanges',
  AVAILABLE_PLATFORMS = 'exchanger/exchanges-available',
  PLATFORMS_BALANCE = 'exchanger/balance',
  ORDERS = 'exchanger/order',
  EXCHANGER_HISTORY = 'wallets/history',
  EXCHANGER_DEPOSIT_HISTORY = 'wallets/deposit-history',
  WITHDRAWAL_LIMITS = 'exchanger/withdrawal-limits',
  EXCHANGER_SECRETS = 'exchanger/exchanges-secrets',
  MARKET_PRICE = 'exchanger/currency-price',
  MARKETS_PRICES = 'exchanger/exchange-currency-price',
}
