import React from 'react';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

import { AxiosError } from 'axios';

import api from 'api';
import { STATUSES } from 'api/auth/constants';
import { IExport } from 'api/headquarters/types';
import { CURRENCIES } from 'api/wallets/constants';
import {
  IBlockedWalletsResult,
  IBlockWalletPayload,
  ICurrency,
  ICurrentBlockResult,
  IDeactivateWalletsPayload,
  IPreparedTransactionBTC,
  IPrepareWithdrawalToWalletPayload,
  IPrepareWithdrawalToWalletResponse,
  IRestoreWalletPayload,
  IRestoreWalletResult,
  ISendWithdrawalToWalletPayload,
  ISetting,
  ISystemWallet,
  ITypesToSend,
  IUpdateSettingsPayload,
  IUploadWalletsPayload,
  IValidateWithdrawalAddressesPayload,
  IValidateWithdrawalWalletsPayload,
  IValidateWithdrawalWalletsResult,
  IWalletInfo,
  IWithdrawalHistory,
  IWithdrawalWallet,
} from 'api/wallets/types';
import {
  IReplaceSystemWalletPayload,
  ISystemWalletHistoryResult,
} from 'api/wallets/types';
import { IBaseAxiosError } from 'constants/types';
import { useAppSelector } from 'hooks';
import { EXCHANGER_KEYS } from 'queries/exchanger/constants';
import { useAppQuery } from 'queries/utils';
import { downloadCSV } from 'utils/csv';
import { NOTIF_TYPES, notificationService } from 'utils/notifications';

import { WALLETS_KEYS } from './constants';

interface IUseCurrenciesProps {
  enabled?: boolean;
}

type TUseCurrenciesResult = UseQueryResult<ICurrency[]> & {
  getSystemWalletCurrency: (currency?: CURRENCIES) => CURRENCIES | null | undefined;
};

export const useCurrenciesQuery = (
  props: IUseCurrenciesProps = {}
): TUseCurrenciesResult => {
  const { enabled } = props;

  const query = useQuery({
    queryKey: [WALLETS_KEYS.CURRENCIES],
    queryFn: api.wallets.getCurrencies,
    cacheTime: Infinity,
    staleTime: 2 * 60 * 60 * 1000,
    useErrorBoundary: (error: AxiosError) => {
      return error?.response?.status !== STATUSES.AUTHENTICATION;
    },
    enabled,
  });

  const getSystemWalletCurrency = React.useCallback(
    (currency?: CURRENCIES) => {
      if (!currency) {
        return null;
      }
      return query.data?.find((curr) => curr.currency === currency)?.systemSymbol;
    },
    [query.data]
  );

  return {
    ...query,
    getSystemWalletCurrency,
  };
};

export const useWithdrawalWalletsQuery = (
  headquarterID: string
): UseQueryResult<IWithdrawalWallet[]> => {
  const currency = useAppSelector((state) => state.userSettings.selectedCurrency);
  const getWallets = React.useCallback(() => {
    return api.wallets.getWithdrawalWallets(headquarterID, currency);
  }, [headquarterID, currency]);

  return useAppQuery({
    queryKey: [WALLETS_KEYS.WITHDRAWAL_WALLETS, headquarterID, currency],
    queryFn: getWallets,
    select: (data) => {
      return data.sort((a, b) => Number(b.isActive) - Number(a.isActive));
    },
  });
};

export type TUseWithdrawalHistoryQueryConfig = UseQueryOptions<
  IWithdrawalHistory | null,
  AxiosError,
  IWithdrawalHistory,
  string[]
>;
export const useWithdrawalHistoryQuery = (
  groupID: string,
  config?: TUseWithdrawalHistoryQueryConfig
): UseQueryResult<IWithdrawalHistory> => {
  const currency = useAppSelector((state) => state.userSettings.selectedCurrency);
  const getHistory = React.useCallback(() => {
    return api.wallets.getWithdrawalHistory(groupID, currency);
  }, [groupID, currency]);

  return useAppQuery({
    queryKey: [WALLETS_KEYS.WITHDRAWAL_HISTORY, groupID, currency],
    queryFn: getHistory,
    ...config,
  });
};

export const useSettingsQuery = (): UseQueryResult<ISetting[]> => {
  return useAppQuery({
    queryKey: [WALLETS_KEYS.SETTINGS],
    queryFn: api.wallets.getSettings,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useUpdateSettings = (): UseMutationResult<
  ISetting[],
  AxiosError,
  IUpdateSettingsPayload
> => {
  const queryClient = useQueryClient();

  return useMutation(api.wallets.updateSettings, {
    onMutate: () => {
      const notifID = notificationService.show({
        type: NOTIF_TYPES.LOADING,
        message: 'Uploading withdrawal settings',
      });
      return { notifID };
    },
    onError: (_error, _payload, context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message: 'Failed to update withdrawal settings',
        updateId: context?.notifID,
      });
    },
    onSuccess: (result, _payload, context) => {
      queryClient.setQueryData([WALLETS_KEYS.SETTINGS], result);

      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'Successfully updated withdrawal settings',
        updateId: context?.notifID,
      });
    },
  });
};

export const useDeactivateWallets = (
  headquarterID: string
): UseMutationResult<void, AxiosError, IDeactivateWalletsPayload> => {
  const queryClient = useQueryClient();

  return useMutation(api.wallets.deactivateWallets, {
    onMutate: () => {
      const notifID = notificationService.show({
        type: NOTIF_TYPES.LOADING,
        message: 'Deactivating wallet...',
      });
      return { notifID };
    },
    onError: (_error, _payload, context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message: 'Failed to deactivate withdrawal wallet',
        updateId: context?.notifID,
      });
    },
    onSuccess: (_result, _payload, context) => {
      queryClient.invalidateQueries([WALLETS_KEYS.WITHDRAWAL_WALLETS, headquarterID]);

      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'Successfully deactivated withdrawal wallet',
        updateId: context?.notifID,
      });
    },
  });
};

export const useValidateAddresses = (): UseMutationResult<
  IValidateWithdrawalWalletsResult,
  AxiosError,
  IValidateWithdrawalAddressesPayload
> => {
  const currency = useAppSelector((state) => state.userSettings.selectedCurrency);
  const validateAddresses = React.useCallback(
    (props: IValidateWithdrawalAddressesPayload) => {
      return api.wallets.validateAddressesForWithdrawal(currency, props);
    },
    [currency]
  );

  return useMutation(validateAddresses);
};

export const useWalletsValidation = (): UseMutationResult<
  IValidateWithdrawalWalletsResult,
  AxiosError,
  IValidateWithdrawalWalletsPayload
> => useMutation(api.wallets.validateForWithdrawal);

export const useWalletsUpload = (): UseMutationResult<
  IWithdrawalWallet[],
  AxiosError,
  IUploadWalletsPayload,
  { notifID: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(api.wallets.uploadWallets, {
    onMutate: ({ headquarterID }) => {
      const notifID = notificationService.show({
        toastId: `uploadWallets-${headquarterID}`,
        type: NOTIF_TYPES.LOADING,
        message: 'Uploading wallets...',
      });
      return { notifID };
    },
    onSuccess: (wallets, { headquarterID }, context) => {
      const headquarterIDstring = `${headquarterID}`;
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'Wallet(s) uploaded successfully',
        updateId: context?.notifID,
      });

      const oldWallets =
        queryClient.getQueryData<IWithdrawalWallet[]>([
          WALLETS_KEYS.WITHDRAWAL_WALLETS,
          headquarterIDstring,
        ]) || [];
      queryClient.setQueryData(
        [WALLETS_KEYS.WITHDRAWAL_WALLETS, headquarterIDstring],
        oldWallets.concat(wallets)
      );
      queryClient.invalidateQueries([
        WALLETS_KEYS.WITHDRAWAL_WALLETS,
        headquarterIDstring,
      ]);
    },
    onError: (_error, _payload, context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message: 'Some error occurred while uploading your wallet(s)',
        updateId: context?.notifID,
      });
    },
  });
};

export const useExportWalletsExample = (): UseMutationResult<
  IExport,
  AxiosError,
  unknown,
  { notifID: string }
> => {
  return useMutation(api.wallets.exportWalletsCSVExample, {
    onMutate: () => {
      const notifID = notificationService.show({
        type: NOTIF_TYPES.LOADING,
        message: 'Exporting wallets example...',
      });
      return { notifID };
    },
    onError: (_error, _variables, context) => {
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'Failed to export wallets example',
        updateId: context?.notifID,
      });
    },
    onSuccess: (data, _variables, context) => {
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'Successfully exported wallets example',
        updateId: context?.notifID,
      });
      downloadCSV(data.content, data.fileName);
    },
  });
};

export const useSystemWalletsQuery = (
  currencySymbol?: CURRENCIES | undefined
): UseQueryResult<ISystemWallet[]> => {
  const getSystemWallets = React.useCallback(() => {
    return api.wallets.getSystemWallets();
  }, []);

  return useAppQuery({
    queryKey: [WALLETS_KEYS.SYSTEM_WALLET],
    queryFn: getSystemWallets,
    cacheTime: Infinity,
    refetchInterval: 1 * 60 * 1000,
    select: (data) => {
      if (!currencySymbol) {
        return data;
      }
      return data.filter((wallet) => wallet.currencySymbol === currencySymbol);
    },
  });
};

export const useGetCurrentBlockQuery = (
  currencySymbol: CURRENCIES
): UseQueryResult<ICurrentBlockResult> => {
  const getCurrentBlock = React.useCallback(() => {
    return api.wallets.getCurrentBlock({ currencySymbol });
  }, [currencySymbol]);

  return useAppQuery({
    queryKey: [WALLETS_KEYS.CURRENT_BLOCK, currencySymbol],
    queryFn: getCurrentBlock,
    refetchInterval: 1 * 60 * 1000,
  });
};

export type TUseGetWalletInfoQueryConfig = UseQueryOptions<
  IWalletInfo,
  AxiosError,
  IWalletInfo,
  string[]
>;

export const useGetWalletInfoQuery = (
  address: string,
  config?: TUseGetWalletInfoQueryConfig
): UseQueryResult<IWalletInfo> => {
  const getWalletInfo = React.useCallback(() => {
    return api.wallets.getWalletInfo({ address });
  }, [address]);

  return useAppQuery({
    queryKey: [WALLETS_KEYS.WALLET_INFO, address],
    queryFn: getWalletInfo,
    cacheTime: Infinity,
    refetchInterval: 1 * 60 * 1000,
    ...config,
  });
};

export const useRestoreWallet = (): UseMutationResult<
  IRestoreWalletResult,
  AxiosError,
  IRestoreWalletPayload,
  { notifID: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(api.wallets.restoreWallet, {
    onMutate: ({ address }) => {
      const notifID = notificationService.show({
        toastId: `restore-wallet-${address}`,
        type: NOTIF_TYPES.LOADING,
        message: 'Restoring wallet...',
      });
      return { notifID };
    },
    onSuccess: (status, { address }, context) => {
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'Wallet restoring successfully',
        updateId: context?.notifID,
      });

      queryClient.invalidateQueries([WALLETS_KEYS.WALLET_INFO, address]);
    },
    onError: (_error, _payload, context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message: 'Some error occurred while restoring your wallet',
        updateId: context?.notifID,
      });
    },
  });
};

export const useReplaceSystemWallet = (): UseMutationResult<
  unknown,
  AxiosError,
  IReplaceSystemWalletPayload,
  { notifID: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(api.wallets.replaceSystemWallet, {
    onMutate: () => {
      const notifID = notificationService.show({
        toastId: `replacing-wallet`,
        type: NOTIF_TYPES.LOADING,
        message: 'Replacing system wallet...',
      });
      return { notifID };
    },
    onSuccess: (_data, _variables, context) => {
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'The system wallet has been replaced',
        updateId: context?.notifID,
      });

      queryClient.invalidateQueries([WALLETS_KEYS.SYSTEM_WALLET]);
    },
    onError: (_error, _payload, context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message: 'Some error occurred while replacing your wallet',
        updateId: context?.notifID,
      });
    },
  });
};

export type IUseGetSystemWalletHistory = UseQueryOptions<
  ISystemWalletHistoryResult[],
  AxiosError,
  ISystemWalletHistoryResult[],
  string[]
>;

export const useGetSystemWalletHistory = (
  currencySymbol: CURRENCIES,
  config?: IUseGetSystemWalletHistory
): UseQueryResult<ISystemWalletHistoryResult[]> => {
  const getSystemWalletHistory = React.useCallback(() => {
    return api.wallets.getSystemWalletHistory(currencySymbol);
  }, [currencySymbol]);

  return useAppQuery({
    queryKey: [WALLETS_KEYS.SYSTEM_WALLET_HISTORY, currencySymbol],
    queryFn: getSystemWalletHistory,
    refetchInterval: 1 * 60 * 1000,
    ...config,
  });
};

export const usePrepareSystemWithdrawToWallet = (
  data?: ITypesToSend
): UseMutationResult<
  IPreparedTransactionBTC | IPrepareWithdrawalToWalletResponse[],
  AxiosError,
  IPrepareWithdrawalToWalletPayload
> => {
  if (data?.isExchanger) {
    return useMutation(api.exchanger.getPreparedWithdrawToWalletTransaction);
  }

  return useMutation(api.wallets.getPreparedWithdrawToWalletTransaction);
};

export const useSendSystemWithdrawToWallet = (
  data?: ITypesToSend
): UseMutationResult<void, AxiosError, ISendWithdrawalToWalletPayload> => {
  const queryClient = useQueryClient();
  const sendTrx = React.useCallback((payload: ISendWithdrawalToWalletPayload) => {
    const { ...rest } = payload;

    if (data?.isExchanger) {
      return api.exchanger.sendWithdrawalToWalletTransaction({
        currencySymbol: payload.currencySymbol,
        // only one transaction is possible
        transactionData: payload.transactions[0],
      });
    }

    return api.wallets.sendWithdrawalToWalletTransaction(rest);
  }, []);

  return useMutation(sendTrx, {
    onMutate: () => {},
    onSuccess: (_data, _context) => {
      if (data?.isExchanger) {
        queryClient.invalidateQueries([EXCHANGER_KEYS.EXCHANGER_HISTORY]);
        queryClient.invalidateQueries([EXCHANGER_KEYS.EXCHANGER_DEPOSIT_HISTORY]);

        return;
      }

      queryClient.invalidateQueries([WALLETS_KEYS.SYSTEM_WALLET]);
      queryClient.invalidateQueries([WALLETS_KEYS.SYSTEM_WALLET_HISTORY]);
    },
    onError: (_error, _context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message:
          (_error as IBaseAxiosError)?.response?.data?.message || 'Transaction failed',
      });
    },
  });
};

export type IUseBlockedWallets = UseQueryOptions<
  IBlockedWalletsResult[],
  AxiosError,
  IBlockedWalletsResult[],
  string[]
>;

export const useBlockedWallets = (
  currencySymbol: CURRENCIES,
  config?: IUseBlockedWallets
): UseQueryResult<IBlockedWalletsResult[]> => {
  const getBlockedWallets = React.useCallback(() => {
    return api.wallets.getBlockedWallets(currencySymbol);
  }, [currencySymbol]);

  return useAppQuery({
    queryKey: [WALLETS_KEYS.BLOCKED_WALLETS, currencySymbol],
    queryFn: getBlockedWallets,
    refetchInterval: 1 * 60 * 1000,
    ...config,
  });
};

export const useBlockWallet = (): UseMutationResult<
  void,
  AxiosError,
  IBlockWalletPayload,
  { notifID: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(api.wallets.blockWallet, {
    onMutate: (data) => {
      const notifID = notificationService.show({
        toastId: `block-wallet-${data.untilBy}`,
        type: NOTIF_TYPES.LOADING,
        message: data.untilBy ? 'Blocking wallet...' : 'Unblocking wallet...',
      });
      return { notifID };
    },
    onSuccess: (_data, variables, context) => {
      queryClient.invalidateQueries([WALLETS_KEYS.BLOCKED_WALLETS]);

      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: variables.untilBy
          ? 'Wallet successfully blocked'
          : 'Wallet successfully unblocked',
        updateId: context?.notifID,
      });
    },
    onError: (_error, _payload, context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message:
          (_error as IBaseAxiosError)?.response?.data?.message ||
          'Some error occurred while blocking your wallet',
        updateId: context?.notifID,
      });
    },
  });
};
