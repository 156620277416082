import { CURRENCIES } from 'api/wallets/constants';
import { ICurrency } from 'api/wallets/types';

import { useCurrenciesQuery } from '../queries/wallets';

const useGetCurrencyData = (currencySymbol: CURRENCIES): ICurrency | null => {
  const { data: currenciesList = [] } = useCurrenciesQuery();

  return currenciesList.find((item) => item.currency === currencySymbol) || null;
};

export default useGetCurrencyData;
