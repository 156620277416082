import React, { PropsWithChildren } from 'react';

import { Container, FlexContainer, Text } from './styled';

type TRowCellData<T> = {
  item: T;
  dataKey?: keyof T;
  rowIndex: number;
  bold?: boolean;
  onClick?: (item: T) => void;
  className?: string;
};

type TRowCellProps<T> = PropsWithChildren<TRowCellData<T>>;

const RowCell = <T,>({
  dataKey,
  item,
  rowIndex,
  onClick,
  bold,
  children,
  className,
}: TRowCellProps<T>): React.ReactElement => {
  const cellData = dataKey && item?.[dataKey] && `${item?.[dataKey]}`;

  const onRowClick = React.useCallback(() => {
    return onClick?.(item);
  }, [item]);

  return (
    <Container
      data-row-index={rowIndex}
      data-cell-key={dataKey}
      index={rowIndex}
      onClick={onRowClick}
      className={className}
    >
      <FlexContainer isClickable={!!onClick} bold={bold}>
        {(!children || typeof children === 'string') && (
          <Text>{children || cellData}</Text>
        )}

        {!!children && typeof children !== 'string' && children}
      </FlexContainer>
    </Container>
  );
};

export default RowCell;
