import React from 'react';
import { useLocation } from 'react-router-dom';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ROUTES } from 'constants/routes';
import { appReactMemo } from 'hocs';
import { useSecurityContext } from 'routes/UnauthorizedStack/Security/context';
import { useSecurityBackup } from 'utils/crypto/backups';

import { ButtonsRow } from '../../styled';

import {
  Container,
  MnemonicContainer,
  MnemonicItem,
  NavButton,
  SecretTextField,
} from './styled';

const SeedPage = () => {
  const location = useLocation();

  const isMultisenderSetSeedPage = React.useMemo(() => {
    return location.pathname === ROUTES.MULTISENDER_SEED.PATH;
  }, [location.pathname]);

  const isExchangerSetSeedPage = React.useMemo(() => {
    return location.pathname === ROUTES.EXCHANGER_SEED.PATH;
  }, [location.pathname]);

  const { goNext } = useSecurityContext();
  const { data: backups, refetch: regenarateBackups } = useSecurityBackup({
    isMultisenderBackup: isMultisenderSetSeedPage,
    isExchangerBackup: isExchangerSetSeedPage,
  });
  const onRegenerate = React.useCallback(() => {
    regenarateBackups();
  }, [regenarateBackups]);

  return (
    <Container>
      <MnemonicContainer>
        {backups?.mnemonic.map((item, index) => {
          return <MnemonicItem key={index} index={index + 1} label={item} />;
        })}
      </MnemonicContainer>

      <SecretTextField
        readOnly
        value={backups?.secretFromSeed || ''}
        label='Please save this secret securely, you will need it to sign transactions'
      />

      <ButtonsRow shouldCenter>
        <NavButton variant='primary' onClick={onRegenerate}>
          Regenerate New
        </NavButton>
        <NavButton
          variant='transparent'
          onClick={goNext}
          endIcon={<ChevronRightIcon />}
          iconMargin={30}
        >
          Next
        </NavButton>
      </ButtonsRow>
    </Container>
  );
};

export default appReactMemo(SeedPage);
