import { axiosInstance } from '../base';

import { normalizeGroup, normalizeGroups } from './normalizers';
import { ICreateGroupPayload, IEditGroupPayload, IGroup } from './types';

const groupsAPI = {
  async getGroup(groupId: number): Promise<IGroup> {
    const result = await axiosInstance.get(`/groups/${groupId}`);
    return normalizeGroup(result.data);
  },

  async getGroups(): Promise<IGroup[]> {
    const response = await axiosInstance.get('/groups');
    return normalizeGroups(response.data);
  },

  async editGroup(payload: IEditGroupPayload): Promise<IGroup> {
    const result = await axiosInstance.put(`/groups/${payload.groupId}`, payload);
    return normalizeGroup(result.data);
  },

  async createGroup(payload: ICreateGroupPayload): Promise<IGroup> {
    const result = await axiosInstance.post('/groups', payload);
    return normalizeGroup(result.data);
  },
};

export default groupsAPI;
