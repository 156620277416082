import { Accordion, accordionClasses } from '@private/components';
import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const StyledAccordion = styled(Accordion)`
  color: ${COLORS.GRAY_300};

  a:first-of-type {
    margin-top: 0;
  }

  ${accordionClasses.accordionHeader} {
    padding-left: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: ${COLORS.GRAY_300};
  }

  &[data-open='true'] {
    ${accordionClasses.accordionEndIcon} {
      transform: rotate(-180deg);
    }
  }

  [data-link] {
    justify-content: center;
    margin-left: auto;
    padding: 8px 12px;

    min-width: 160px;
    width: max-content;
  }

  transition: background 0.4s ease;
`;

export const AccordionTitle = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
`;
