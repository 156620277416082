import { NOTIF_TYPES } from '@private/notifications';
import styled from 'styled-components';

import { COLORS, SHADOWS } from 'constants/styles';

interface IContainerProps {
  type: NOTIF_TYPES;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  padding: 16px 70px;

  background: ${COLORS.WHITE};
  border-radius: 15px;
  border: 1px solid ${COLORS.GRAY_LIGHT_100};
  border-left: none;
  width: 343px;

  box-shadow: ${SHADOWS.TERTIARY};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  justify-content: flex-start;
  align-items: flex-start;
`;

export const IconContainer = styled.div`
  position: absolute;
  left: 16px;
  top: 10px;
`;

export const Title = styled.h2<IContainerProps>`
  color: ${({ type }) => {
    switch (type) {
      case NOTIF_TYPES.SUCCESS:
        return COLORS.GREEN_400;
      case NOTIF_TYPES.ERROR:
        return COLORS.RED_600;
      case NOTIF_TYPES.LOADING:
        return COLORS.BLUE_500;
      case NOTIF_TYPES.INFO:
      default:
        return COLORS.YELLOW_400;
    }
  }};
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  white-space: break-all;
  overflow: hidden;
`;

export const Close = styled.button`
  position: absolute;
  right: 25px;
  top: 10px;

  height: auto;
  background: none;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: flex-end;
  &::after {
    color: ${COLORS.GRAY_500};
    content: '\\00D7';
    font-size: 25px;
    position: static;
    margin: 0;
    transition: 0.2s;
  }
  &:hover {
    &::after {
      color: ${COLORS.GRAY_300};
    }
  }
`;

export const Message = styled.div`
  align-self: center;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin-top: 8px;
  color: ${COLORS.GRAY_700};
  word-break: break-word;
`;
