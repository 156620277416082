import { IAuthState } from 'api/auth/types';
import {
  axiosExchangerInstance,
  axiosInstance,
  axiosMultisenderInstance,
} from 'api/base';
import appQueryClient from 'queries';
import AppCache from 'utils/cache';

import { SESSION_KEYS } from './constants';

export const sessionCache = new AppCache<Pick<IAuthState, 'token'>>('@session_v1');

export const updateAuthHeader = (value: string): void => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${value}`;
  axiosMultisenderInstance.defaults.headers.common.Authorization = `Bearer ${value}`;
  axiosExchangerInstance.defaults.headers.common.Authorization = `Bearer ${value}`;
};

export const logoutWithQuery = (queryClient: typeof appQueryClient): void => {
  sessionCache.clear();
  queryClient.resetQueries({
    predicate: (query) => query.queryKey !== SESSION_KEYS.IS_INITIALIZED,
  });
  queryClient.cancelQueries();
};
