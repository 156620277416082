import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import { ModalContainer } from 'components';

import { SFeeIcon, SInfoContainer, SInfoDesContainer, SMessage } from './styled';

export interface IWithdrawalModalProps {
  onClose: () => void;
  isLoading: boolean;
  title: string;
}

const ModalLoading = ({
  onClose,
  isLoading,
  title,
}: IWithdrawalModalProps): React.ReactElement => {
  return (
    <ModalContainer
      title={title}
      rightBtnText='Next'
      rightBtnVariant='primary'
      onDismiss={onClose}
      onLeftAction={onClose}
      onRightAction={() => null}
      disableRightBtn={isLoading}
    >
      <Skeleton
        variant='rectangular'
        animation='wave'
        sx={{
          borderRadius: '8px',
          marginBottom: '20px',
        }}
        height={124}
        width='100%'
      />

      <SInfoContainer>
        <SFeeIcon />
        <SInfoDesContainer>
          <SMessage>Total transaction fee is:</SMessage>
          <Skeleton animation='wave' height={21} width={250} />
        </SInfoDesContainer>
      </SInfoContainer>
    </ModalContainer>
  );
};

export default ModalLoading;
