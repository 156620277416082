// import { AxiosResponse } from 'axios';

import { CURRENCIES } from 'api/wallets/constants';

import { axiosInstance } from '../base';

import {
  normalizeExport,
  normalizeGroup,
  normalizeHeadquarter,
  normalizeHeadquarters,
} from './normalizers';
import {
  IExport,
  IExportPayload,
  IHeadquarter,
  // IEditGroupPayload,
  IHeadquarterGroup,
  IHeadquartersData,
  // IEditHeadquarterPayload,
  // ICreateHeadquarterPayload,
  // TCreateGroupPayload,
  // IDeleteGroupResult,
  IIdentifyGroupPayload,
} from './types';

const groupsAPI = {
  async getHeadquarters(currency?: string): Promise<IHeadquartersData> {
    const result = await axiosInstance.get('/headquarters', {
      params: {
        ...(!!currency && { currencySymbol: currency }),
      },
    });
    return normalizeHeadquarters(result.data);
  },

  async getHeadquarter(id: string, currency: CURRENCIES): Promise<IHeadquarter> {
    const result = await axiosInstance.get(`/headquarters/${id}`, {
      params: { currencySymbol: currency },
    });
    return normalizeHeadquarter(result.data);
  },

  async exportTransactions({
    headquarterID,
    currency,
    createdAtStart,
    createdAtEnd,
  }: IExportPayload): Promise<IExport> {
    const result = await axiosInstance.get(
      `/headquarters/${headquarterID}/transactions/export`,
      {
        params: {
          currencySymbol: currency,
          createdAtStart,
          createdAtEnd,
        },
      }
    );
    return normalizeExport(result.data);
  },

  // async editHeadquarter(props: IEditHeadquarterPayload): Promise<AxiosResponse> {
  //   const result = await axiosInstance.put(`/headquarters/${props.headquarterID}`, { title: props.title });
  //   return result;
  // },

  async getGroup(props: IIdentifyGroupPayload): Promise<IHeadquarterGroup> {
    const result = await axiosInstance.get(
      `/headquarters/${props.headquarterID}/groups/${props.groupID}`,
      {
        params: { currencySymbol: props.currencySymbol },
      }
    );
    return normalizeGroup(result.data);
  },

  // async deleteGroup(props: IIdentifyGroupPayload): Promise<IDeleteGroupResult> {
  //   const result = await axiosInstance.delete(`/headquarters/${props.headquarterID}/groups/${props.groupID}`);
  //   return result.data;
  // },

  // async editGroup(props: IEditGroupPayload): Promise<IGroupAdmin> {
  //   const result = await axiosInstance.put(`/headquarters/${props.headquarterID}/groups/${props.groupID}`, props.groupData);
  //   return normalizeGroupAdmin(result.data);
  // },

  // async createGroup(props: TCreateGroupPayload): Promise<IGroupAdmin> {
  //   const result = await axiosInstance.post(`/headquarters/${props.headquarterID}/groups`, props.groupData);
  //   return normalizeGroupAdmin(result.data);
  // },
};

export default groupsAPI;
