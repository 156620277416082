import { createGlobalStyle } from 'styled-components';

import PTSansTTF from '../assets/fonts/PT_Sans/ptsans.ttf';
import PTSansWOFF from '../assets/fonts/PT_Sans/ptsans.woff';
import PTSansWOFF2 from '../assets/fonts/PT_Sans/ptsans.woff2';
import PTSansBoldTTF from '../assets/fonts/PT_Sans/ptsansbold.ttf';
import PTSansBoldWOFF from '../assets/fonts/PT_Sans/ptsansbold.woff';
import PTSansBoldWOFF2 from '../assets/fonts/PT_Sans/ptsansbold.woff2';

const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'PT Sans';
    font-weight: 400;
    font-style: normal;
    src: local('PT Sans'), local('PTSans-Regular'), 
    url(${PTSansWOFF2}) format('woff2'),
    url(${PTSansWOFF}) format('woff'),
    url(${PTSansTTF}) format('ttf');
  }
  @font-face {
    font-family: 'PT Sans';
    font-weight: 700;
    font-style: normal;
    src: local('PT Sans Bold'), local('PTSans-Bold'),
    url(${PTSansBoldWOFF2}) format('woff2'),
    url(${PTSansBoldWOFF}) format('woff'),
    url(${PTSansBoldTTF}) format('ttf');
  }
`;

export default GlobalFonts;
