import { appReactMemo } from 'hocs';

import { ChipWrapper, StyledIndex, StyledLabel } from './styled';

interface IIndexedChipProps {
  index: number;
  label: string;
  className?: string;
}

const IndexedChip = ({ index, label, className }: IIndexedChipProps) => (
  <ChipWrapper className={className}>
    <StyledIndex>{index}</StyledIndex>
    <StyledLabel>{label}</StyledLabel>
  </ChipWrapper>
);

export default appReactMemo(IndexedChip);
