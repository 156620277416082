import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const StyledLink = styled(Link)`
  display: flex;
`;

export const StyledLogo = styled.img<{ withoutText: boolean }>`
  width: ${({ withoutText }) => (withoutText ? 35 : 156)}px;
  height: ${({ withoutText }) => (withoutText ? 35 : 40)}px;
`;
