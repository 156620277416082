import React from 'react';

import MUICheckbox from '@mui/material/Checkbox';
import { CSSObject } from 'styled-components';

import { appReactMemo } from 'hocs';

import {
  StyledCheckBoxIcon,
  StyledCheckBoxOutlineBlankIcon,
  StyledLabel,
} from './styled';

const icon = <StyledCheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <StyledCheckBoxIcon fontSize='small' />;

interface ICheckboxProps {
  label?: string;
  checked: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  style?: CSSObject;
}

const Checkbox = ({ label = '', ...props }: ICheckboxProps) => (
  <StyledLabel
    label={label}
    control={
      <MUICheckbox disableRipple icon={icon} checkedIcon={checkedIcon} {...props} />
    }
  />
);

export default appReactMemo(Checkbox);
