import { normalizeBrand, TServerBrandData } from 'api/brands/normalizers';
import { CURRENCIES } from 'api/wallets/constants';

import { IExport, IHeadquarter, IHeadquarterGroup, IHeadquartersData } from './types';

type TServerData = Record<string, string>;

type TServerGroupData = Record<string, string> & {
  id: number;
  totalBalance: number;
  totalBlockedBalance: number;
  brands: TServerBrandData[];
};

export function normalizeGroup(data: TServerGroupData): IHeadquarterGroup {
  return {
    id: data.id || 0,
    title: data.title || '',
    currency: (data.currencySymbol as CURRENCIES) || CURRENCIES.BTCTEST,
    balance: data.totalBalance || 0,
    pendingWithdrawals: +data.pendingWithdrawn || 0,
    brands: data.brands?.map(normalizeBrand) || [],
    totalBlockedBalance: data.totalBlockedBalance || 0,
  };
}

export function normalizeGroups(data: TServerGroupData[]): IHeadquarterGroup[] {
  return data.map((item) => normalizeGroup(item));
}

export function normalizeExport(data: TServerData): IExport {
  return {
    fileName: data.fileName || '',
    content: data.content || data.fileContent || '',
  };
}

type THQServerData = {
  groups?: TServerGroupData[];
  withdrawalWallets?: { address: string }[];
  totalBalance: number;
  totalWithdrawn: number;
  pendingWithdrawn: number;
  groupsCount: number;
  totalBlockedBalance: number;
} & TServerData;

export function normalizeHeadquarter(data: THQServerData): IHeadquarter {
  return {
    id: data.id || '',
    title: data.title || '',
    balance: data.totalBalance || 0,
    totalBlockedBalance: data.totalBlockedBalance || 0,
    totalWithdrawal: data.totalWithdrawn || 0,
    pendingWithdrawals: data.pendingWithdrawn || 0,
    groups: normalizeGroups(data.groups || []),
    groupsCount: data?.groupsCount,
  };
}

interface IHQsServerResponse {
  totalBalance: number;
  totalWithdrawn: number;
  pendingWithdrawn: number;
  totalBlockedBalance: number;
  headquarters: THQServerData[];
}

export function normalizeHeadquarters(data: IHQsServerResponse): IHeadquartersData {
  return {
    balance: data.totalBalance || 0,
    totalBlockedBalance: data.totalBlockedBalance || 0,
    totalWithdrawals: data.totalWithdrawn || 0,
    pendingWithdrawals: data.pendingWithdrawn || 0,
    headquarters: data.headquarters?.map(normalizeHeadquarter) || [],
  };
}
