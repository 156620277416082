import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const WithdrawalBox = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  padding: 8px 16px;

  border: 1px solid ${COLORS.GRAY_300};
  filter: drop-shadow(0px 3.5px 5.5px rgba(0, 0, 0, 0.02));
  border-radius: 10px;
`;

export const RestInfo = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 2px;

    font-size: 14px;
    color: ${COLORS.GRAY_500};
  }
`;

export const RestAmount = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  [data-children] {
    overflow: unset;
    text-overflow: unset;

    font-weight: 700;
    font-size: 16px;
    color: ${COLORS.GRAY_700};
  }
`;
