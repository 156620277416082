import React from 'react';

import { useUpdateEffect } from '@private/hooks';

import { ActiveTabLine, Container, Content, Wrapper } from './styled';
import TabTitle from './TabTitle';

interface TabsProps {
  children: React.ReactElement[];
  defaultActiveTab?: string;
  onTabChange?: (index: number) => void;
}

const Tabs: React.FC<TabsProps> = ({ children, defaultActiveTab, onTabChange }) => {
  const defaultActive = children.findIndex(
    (item) => item.props.title === defaultActiveTab
  );

  const [selectedTab, setSelectedTab] = React.useState(
    defaultActive >= 0 ? defaultActive : 0
  );

  const ref = React.useRef<(HTMLButtonElement | null)[]>([]);

  const [position, setPosition] = React.useState<number>();

  const [width, setWidth] = React.useState<number>();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
      setSelectedTab(index);
      setPosition((event.target as HTMLButtonElement).offsetLeft);
      setWidth((event.target as HTMLButtonElement).offsetWidth);

      onTabChange?.(index);
    },
    [onTabChange]
  );

  useUpdateEffect(() => {
    setSelectedTab(defaultActive);
  }, [defaultActiveTab]);

  React.useEffect(() => {
    return (
      setWidth(ref.current[selectedTab]?.offsetWidth || 0),
      setPosition(ref.current[selectedTab]?.offsetLeft || 0)
    );
  }, [ref, selectedTab]);

  return (
    <Wrapper>
      <Container>
        {children.map((item, index) => {
          return (
            <TabTitle
              key={index}
              title={item.props.title}
              setSelectedTab={(event) => handleClick(event, index)}
              activeTab={selectedTab === index}
              buttonRefs={(element: HTMLButtonElement | null) => {
                ref.current[index] = element;
              }}
            />
          );
        })}
        <ActiveTabLine position={position} width={width} />
      </Container>
      <Content>{children[selectedTab]}</Content>
    </Wrapper>
  );
};

export default Tabs;
