export const PASSWORD_REQUIREMENTS = {
  TITLE: 'Password requirements:',
  HINT: `
  1. Uppercase letters: A-Z'\r\n
  2. Lowercase letters: a-z\r\n
  3. Numbers: 0-9\r\n
  4. Symbols: ~\`!@#$%^&* ()_ -+={ [}]|: ; "'<,>.?/
  `,
};

export const DEFAULT_SORTING = {
  orderColumn: 'createdAt',
  orderDirection: 'desc' as const,
};
