import styled from 'styled-components';

import { ICONS_MAP } from 'constants/icons';
import { COLORS } from 'constants/styles';

interface IUploadFileContainerProps {
  isSuccess: boolean;
  isFailure: boolean;
  isLoading: boolean;
}

export const UploadFileContainer = styled.div<IUploadFileContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
  padding: 18px 12px;

  height: 48px;
  width: 100%;

  border: ${({ isSuccess = false, isFailure = false, isLoading }) => {
    if (isLoading) {
      return `2px solid ${COLORS.BLUE_400}`;
    }

    if (isSuccess) {
      return `2px solid ${COLORS.GREEN_400}`;
    }

    if (isFailure) {
      return `2px solid ${COLORS.RED_600}`;
    }

    return `1px solid ${COLORS.GRAY_LIGHT_300}`;
  }};

  border-radius: 8px;
  transition: 0.2s;
`;

export const ErrorContainer = styled.div`
  margin-top: 8px;
  margin-left: 2px;
  max-height: 200px;
  align-self: flex-start;
  overflow-y: auto;
`;

export const UploadFileName = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.GRAY_700};
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledFileIcon = styled(ICONS_MAP.File)`
  margin-right: 10px;
`;

export const ErrorMessage = styled.span`
  color: ${COLORS.RED_500};
  font-size: 13px;
  white-space: pre;
  line-height: 24px;
`;
