import { CURRENCIES } from 'api/wallets/constants';

import { IBrand } from './types';

export type TServerBrandData = Record<string, string> & {
  id: number;
  activeWallets?: number;
  balance: number;
  nonActiveWallets?: number;
  currencySymbol: CURRENCIES;
  groupId: number | null;
};

export function normalizeBrand(data: TServerBrandData): IBrand {
  return {
    id: data.id,
    createdAt: data.createdAt || '',
    title: data.title || '',
    merchantID: data.merchantUuid || '',
    currency: data.currencySymbol || CURRENCIES.BTC,
    balance: data.balance || 0,
    activeWallets: data.activeWallets || 0,
    nonActiveWallets: data.nonActiveWallets || 0,
    groupId: data.groupId || 0,
  };
}

export function normalizeBrands(data: TServerBrandData[]): IBrand[] {
  return data.map((item) => normalizeBrand(item));
}
