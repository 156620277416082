import React from 'react';

import { IColumn, UNITS } from '@private/data-grid';

import { CURRENCIES } from 'api/wallets/constants';
import { ITransaction } from 'api/wallets/types';
import { CurrencyIcon, HeaderCell, RowCell } from 'components';
import { useCurrenciesQuery } from 'queries/wallets';
import { Status } from 'routes/AuthorizedStack/styled';
import formatDate from 'utils/date';

import { useAppSelector } from './useStore';

interface IUseWithdrawalGridData {
  data?: ITransaction[];
  firstColumn: IColumn<ITransaction>;
}

export const useWithdrawalGridData = ({
  data,
  firstColumn,
}: IUseWithdrawalGridData): IColumn<ITransaction>[] => {
  const currency = useAppSelector((state) => state.userSettings.selectedCurrency);
  const { getSystemWalletCurrency } = useCurrenciesQuery();

  const columns = React.useMemo(() => {
    return [
      {
        ...firstColumn,
        minWidth: 180,
        width: {
          unit: UNITS.PERCENT,
          value: 18,
        },
        renderHeaderCell: (params) => <HeaderCell {...params} />,
      },
      {
        dataKey: 'status',
        title: 'Status',
        minWidth: 100,
        width: {
          unit: UNITS.PERCENT,
          value: 12,
        },
        renderHeaderCell: (params) => <HeaderCell {...params} />,
        renderRowCell: (params) => (
          <RowCell {...params}>
            <Status status={params.item.status}>{params.item.status}</Status>
          </RowCell>
        ),
      },
      {
        dataKey: 'amount',
        title: `Amount, ${currency}`,
        minWidth: 180,
        width: {
          unit: UNITS.PERCENT,
          value: 14,
        },
        renderHeaderCell: (params) => <HeaderCell {...params} />,
        renderRowCell: (params) => (
          <RowCell bold {...params}>
            <CurrencyIcon currency={currency}>{params.item.amount}</CurrencyIcon>
          </RowCell>
        ),
      },
      {
        dataKey: 'amountFiat',
        title: 'Amount, USD',
        width: {
          unit: UNITS.PERCENT,
          value: 14,
        },
        renderHeaderCell: (params) => <HeaderCell {...params} />,
        renderRowCell: (params) => (
          <RowCell bold {...params}>
            <CurrencyIcon currency={CURRENCIES.USD}>
              {params.item.amountFiat.USD}
            </CurrencyIcon>
          </RowCell>
        ),
      },
      {
        dataKey: 'amountFiat',
        title: 'Amount, EUR',
        width: {
          unit: UNITS.PERCENT,
          value: 14,
        },
        renderHeaderCell: (params) => <HeaderCell {...params} />,
        renderRowCell: (params) => (
          <RowCell bold {...params}>
            <CurrencyIcon currency={CURRENCIES.EUR}>
              {params.item.amountFiat.EUR}
            </CurrencyIcon>
          </RowCell>
        ),
      },
      {
        dataKey: 'date',
        title: 'Time',
        width: {
          unit: UNITS.PERCENT,
          value: 14,
        },
        renderHeaderCell: (params) => <HeaderCell {...params} />,
        renderRowCell: (params) => (
          <RowCell {...params}>{formatDate(params.item.date)}</RowCell>
        ),
      },
      {
        dataKey: 'fee',
        title: 'Transaction Fee',
        width: {
          unit: UNITS.PERCENT,
          value: 14,
        },
        renderHeaderCell: (params) => <HeaderCell {...params} />,
        renderRowCell: (params) => {
          const systemWalletCurrency = getSystemWalletCurrency(currency);
          const feeCurrency = systemWalletCurrency || currency;
          return (
            <RowCell bold {...params}>
              <CurrencyIcon currency={feeCurrency}>{params.item.fee}</CurrencyIcon>
            </RowCell>
          );
        },
      },
    ] as IColumn<ITransaction>[];
  }, [currency]);

  return !data ? [] : columns;
};
