import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
  color: ${COLORS.WHITE};

  svg {
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
  }
`;
