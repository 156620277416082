import React from 'react';

import { ISystemWallet } from 'api/wallets/types';
import { Button, RefreshButton } from 'components';
import { CURRENCIES_ICONS, ICONS_MAP } from 'constants/icons';
import { useConfirmModal } from 'modals/ConfirmModal';
import { useReplaceSystemWallet } from 'queries/wallets';
import formatDate from 'utils/date';

import {
  Container,
  CurrencyAmount,
  CurrencyInfo,
  CurrencyInfoWrapper,
  CurrencyWrapper,
  IconBG,
  IconWrapper,
  Item,
  ItemsWrapper,
  Row,
  StyledAlert,
  StyledConfirmationText,
  StyledCurrencyAddress,
  StyledMessageBox,
  StyledReplaceButton,
  SubText,
  Text,
  Title,
} from './styled';

interface ISystemWalletStatistic {
  wallet: ISystemWallet;
  updateDateTimestamp?: number;
  isUpdating?: boolean;
  isError?: boolean;
  onUpdate?: () => void;
}

const SystemWalletStatistic: React.FC<ISystemWalletStatistic> = ({
  wallet,
  onUpdate,
  isUpdating,
  isError,
  updateDateTimestamp,
}) => {
  const { mutate: replaceWallet } = useReplaceSystemWallet();
  const [showConfirmModal] = useConfirmModal();

  const {
    currencySymbol,
    systemCurrencySymbol,
    address,
    balance,
    amountToDeposit,
    recommendedBalance,
    walletCount,
    isBalanceEnough,
    transactionsCompletedCount,
  } = wallet;

  const onConfirmReplacing = React.useCallback(() => {
    showConfirmModal({
      title: 'Replace wallet',
      message: (
        <StyledConfirmationText>
          Do you want to replace&nbsp;
          <strong>{address}</strong>
          &nbsp;wallet?
        </StyledConfirmationText>
      ),
      confirmText: 'Replace',
      onConfirm: () => {
        replaceWallet({
          currencySymbol,
        });
      },
    });
  }, [address]);

  return (
    <>
      <Container>
        <CurrencyWrapper>
          <CurrencyInfoWrapper>
            <IconWrapper>{CURRENCIES_ICONS[systemCurrencySymbol]}</IconWrapper>
            <CurrencyInfo>
              <CurrencyAmount>
                {`${balance} ${systemCurrencySymbol}`}
                <RefreshButton
                  onUpdate={onUpdate}
                  disableDebounce
                  isUpdating={isUpdating}
                  isError={isError}
                />
              </CurrencyAmount>
              <SubText>
                {`Last updated: ${
                  updateDateTimestamp
                    ? formatDate(new Date(updateDateTimestamp).toJSON())
                    : '–'
                }`}
              </SubText>
              <StyledCurrencyAddress disableShrink text={address} />
            </CurrencyInfo>
          </CurrencyInfoWrapper>
          <StyledReplaceButton onClick={onConfirmReplacing} variant='bordered'>
            Replace
          </StyledReplaceButton>
        </CurrencyWrapper>

        <ItemsWrapper>
          <Item>
            <Row>
              <IconBG>
                <ICONS_MAP.SumOfDepositToBeDone />
              </IconBG>
              <Title>Sum of Deposit to be done</Title>
            </Row>
            <Row>
              <Text>{amountToDeposit}</Text>
            </Row>
          </Item>
          <Item>
            <Row>
              <IconBG>
                <ICONS_MAP.TotalWithdrawalFee />
              </IconBG>
              <Title>Total Withdrawal Fee</Title>
            </Row>
            <Row>
              <Text>{recommendedBalance}</Text>
            </Row>
          </Item>

          <Item>
            <Row>
              <IconBG>
                <ICONS_MAP.Available />
              </IconBG>
              <Title>Amount of Wallets</Title>
            </Row>
            <Row>
              <Text>{walletCount}</Text>
            </Row>
          </Item>
        </ItemsWrapper>
      </Container>

      {!!transactionsCompletedCount && (
        <StyledAlert icon={<ICONS_MAP.WarningRed />} severity='error'>
          <StyledMessageBox>
            <div>
              <strong>
                We recommend replace your system wallet to keep your data safe
              </strong>
              <p>
                Have been&nbsp;
                {transactionsCompletedCount}
                &nbsp;withdrawals after the last replacement
              </p>
            </div>
            <Button onClick={onConfirmReplacing} variant='bordered'>
              Replace System Wallet
            </Button>
          </StyledMessageBox>
        </StyledAlert>
      )}

      {!isBalanceEnough && (
        <StyledAlert icon={<ICONS_MAP.WarningYellow />} severity='warning'>
          The balance does not match the recommended balance. The deposit is needed in
          case of withdrawal from all groups
        </StyledAlert>
      )}
    </>
  );
};

export default SystemWalletStatistic;
