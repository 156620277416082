import styled from 'styled-components';

import { Logo, Slider } from 'components';
import { COLORS } from 'constants/styles';

import bgImage from 'assets/imgs/bg.png';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  background: url(${bgImage}) no-repeat center center fixed;
  background-size: cover;
`;

export const StyledLogo = styled(Logo)`
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
  width: 275px;
  height: 71px;
`;

export const StyledSlider = styled(Slider)`
  width: 100%;
  height: 175px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SliderItem = styled.div``;

export const TextBlock = styled.div`
  width: 400px;
  text-align: center;
  color: ${COLORS.WHITE};
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-family: inherit;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 16px;
`;

export const Description = styled.div`
  font-family: inherit;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;
