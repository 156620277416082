import { axiosExchangerInstance } from 'api/base';
import {
  normalizeExchangeSecrets,
  normalizeMarketPrice,
  normalizeMarketsPrices,
  normalizeWithdrawalLimits,
  normalizeWithdrawToWalletTransaction,
} from 'api/wallets/normalizers';
import {
  ICurrency,
  IPreparedTransactionBTC,
  IPrepareWithdrawalToWalletPayload,
} from 'api/wallets/types';
import { saveLog } from 'utils/logToStorage';

import { CURRENCIES } from '../wallets/constants';

import {
  normalizeActiveWallet,
  normalizeAvailablePlatforms,
  normalizeCurrencies,
  normalizeDepositHistory,
  normalizeHistory,
  normalizeOrders,
  normalizePlatformBalances,
  normalizePlatforms,
} from './normalizers';
import {
  IActiveWallet,
  IAvailablePlatform,
  ICreateOrderPayload,
  IDepositHistory,
  IDepositHistoryPayload,
  IExchangerHistoryPayload,
  IExchangerHistoryResult,
  IExchangerSecrets,
  IExchangerWithdrawalLimits,
  IGetExchangerSecrets,
  IGetMarketPricePayload,
  IGetMarketPriceResult,
  IGetMarketsPricesResult,
  IGetOrdersPayload,
  IOrderResponse,
  IPlatform,
  IPlatformBalance,
  IPrepareDepositTransactionPayload,
  IRemoveAccountPayload,
  IRemoveOrderPayload,
  IReplaceWalletPayload,
  ISendDepositTransactionPayload,
  ISendExchangerRemainsPayload,
  ISetupAccountPayload,
} from './types';

const exchangerAPI = {
  async getCurrencies(): Promise<ICurrency[]> {
    const result = await axiosExchangerInstance.get(`exchanger/currencies`);
    return normalizeCurrencies(result.data);
  },

  async getActiveWallet({
    currencySymbol,
  }: {
    currencySymbol: CURRENCIES;
  }): Promise<IActiveWallet> {
    const result = await axiosExchangerInstance.get('/wallets/addresses/system/active', {
      params: {
        currencySymbol,
      },
    });

    return normalizeActiveWallet(result.data);
  },

  async getPlatforms(): Promise<IPlatform[]> {
    const result = await axiosExchangerInstance.get('/exchanger/exchanges');
    return normalizePlatforms(result.data);
  },

  async getPlatformBalances(): Promise<IPlatformBalance[]> {
    const result = await axiosExchangerInstance.get('/exchanger/balance');
    return normalizePlatformBalances(result.data);
  },

  async replaceWallet(payload: IReplaceWalletPayload): Promise<unknown> {
    const { currencySymbol } = payload;

    const result = await axiosExchangerInstance.put(
      '/wallets/addresses/system/replace',
      {},
      { params: { currencySymbol } }
    );

    return result.data;
  },

  async getOrders(payload: IGetOrdersPayload): Promise<IOrderResponse[]> {
    const { currencySymbol, orderId } = payload;

    const result = await axiosExchangerInstance.get('/exchanger/order', {
      params: {
        currencySymbol,
        ...(orderId && { orderId }),
      },
    });

    return normalizeOrders(result.data);
  },

  async getAvailablePlatforms(): Promise<IAvailablePlatform[]> {
    const result = await axiosExchangerInstance.get('/exchanger/exchanges-available');
    return normalizeAvailablePlatforms(result.data);
  },

  async getExchangerHistory(
    payload: IExchangerHistoryPayload
  ): Promise<IExchangerHistoryResult[]> {
    const result = await axiosExchangerInstance.get('/wallets/history', {
      params: { currencySymbol: payload.currencySymbol },
    });
    return normalizeHistory(result.data);
  },

  async getExchangerDepositHistory(
    payload: IDepositHistoryPayload
  ): Promise<IDepositHistory> {
    const result = await axiosExchangerInstance.get('/wallets/deposit-history', {
      params: { currencySymbol: payload.currencySymbol, address: payload.address },
    });
    return normalizeDepositHistory(result.data);
  },

  async getPreparedWithdrawToWalletTransaction({
    walletAddress,
    currencySymbol,
    fromAddress,
  }: IPrepareWithdrawalToWalletPayload): Promise<IPreparedTransactionBTC> {
    const params = {
      currencySymbol,
      address: fromAddress,
    };

    const result = await axiosExchangerInstance.post(
      `/wallets/transactions/system/prepare`,
      { receiverAddress: walletAddress },
      { params }
    );

    saveLog(result?.request?.responseURL || '');

    return normalizeWithdrawToWalletTransaction(result.data);
  },

  async sendWithdrawalToWalletTransaction(
    payload: ISendExchangerRemainsPayload
  ): Promise<void> {
    const { transactionData, currencySymbol } = payload;
    const params = { currencySymbol };

    await axiosExchangerInstance.post(
      `wallets/transactions/system/send`,
      transactionData,
      { params }
    );
  },

  async getExchangerWithdrawalLimits(): Promise<IExchangerWithdrawalLimits[]> {
    const result = await axiosExchangerInstance.get('/exchanger/withdrawal-limits');
    return normalizeWithdrawalLimits(result.data);
  },

  async getExchangerSecrets(payload: IGetExchangerSecrets): Promise<IExchangerSecrets[]> {
    const result = await axiosExchangerInstance.get('/exchanger/exchanges-secrets', {
      params: {
        exchangeId: payload.exchangerId,
      },
    });
    return normalizeExchangeSecrets(result.data);
  },

  async setupAccount(payload: ISetupAccountPayload): Promise<void> {
    const { exchangeId, apiKey, secret, currencySymbol, withdrawalLimit, address } =
      payload;

    await axiosExchangerInstance.post(`/exchanger/setup/${exchangeId}/account`, {
      apiKey,
      secret,
      exchangeDetails: [
        {
          currencySymbol,
          withdrawalLimit,
          address,
        },
      ],
    });
  },

  async removeAccount(payload: IRemoveAccountPayload): Promise<void> {
    await axiosExchangerInstance.delete(`/exchanger/${payload.exchangeId}/account`);
  },

  async createOrder(payload: ICreateOrderPayload): Promise<void> {
    const { price, quantity, symbol, exchangeId } = payload;

    await axiosExchangerInstance.post(`/exchanger/${exchangeId}/order`, {
      price,
      quantity,
      symbol,
    });
  },

  async removeOrder(payload: IRemoveOrderPayload): Promise<void> {
    const { currencySymbol, orderId, exchangeIds } = payload;

    await axiosExchangerInstance.delete(`/exchanger/order`, {
      params: {
        currencySymbol,
        ...(exchangeIds && { exchangeIds }),
        ...(orderId && { orderId }),
      },
    });
  },

  async getMarketPrice(payload: IGetMarketPricePayload): Promise<IGetMarketPriceResult> {
    const { currencySymbol } = payload;

    const result = await axiosExchangerInstance.get('exchanger/currency-price', {
      params: {
        currencySymbol,
      },
    });

    return normalizeMarketPrice(result.data);
  },

  async getMarketsPrices(
    payload: IGetMarketPricePayload
  ): Promise<IGetMarketsPricesResult> {
    const { currencySymbol } = payload;

    const result = await axiosExchangerInstance.get('exchanger/exchange-currency-price', {
      params: {
        currencySymbol,
      },
    });

    return normalizeMarketsPrices(result.data);
  },

  async getPrepareDepositTransactions(
    payload: IPrepareDepositTransactionPayload
  ): Promise<IPreparedTransactionBTC> {
    const { transactionParams, currencySymbol, currencyType } = payload;
    const params = { currencySymbol };

    const result = await axiosExchangerInstance.post(
      `/wallets/transactions/${currencyType}/prepare`,
      { transactionParams },
      { params }
    );

    saveLog(result?.request?.responseURL || '');

    return normalizeWithdrawToWalletTransaction(result.data);
  },

  async sendDepositTransactions(payload: ISendDepositTransactionPayload): Promise<void> {
    const { currencyType, transactionData, currencySymbol } = payload;
    const params = { currencySymbol };
    await axiosExchangerInstance.post(
      `wallets/transactions/${currencyType}/send`,
      transactionData,
      { params }
    );
  },
};

export default exchangerAPI;
