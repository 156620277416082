import { normalizeExport } from 'api/headquarters/normalizers';
import { IExport } from 'api/headquarters/types';
import { saveLog } from 'utils/logToStorage';

import { axiosInstance } from '../base';

import { CURRENCIES } from './constants';
import {
  IWalletsValidationResponse,
  normalizeBlockedWallets,
  normalizeCurrencies,
  normalizeCurrentBlock,
  normalizeRestoreWalletInfo,
  normalizeSystemTrxStatus,
  normalizeSystemWalletHistory,
  normalizeSystemWallets,
  normalizeTransactionDetails,
  normalizeWalletInfo,
  normalizeWalletsValidation,
  normalizeWithdrawalHistory,
  normalizeWithdrawalSetting,
  normalizeWithdrawalWallet,
  normalizeWithdrawToWalletTransactions,
} from './normalizers';
import {
  IBlockedWalletsResult,
  IBlockWalletPayload,
  ICurrency,
  ICurrentBlockResult,
  IDeactivateWalletsPayload,
  IGetCurrentBlockPayload,
  IGetSystemTransactionStatusPayload,
  IGetWalletInfoPayload,
  IPrepareTransactionPayload,
  IPrepareWithdrawalToWalletPayload,
  IPrepareWithdrawalToWalletResponse,
  IReplaceSystemWalletPayload,
  IRestoreWalletPayload,
  IRestoreWalletResult,
  ISendTransactionPayload,
  ISendWithdrawalToWalletPayload,
  ISetting,
  ISystemTrxStatus,
  ISystemWallet,
  ISystemWalletHistoryResult,
  ITransactionDetails,
  IUpdateSettingsPayload,
  IUploadWalletsPayload,
  IValidateWithdrawalAddressesPayload,
  IValidateWithdrawalWalletsPayload,
  IValidateWithdrawalWalletsResult,
  IWalletInfo,
  IWithdrawalHistory,
  IWithdrawalWallet,
  TPreparedTransaction,
} from './types';

const walletsAPI = {
  async getCurrencies(): Promise<ICurrency[]> {
    const result = await axiosInstance.get('/wallets/currencies');
    return normalizeCurrencies(result.data);
  },

  async validateForWithdrawal({
    currency,
    csvFile,
  }: IValidateWithdrawalWalletsPayload): Promise<IValidateWithdrawalWalletsResult> {
    const result = await axiosInstance.post<IWalletsValidationResponse>(
      `wallets/withdrawal/validate/${currency}`,
      csvFile
    );
    return normalizeWalletsValidation(result.data);
  },

  async validateAddressesForWithdrawal(
    currency: string,
    payload: IValidateWithdrawalAddressesPayload
  ): Promise<IValidateWithdrawalWalletsResult> {
    const result = await axiosInstance.put<IWalletsValidationResponse>(
      `wallets/withdrawal/validate/${currency}`,
      payload
    );
    return normalizeWalletsValidation(result.data);
  },

  async uploadWallets(data: IUploadWalletsPayload): Promise<IWithdrawalWallet[]> {
    const result = await axiosInstance.put('wallets/withdrawal/upload', {
      ...data,
      headquarterId: +data.headquarterID,
    });
    const wallets = Array.isArray(result.data) ? result.data : [];
    return wallets
      .map(normalizeWithdrawalWallet)
      .map((item) => ({ ...item, isActive: true }));
  },

  async blockWallet(data: IBlockWalletPayload): Promise<void> {
    const { address, untilBy, otp } = data;

    await axiosInstance.put(`/wallets/block/${address}`, {
      untilBy,
      otp,
    });
  },

  async exportWalletsCSVExample(): Promise<IExport> {
    const result = await axiosInstance.get('/wallets/withdrawal/validate/example');
    return normalizeExport(result.data);
  },

  async deactivateWallets(payload: IDeactivateWalletsPayload): Promise<void> {
    const result = await axiosInstance.post('/wallets/withdrawal/deactivate', payload);
    return result.data;
  },

  async getWithdrawalWallets(
    headquarterID: string,
    currency: CURRENCIES
  ): Promise<IWithdrawalWallet[]> {
    const result = await axiosInstance.get(
      `/headquarters/${headquarterID}/withdrawal_wallets`,
      { params: { currencySymbol: currency } }
    );
    const wallets = Array.isArray(result.data?.withdrawalWallets)
      ? result.data.withdrawalWallets
      : [];
    return wallets.map(normalizeWithdrawalWallet);
  },

  async getWithdrawalHistory(
    groupID: string,
    currency: CURRENCIES
  ): Promise<IWithdrawalHistory> {
    const result = await axiosInstance.get(
      `headquarters/groups/${groupID}/withdrawal_history`,
      { params: { currencySymbol: currency } }
    );
    return normalizeWithdrawalHistory(result.data);
  },

  async getWithdrawalTransaction({
    currencyType,
    groupID,
    skipAmount,
    currencySymbol,
  }: IPrepareTransactionPayload): Promise<TPreparedTransaction> {
    const params = { skipAmount, currencySymbol };

    const result = await axiosInstance.get(
      `wallets/transactions/${currencyType}/prepare/${groupID}`,
      {
        timeout: 120000,
        params,
      }
    );

    saveLog(result?.request?.responseURL || '');

    return result.data;
  },

  async sendWithdrawalTransaction(payload: ISendTransactionPayload): Promise<void> {
    const { currencySymbol, currencyType, groupId, transactions, isSystem } = payload;
    await axiosInstance.post(
      `wallets/transactions/${currencyType}/send`,
      { groupId, transactions, isSystem },
      {
        timeout: 120000,
        params: { currencySymbol },
      }
    );
  },

  async getSettings(): Promise<ISetting[]> {
    const result = await axiosInstance.get('/wallets/currencies/settings');
    return result.data?.map(normalizeWithdrawalSetting) || [];
  },

  async updateSettings(payload: IUpdateSettingsPayload): Promise<ISetting[]> {
    const result = await axiosInstance.put('/wallets/currencies/settings', payload);
    return result.data?.map(normalizeWithdrawalSetting) || [];
  },

  async getTransactionDetails(internalID: string): Promise<ITransactionDetails> {
    const result = await axiosInstance.get(`wallets/transactions/${internalID}`);
    return normalizeTransactionDetails(result.data);
  },

  async getSystemWallets(currencySymbol?: CURRENCIES): Promise<ISystemWallet[]> {
    const result = await axiosInstance.get('wallets/system', {
      params: { currencySymbol },
    });
    return normalizeSystemWallets(result.data);
  },

  async getSystemTransactionStatus(
    payload: IGetSystemTransactionStatusPayload
  ): Promise<ISystemTrxStatus> {
    const result = await axiosInstance.get(
      `wallets/system/transactions/status/${payload.groupID}`,
      {
        params: { currencySymbol: payload.currencySymbol },
      }
    );
    return normalizeSystemTrxStatus(result.data);
  },

  async getCurrentBlock(payload: IGetCurrentBlockPayload): Promise<ICurrentBlockResult> {
    const result = await axiosInstance.get(`wallets/last-block/time`, {
      params: { currencySymbol: payload.currencySymbol },
    });

    return normalizeCurrentBlock(result.data);
  },

  async getWalletInfo(payload: IGetWalletInfoPayload): Promise<IWalletInfo> {
    const { address } = payload;

    const result = await axiosInstance.get(`wallets/info/${address}`);
    return normalizeWalletInfo(result.data);
  },

  async restoreWallet(payload: IRestoreWalletPayload): Promise<IRestoreWalletResult> {
    const { address } = payload;

    const result = await axiosInstance.put(`wallets/restore/${address}`);
    return normalizeRestoreWalletInfo(result.data);
  },

  async replaceSystemWallet(payload: IReplaceSystemWalletPayload): Promise<unknown> {
    const { currencySymbol } = payload;

    const result = await axiosInstance.post(
      '/wallets/replace-system-wallet',
      {},
      { params: { currencySymbol } }
    );

    return result.data;
  },

  async getSystemWalletHistory(
    currencySymbol: CURRENCIES
  ): Promise<ISystemWalletHistoryResult[]> {
    const result = await axiosInstance.get('/wallets/system_history', {
      params: { currencySymbol },
    });

    return normalizeSystemWalletHistory(result.data);
  },

  async getBlockedWallets(currencySymbol: CURRENCIES): Promise<IBlockedWalletsResult[]> {
    const result = await axiosInstance.get('/wallets/blocked', {
      params: { currencySymbol },
    });

    return normalizeBlockedWallets(result.data);
  },

  async getPreparedWithdrawToWalletTransaction({
    fromAddress,
    walletAddress,
    currencySymbol,
  }: IPrepareWithdrawalToWalletPayload): Promise<IPrepareWithdrawalToWalletResponse[]> {
    const params = {
      currencySymbol,
      ...(fromAddress && { address: fromAddress }),
    };

    const result = await axiosInstance.post(
      `/wallets/transactions/system/prepare`,
      { receiverAddress: walletAddress },
      { params }
    );

    saveLog(result?.request?.responseURL || '');

    return normalizeWithdrawToWalletTransactions(result.data);
  },

  async sendWithdrawalToWalletTransaction(
    payload: ISendWithdrawalToWalletPayload
  ): Promise<void> {
    const { transactions, currencySymbol } = payload;
    const params = { currencySymbol };

    await axiosInstance.post(
      `wallets/transactions/system/send`,
      { transactions },
      { params }
    );
  },
};

export default walletsAPI;
