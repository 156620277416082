import { absoluteLoaderClasses, tooltipClasses } from '@private/components';
import { DataGrid, dataGridClasses, gridSettingsButtonClasses } from '@private/data-grid';
import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const StyledDataGrid = styled(DataGrid)`
  row-gap: 15px;

  ${dataGridClasses.progressBar} {
    background: ${COLORS.GRAY_300};
  }

  ${absoluteLoaderClasses.firstProgress},
  ${absoluteLoaderClasses.secondProgress} {
    background: ${COLORS.GREEN_600};
  }

  ${dataGridClasses.notFoundContainer} {
    margin-top: 80px;
  }

  ${gridSettingsButtonClasses.settingsPopover} {
    width: 320px;

    ${tooltipClasses.message} {
      padding: 24px 20px;
    }
  }

  ${gridSettingsButtonClasses.tableSettingsContainer} {
    width: 100%;
    color: ${COLORS.GRAY_700};
    font-size: 14px;
    line-height: 20px;
  }

  ${gridSettingsButtonClasses.columnsFilterTitle} {
    font-weight: 700;
    font-size: 16px;
  }

  ${gridSettingsButtonClasses.columnsFilterHeader} {
    font-weight: 700;
    font-size: 14px;
  }

  ${gridSettingsButtonClasses.enableAllButton} {
    border-radius: 8px;
    font-size: 14px;
  }

  ${gridSettingsButtonClasses.columnFilterWrapper} {
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    padding: 10px;
    padding-left: 18px;
  }
` as typeof DataGrid;
