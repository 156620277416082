import { ICurrency } from 'api/wallets/types';
import sortByDate from 'utils/sortByDate';

import { Networks } from '../wallets/types';

import {
  IActiveWallet,
  IAvailablePlatform,
  IDepositHistory,
  IDepositHistoryTransaction,
  IExchangerCurrencyResponse,
  IExchangerHistory,
  IExchangerHistoryResult,
  IOrderResponse,
  IPlatform,
  IPlatformBalance,
} from './types';

export function normalizeCurrency(currencyData: IExchangerCurrencyResponse): ICurrency {
  return {
    currency: currencyData.symbol,
    index: currencyData.index || 0,
    network: currencyData.network || ('mainnet' as Networks),
    explorerUrl: currencyData.explorerUrl,
    type: currencyData.type,
    systemDecimals: currencyData.systemDecimals || 2,
    systemSymbol: currencyData.systemSymbol || null,
    contractAddress: currencyData.contractAddress || '',
    decimals: currencyData.decimals,
  };
}

export function normalizeCurrencies(data: IExchangerCurrencyResponse[]): ICurrency[] {
  return data.map((item) => normalizeCurrency(item));
}

export function normalizeActiveWallet(data: IActiveWallet): IActiveWallet {
  return {
    id: data.id,
    address: data.address,
    derivationIndex: data.derivationIndex,
    deletedAt: data.deletedAt || '-',
    createdAt: data.createdAt,
    seedCurrencyId: data.seedCurrencyId,
    balance: data.balance,
    pendingBalance: data.pendingBalance,
  };
}

export function normalizePlatform(data: IPlatform): IPlatform {
  return {
    id: data.id,
    title: data.title,
    createdAt: data.createdAt,
    isConfigured: data.isConfigured,
  };
}

export function normalizePlatforms(data: IPlatform[]): IPlatform[] {
  return data.filter((item) => item.isConfigured).map((item) => normalizePlatform(item));
}

export function normalizeAvailablePlatforms(
  data: IAvailablePlatform[]
): IAvailablePlatform[] {
  return data.map((item) => ({
    id: item.id,
    title: item.title,
    createdAt: item.createdAt,
  }));
}

export function normalizePlatformBalance(data: IPlatformBalance): IPlatformBalance {
  return {
    exchangeTitle: data.exchangeTitle,
    balances: data.balances,
  };
}

export function normalizePlatformBalances(data: IPlatformBalance[]): IPlatformBalance[] {
  return data.map((item) => normalizePlatformBalance(item));
}

export function normalizeHistoryItem(data: IExchangerHistory): IExchangerHistoryResult {
  return {
    id: data.id,
    address: data.address,
    derivationIndex: data.derivationIndex,
    isActive: data.isActive,
    createdAt: data.createdAt,
    isDeleted: data.isDeleted,
    deletedAt: data.deletedAt || '',
    systemSymbol: data.seedCurrency.currency.systemSymbol,
    currency: data.seedCurrency.currency.symbol,
    type: data.seedCurrency.currency.type,
    network: data.seedCurrency.currency.network,
    transactionsCount: data.transactionsCount || 0,
    balance: data.balance || 0,
  };
}

export function normalizeHistory(data: IExchangerHistory[]): IExchangerHistoryResult[] {
  const sorted = sortByDate(data as [], 'createdAt');
  return sorted.map((item) => normalizeHistoryItem(item));
}

export function normalizeDepositHistoryItem(
  data: IDepositHistoryTransaction
): IDepositHistoryTransaction {
  return {
    id: data.id,
    platform: data.platform,
    amount: data.amount,
    status: data.status,
    createdAt: data.createdAt,
  };
}

export function normalizeDepositHistory(data: IDepositHistory): IDepositHistory {
  const sortedTransactions = sortByDate(data.transactions as [], 'createdAt');
  return {
    transactions: sortedTransactions.map((item) => normalizeDepositHistoryItem(item)),
    balance: data.balance,
  };
}

export function normalizeOrders(data: IOrderResponse[]): IOrderResponse[] {
  const sorted = sortByDate(data as [], 'createdAt') as IOrderResponse[];
  return sorted.map((item) => ({
    id: item.id,
    exchangeOrderId: item.exchangeOrderId,
    userId: item.userId,
    exchangeId: item.exchangeId,
    price: item.price,
    quantity: item.quantity,
    status: item.status,
    type: item.type,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    symbol: item.symbol,
    filledQuantity: item.filledQuantity,
    filledPrice: item.filledPrice,
  }));
}
