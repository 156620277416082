import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const StyledMessage = styled.p`
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 14px;
  color: ${COLORS.GRAY_700};
`;
