import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import Button from 'components/Button';
import { COLORS } from 'constants/styles';

export const TextWrapper = styled.div<{ reverse: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
`;

const LinkStyles = css`
  color: ${COLORS.BLUE_500};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.3s ease;

  &:hover {
    color: ${COLORS.BLUE_300};
  }

  &:active {
    color: ${COLORS.BLUE_400};
  }
`;

export const AppLink = styled(Link)`
  ${LinkStyles};
`;

export const StyledLink = styled.a`
  ${LinkStyles};
`;

export const CopyButton = styled(Button)<{ reverse: boolean }>`
  margin-${({ reverse }) => (reverse ? 'left' : 'right')}: 7px;
  color: ${COLORS.GRAY_500};
`;

export const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
