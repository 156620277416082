import React from 'react';
import { useQueryClient } from 'react-query';

import { useModal } from '@private/modals';
import { AxiosError, AxiosResponse } from 'axios';

import { STATUSES } from 'api/auth/constants';
import {
  axiosExchangerInstance,
  axiosInstance,
  axiosMultisenderInstance,
} from 'api/base';
import { useCurrenciesQuery } from 'queries/wallets';
import { readJWTData } from 'utils/common';

import { SESSION_KEYS } from './constants';
import useAuthentication from './useAuthentication';
import useRegister from './useRegister';
import { logoutWithQuery, sessionCache, updateAuthHeader } from './utils';

export const useSessionWatcher = (): void => {
  const startTime = new Date().getTime();
  const WAIT_UNTIL_INITIALIZING = 1300;
  const [isTokenInitialized, setIsTokenInitialized] = React.useState(false);
  const [canStartRequests, setCanStartRequests] = React.useState(false);

  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { isFetched } = useCurrenciesQuery({ enabled: canStartRequests });

  const axiosSuccessInterceptor = React.useCallback((response: AxiosResponse) => {
    return response;
  }, []);

  const axiosErrorInterceptor = React.useCallback(
    (error: AxiosError) => {
      const isTokenFailed = error?.response?.status === STATUSES.AUTHENTICATION;
      if (isTokenFailed) {
        queryClient.setQueryData(SESSION_KEYS.IS_INITIALIZED, true);
        setCanStartRequests(false);
        logoutWithQuery(queryClient);
        closeModal();
        return Promise.reject(error);
      }

      return Promise.reject(error);
    },
    [queryClient]
  );

  const handleTokenInitialization = React.useCallback(() => {
    const sessionData = sessionCache.getStorage();

    if (!sessionData.token) {
      queryClient.setQueryData(SESSION_KEYS.IS_INITIALIZED, true);
      return;
    }

    const tokenData = readJWTData(sessionData.token);
    const threshold = 60 * 1000;

    const tokenInvalid = !tokenData || tokenData.exp < Date.now() + threshold;

    if (tokenInvalid || !tokenData?.is2FASet || !tokenData?.isSeedSet) {
      sessionCache.clear();
      setCanStartRequests(false);
      setIsTokenInitialized(true);
      return;
    }

    updateAuthHeader(sessionData.token);
    queryClient.setQueryData([SESSION_KEYS.TOKEN], sessionData.token);
    if (tokenData?.is2faValidated) {
      queryClient.setQueryData([SESSION_KEYS.IS_2FA_PASSED], true);
      setCanStartRequests(true);
    }
    setIsTokenInitialized(true);
  }, [queryClient]);

  React.useEffect(() => {
    handleTokenInitialization();

    const responseInterceptors = {
      base: axiosInstance.interceptors.response.use(
        axiosSuccessInterceptor,
        axiosErrorInterceptor
      ),
      multisender: axiosMultisenderInstance.interceptors.response.use(
        axiosSuccessInterceptor,
        axiosErrorInterceptor
      ),
      exchanger: axiosExchangerInstance.interceptors.response.use(
        axiosSuccessInterceptor,
        axiosErrorInterceptor
      ),
    };

    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptors.base);
      axiosExchangerInstance.interceptors.response.eject(responseInterceptors.exchanger);
      axiosMultisenderInstance.interceptors.response.eject(
        responseInterceptors.multisender
      );
    };
  }, [axiosErrorInterceptor, queryClient]);

  React.useEffect(() => {
    const isWaitingCurrencies = canStartRequests && !isFetched;
    if (!isTokenInitialized || isWaitingCurrencies) {
      return;
    }

    const diff = new Date().getTime() - startTime;
    if (diff < WAIT_UNTIL_INITIALIZING) {
      setTimeout(() => {
        queryClient.setQueryData(SESSION_KEYS.IS_INITIALIZED, true);
      }, WAIT_UNTIL_INITIALIZING - diff);
    }
  }, [queryClient, isFetched, isTokenInitialized, canStartRequests]);
};

export { useAuthentication, useRegister };
