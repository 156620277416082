import React from 'react';
import { Outlet } from 'react-router-dom';

import {
  Container,
  Description,
  ImageContainer,
  SliderItem,
  StyledLogo,
  StyledSlider,
  TextBlock,
  Title,
} from './styled';

const TEXT_MAP = [
  {
    title: 'Benefits of HD wallets',
    description: 'You can protect your financial privacy.',
  },
  {
    title: 'Benefits of HD wallets',
    description: 'You can generate many Bitcoin addresses every time you receive funds.',
  },
  {
    title: 'Benefits of HD wallets',
    description:
      'You only need to back up one key (ie the mnemonic "seed phrase").This is the only backup you will ever need.',
  },
];

const UnauthorizedStackLayout = (): React.ReactElement => {
  return (
    <Container>
      <ImageContainer>
        <StyledLogo disableLink />
        <StyledSlider dots autoplay>
          {TEXT_MAP.map((text, index) => (
            <SliderItem key={index}>
              <TextBlock>
                <Title>{text.title}</Title>
                <Description>{text.description}</Description>
              </TextBlock>
            </SliderItem>
          ))}
        </StyledSlider>
      </ImageContainer>
      <Outlet />
    </Container>
  );
};

export default UnauthorizedStackLayout;
