import { useBreadcrumbs } from 'hooks';

import { Active, Container, Disabled } from './styled';

interface IBreadcrumbsProps {
  className?: string;
}

const Breadcrumbs = ({ className }: IBreadcrumbsProps): React.ReactElement => {
  const breadcrumbs = useBreadcrumbs();
  return (
    <Container className={className} data-testid='breadcrumbs'>
      {breadcrumbs.map(({ url, breadcrumb }, index) => {
        if (breadcrumbs.length - 1 === index) {
          return <Disabled key={url}>{breadcrumb}</Disabled>;
        }
        return (
          <Active to={url} key={url}>
            {breadcrumb}
          </Active>
        );
      })}
    </Container>
  );
};

export default Breadcrumbs;
