import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const DropzoneStyled = styled.div`
  height: 120px;
  margin-top: 20px;

  border: 2px dashed ${COLORS.GRAY_300};
  border-radius: 8px;

  display: flex;
  width: 100%;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  user-select: none;

  &:hover {
    background: ${COLORS.GRAY_LIGHT_150};
  }

  &:active {
    background: ${COLORS.GRAY_LIGHT_300};
  }
`;

export const DropzoneContent = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.GRAY_700};
  text-align: center;
`;

export const BlueContent = styled.span`
  text-align: center;
  color: ${COLORS.BLUE_400};
`;
