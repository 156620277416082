import React from 'react';

import { AutocompleteProps } from '@mui/material/Autocomplete';

import { appReactMemo } from 'hocs';

import Checkbox from './components/Checkbox';
import Chip from './components/Chip';
import TextField from './components/TextField';

import {
  StyledAutocomplete,
  StyledCancelIcon,
  StyledDropdownIcon,
  StyledMenuItem,
  StyledPaper,
} from './styled';

export interface IOption {
  id: string;
  label: string;
  selected: 'selected' | 'notselected';
}

export interface IMultiselectProps {
  data: Array<IOption>;
  className?: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  onChange: (options: IOption[]) => void;
}

type TMultiselectParentProps = AutocompleteProps<IOption, boolean, boolean, boolean>;

const Multiselect = ({
  data,
  className,
  onChange,
  label,
  required,
  disabled,
}: IMultiselectProps) => {
  const [options, setOptions] = React.useState<IOption[]>(data);
  const filteredOptions = React.useRef<IOption[]>(data);

  React.useEffect(() => {
    setOptions(data);
  }, [data]);

  const renderInput: TMultiselectParentProps['renderInput'] = (params) => (
    <TextField
      {...params}
      InputLabelProps={{ shrink: false }}
      required={required}
      label={label}
      placeholder='Favorites'
    />
  );

  const renderTags = React.useCallback(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    (option: IOption[], getTagProps: any) =>
      option.map((chip: IOption, index: number) => (
        <Chip {...getTagProps({ index })} label={chip.label} />
      )),
    []
  );

  const renderOption: TMultiselectParentProps['renderOption'] = (props, option) => (
    <StyledMenuItem {...props} key={option.id} data-testid='menu-item'>
      <Checkbox checked={option.selected === 'selected'} style={{ marginRight: 8 }} />
      {option.label}
    </StyledMenuItem>
  );

  const renderGroup: TMultiselectParentProps['renderGroup'] = (params) => {
    const allSelected = options.every((item) => item.selected === 'selected');
    const onClick = () => {
      if (allSelected) {
        const newOptions: IOption[] = options.map((item) => ({
          ...item,
          selected: 'notselected',
        }));
        setOptions(newOptions);
      } else {
        const newOptions: IOption[] = options.map((option) => {
          if (filteredOptions.current.find((item) => item.id === option.id)) {
            return { ...option, selected: 'selected' };
          }
          return option;
        });
        setOptions(newOptions);
        onChange(newOptions);
      }
    };

    return (
      <div key={params.key}>
        {(params.group === 'notselected' || allSelected) && (
          // eslint-disable-next-line
          <div onClick={onClick}>
            <Checkbox label='All' checked={allSelected} style={{ marginRight: 8 }} />
          </div>
        )}
        {params?.children}
      </div>
    );
  };

  const handleChange: TMultiselectParentProps['onChange'] = (
    _event,
    _value,
    reason,
    selectedOption
  ) => {
    const newOptions: IOption[] = options.map((option) => {
      if (reason === 'clear') {
        return {
          ...option,
          selected: 'notselected',
        };
      }
      if (option.id === selectedOption?.option.id) {
        return {
          ...option,
          selected: option.selected === 'selected' ? 'notselected' : 'selected',
        };
      }
      return option;
    });
    setOptions(newOptions);
    onChange(newOptions);
  };

  const sortOptions = () =>
    options.sort((a, b) => {
      const comparedBySelected = b.selected.localeCompare(a.selected);
      if (comparedBySelected !== 0) {
        return comparedBySelected;
      }
      return -b.label.localeCompare(a.label);
    });

  const filterOptions: TMultiselectParentProps['filterOptions'] = (
    initialOptions,
    config
  ) => {
    const searchResult = initialOptions.filter((option) =>
      option.label.toLowerCase().includes(config.inputValue.toLowerCase())
    );
    filteredOptions.current = !searchResult.length ? initialOptions : searchResult;
    return searchResult;
  };

  return (
    <StyledAutocomplete
      id='multiselect'
      className={className}
      multiple
      disabled={disabled}
      disableCloseOnSelect
      limitTags={3}
      openOnFocus
      filterOptions={filterOptions}
      options={sortOptions()}
      value={options.filter((item) => item.selected === 'selected')}
      groupBy={(option) => option.selected}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleChange}
      renderInput={renderInput}
      renderTags={renderTags}
      renderOption={renderOption}
      renderGroup={renderGroup}
      PaperComponent={StyledPaper}
      popupIcon={<StyledDropdownIcon />}
      clearIcon={<StyledCancelIcon />}
    />
  );
};

export default appReactMemo(Multiselect);
