import React from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import BIP32Factory from 'bip32';
import * as bip39 from 'bip39';
import { Network, networks } from 'bitcoinjs-lib';
// eslint-disable-next-line
// @ts-ignore
import * as ecc from 'tiny-secp256k1';

import { IBackupCurrencyInfo } from 'api/auth/types';
import { CURRENCIES } from 'api/wallets/constants';
import { ICurrency } from 'api/wallets/types';
import { useGetExchangerCurrencies } from 'queries/exchanger';
import { useGetMultisenderCurrencies } from 'queries/multisender';
import { useCurrenciesQuery } from 'queries/wallets';

export const SECURITY_BACKUPS = '@security/backups';
export const SECURITY_MULTISENDER_BACKUPS = '@security/multisender-backups';
export const SECURITY_EXCHANGER_BACKUPS = '@security/exchanger-backups';

export const COIN_DERIVE_INDEXES = {
  mainnet: {
    bitcoin: 0,
    ethereum: 60,
    'bitcoin-cash': 145,
    litecoin: 2,
  },
  testnet: {
    bitcoin: 1,
    ethereum: 60,
    'bitcoin-cash': 145,
    litecoin: 1,
  },
};

export const getSecretFromSeed = (
  seed: string
): { secret: string; remaining: string } => {
  let secret = '';
  let remaining = '';
  for (let i = 0; i < seed.length; i += 1) {
    // every 10nth element
    if (i % 10 === 9) {
      secret += seed[i];
    } else {
      remaining += seed[i];
    }
  }

  return {
    secret,
    remaining,
  };
};

export const getSeedFromSecret = (secret: string, remaining: string): string => {
  let result = '';
  let j = 0;
  for (let i = 0; i < remaining.length; i += 1) {
    result += remaining[i];
    const nextIndex = i + 1;
    // before every 10nth element
    if (nextIndex % 9 === 0) {
      result += secret[j];
      j += 1;
    }
  }

  return result;
};

interface ISecurityBackup {
  secretFromSeed: string;
  seedRemainings: string;
  mnemonic: string[];
  pubKeys: IBackupCurrencyInfo[];
}

export const generateSecurityBackups = async (
  data?: ICurrency[]
): Promise<ISecurityBackup> => {
  if (!data || !data.length) {
    return {
      mnemonic: [],
      secretFromSeed: '',
      seedRemainings: '',
      pubKeys: [],
    };
  }
  const bip32 = BIP32Factory(ecc);
  const mnemonic = bip39.generateMnemonic(256);
  const seed = await bip39.mnemonicToSeed(mnemonic);
  const seedString = seed.toString('hex');

  const currenciesPubKeys = data.reduce((acc, item) => {
    if (!Number.isInteger(item.index)) {
      return acc;
    }

    const params: [Buffer, Network?] = [seed];
    if (item.currency.includes(CURRENCIES.BTC) && networks[item.network]) {
      params.push(networks[item.network]);
    }

    acc.push({
      currencySymbol: item.currency,
      pubKey: bip32
        .fromSeed(...params)
        .deriveHardened(44)
        .deriveHardened(item.index)
        .deriveHardened(0)
        .neutered()
        .toBase58(),
    });
    return acc;
  }, [] as IBackupCurrencyInfo[]);

  const { secret, remaining } = getSecretFromSeed(seedString);

  return {
    pubKeys: currenciesPubKeys,
    mnemonic: mnemonic.split(' '),
    secretFromSeed: secret,
    seedRemainings: remaining,
  };
};

interface IUseSecurityBackup {
  isMultisenderBackup: boolean;
  isExchangerBackup: boolean;
}

export const useSecurityBackup = (
  payload?: IUseSecurityBackup
): UseQueryResult<ISecurityBackup> => {
  const isMultisender = React.useMemo(() => {
    return !!payload?.isMultisenderBackup;
  }, [payload]);

  const isExchanger = React.useMemo(() => {
    return !!payload?.isExchangerBackup;
  }, [payload]);

  const { data, isFetched } = useCurrenciesQuery({
    enabled: !isMultisender && !isExchanger,
  });

  const { data: multisenderCurrencyData, isFetched: isFetchedMultisenderCurrency } =
    useGetMultisenderCurrencies({
      config: {
        enabled: isMultisender,
      },
    });

  const { data: exchangerCurrencyData, isFetched: isFetchedExchangerCurrency } =
    useGetExchangerCurrencies({
      config: {
        enabled: isExchanger,
      },
    });

  const queryData = React.useMemo(() => {
    if (isMultisender) {
      return {
        data: multisenderCurrencyData,
        isFetched: isFetchedMultisenderCurrency,
        queryKey: SECURITY_MULTISENDER_BACKUPS,
      };
    }

    if (isExchanger) {
      return {
        data: exchangerCurrencyData,
        isFetched: isFetchedExchangerCurrency,
        queryKey: SECURITY_EXCHANGER_BACKUPS,
      };
    }

    return {
      data,
      isFetched,
      queryKey: SECURITY_BACKUPS,
    };
  }, [
    isFetchedMultisenderCurrency,
    isFetchedExchangerCurrency,
    isFetched,
    multisenderCurrencyData,
    exchangerCurrencyData,
    isMultisender,
    data,
  ]);

  return useQuery<ISecurityBackup>(
    [queryData.queryKey],
    () => generateSecurityBackups(queryData.data),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: queryData.isFetched && !!queryData.data?.length,
    }
  );
};
