import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const LoaderHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.WHITE};
  z-index: 20;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  padding: 30px 40px 50px 40px;
`;
