import Close from '@mui/icons-material/Close';
import Chip, { chipClasses } from '@mui/material/Chip';
import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const StyledChip = styled(Chip)`
  &.${chipClasses.root} {
    background-color: ${COLORS.BLUE_300};
    color: ${COLORS.WHITE};
    height: 20px;

    .${chipClasses.deleteIcon} {
      color: ${COLORS.WHITE};
      font-size: 15px;
      transition: all 0.3s ease;
    }
  }
`;

export const StyledClose = styled(Close)`
  color: ${COLORS.WHITE};
  font-size: 8px;
`;
