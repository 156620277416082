import React from 'react';

import { TButtonTypes } from '@private/components';
import { useModal } from '@private/modals';

import { ModalContainer } from 'components';

import { StyledDescription } from './styled';

export interface IConfirmModalConfig {
  title: string;
  confirmBtnType?: TButtonTypes;
  message: string | React.ReactElement;
  confirmText?: string;
  onConfirm: () => void;
  isHideRightButton?: boolean;
}

export interface IConfirmModalProps extends IConfirmModalConfig {
  onClose: () => void;
}

const ConfirmModal = ({
  title,
  confirmBtnType = 'primary',
  message,
  confirmText = 'Confirm',
  onClose,
  onConfirm,
  isHideRightButton,
}: IConfirmModalProps): React.ReactElement => {
  return (
    <ModalContainer
      title={title}
      rightBtnText={confirmText}
      rightBtnVariant={confirmBtnType}
      onDismiss={onClose}
      onLeftAction={onClose}
      onRightAction={onConfirm}
      isHideRightButton={isHideRightButton}
    >
      <StyledDescription>{message}</StyledDescription>
    </ModalContainer>
  );
};

type TUseConfirmModalResult = [(config: IConfirmModalConfig) => void, () => void];

export const useConfirmModal = (): TUseConfirmModalResult => {
  const { closeModal, showModal } = useModal();

  const showConfirmModal = React.useCallback((config: IConfirmModalConfig) => {
    showModal(
      <ConfirmModal
        {...config}
        onConfirm={() => {
          config.onConfirm();
          closeModal();
        }}
        onClose={closeModal}
      />
    );
  }, []);

  return [showConfirmModal, closeModal];
};

export default ConfirmModal;
