import React from 'react';
import Slider, { Settings } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { SliderWrapper } from './styled';

interface ISliderProps extends Settings {
  className?: string;
}

const SliderSlick: React.FC<ISliderProps> = ({
  className,
  children,
  arrows = false,
  ...props
}) => {
  return (
    <SliderWrapper className={className}>
      <Slider arrows={arrows} {...props}>
        {children}
      </Slider>
    </SliderWrapper>
  );
};

export default SliderSlick;
