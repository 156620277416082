import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: global.CONFIG.API_URL,
  timeout: 60000,
  timeoutErrorMessage: 'Network Timeout Error',
});

const axiosMultisenderInstance = axios.create({
  baseURL: global.CONFIG.MULTISENDER_API_URL,
  timeout: 60000,
  timeoutErrorMessage: 'Network Timeout Error',
});

const axiosExchangerInstance = axios.create({
  baseURL: global.CONFIG.EXCHANGER_API_URL,
  timeout: 60000,
  timeoutErrorMessage: 'Network Timeout Error',
});

export { axiosInstance, axiosMultisenderInstance, axiosExchangerInstance };
