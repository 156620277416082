import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 25px;
  height: 25px;
`;

export const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
