import React from 'react';

import { ICONS_MAP } from 'constants/icons';

import { Body, Container, Subtitle, Text } from './styled';

interface INotFoundContent {
  className?: string;
  title?: string;
  subtitle?: string;
}

const NotFoundContent: React.FC<INotFoundContent> = ({ className, title, subtitle }) => {
  return (
    <Container className={className}>
      <Body>
        <ICONS_MAP.SadPaper />
        <Text>{title || 'No matching items found'}</Text>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Body>
    </Container>
  );
};

export default NotFoundContent;
