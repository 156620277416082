import React from 'react';

import { INumericInputProps } from '@private/components';

import { StyledNumericInput } from './styled';

const NumericInput: React.FC<INumericInputProps> = (props) => {
  return <StyledNumericInput inputsCount={6} numberLength={1} maxNumber={9} {...props} />;
};

export default NumericInput;
