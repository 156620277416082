import React from 'react';
import { generatePath, Navigate, Outlet } from 'react-router-dom';

import { ROLES } from 'api/auth/constants';
import { IHeadquarter, IHeadquarterGroup } from 'api/headquarters/types';
import { ROUTES } from 'constants/routes';
import queryClient from 'queries';
import { HEADQUARTERS_KEYS } from 'queries/headquarters/constants';
import UnauthorizedStack from 'routes/UnauthorizedStack';
import UnauthorizedLayout from 'routes/UnauthorizedStack/Layout';

import { ellipsisText } from '../utils/ellipsisText';

import {
  INavBarIcons,
  NAVBAR_ICONS,
  NAVBAR_ICONS_NAME,
} from './AuthorizedStack/NavBar/navBarIcons';

const AuthorizedLayout = React.lazy(() => import('routes/AuthorizedStack'));
const Headquarters = React.lazy(() => import('routes/AuthorizedStack/Headquarters'));
const Headquarter = React.lazy(() => import('routes/AuthorizedStack/Headquarter'));
const Group = React.lazy(() => import('routes/AuthorizedStack/Group'));
const Groups = React.lazy(() => import('routes/AuthorizedStack/Groups'));
const Brands = React.lazy(() => import('routes/AuthorizedStack/Brands'));
const Settings = React.lazy(() => import('routes/AuthorizedStack/Settings'));
const TransactionDetails = React.lazy(
  () => import('routes/AuthorizedStack/TransactionDetails')
);
const SystemWallets = React.lazy(() => import('routes/AuthorizedStack/SystemWallets'));
const SystemWalletHistory = React.lazy(
  () => import('routes/AuthorizedStack/SystemWalletHistory')
);
const Multisender = React.lazy(() => import('routes/AuthorizedStack/Multisender'));
const MultisenderHistory = React.lazy(
  () => import('routes/AuthorizedStack/MultisenderHistory')
);
const MultisenderTransactions = React.lazy(
  () => import('routes/AuthorizedStack/MultisenderTransactions')
);
const MultisenderSeed = React.lazy(() => import('routes/UnauthorizedStack/Security'));
const WalletsManagement = React.lazy(
  () => import('routes/AuthorizedStack/WalletsManagement')
);
const Exchanger = React.lazy(() => import('routes/AuthorizedStack/Exchanger'));
const ExchangerSeed = React.lazy(() => import('routes/UnauthorizedStack/Security'));
const ExchangerHistory = React.lazy(
  () => import('routes/AuthorizedStack/ExchangerHistory')
);
const ExchangerTransactions = React.lazy(
  () => import('routes/AuthorizedStack/ExchangerTransactions')
);
const ExchangerSettings = React.lazy(
  () => import('routes/AuthorizedStack/ExchangerSettings')
);
export interface IRouteParams extends Record<string, string | undefined> {
  headquarterID?: string;
  groupID?: string;
  internalID?: string;
}

type TDynamicBreadcrumpFunction = (params?: IRouteParams) => string;
type TDynamicUrlFunction = (params?: IRouteParams) => string;

export interface IRoutesConfigItem extends INavBarIcons {
  component: typeof Headquarters;
  path: string;
  isPrivate?: boolean;
  disableLazy?: boolean;
  children?: IRoutesConfigItem[];
  breadcrumb?: string | TDynamicBreadcrumpFunction;
  url?: string | TDynamicUrlFunction;
  roles?: ROLES[];
  withBalanceWarning?: boolean;
  isNavigation?: boolean;
  isAccordion?: boolean;
  accordionTitle?: string;
  accordionStartIcon?: React.ReactElement;
}

export const ROUTES_CONFIG = [
  {
    component: UnauthorizedLayout,
    path: '/',
    isPrivate: false,
    disableLazy: true,
    children: [
      {
        path: '/',
        component: <Navigate to={ROUTES.SIGN_IN.PATH} replace />,
      },
      {
        path: '/*',
        component: UnauthorizedStack,
      },
    ],
  },
  {
    component: AuthorizedLayout,
    path: '/',
    isPrivate: true,
    roles: [ROLES.FINANCE, ROLES.ADMIN],
    children: [
      {
        component: <Navigate to={ROUTES.HEADQUARTERS.PATH} replace />,
        path: '/',
        roles: [ROLES.FINANCE, ROLES.ADMIN],
      },
      {
        component: () => <Outlet />,
        breadcrumb: 'Headquarters',
        path: ROUTES.HEADQUARTERS.PATH,
        url: ROUTES.HEADQUARTERS.PATH,
        children: [
          {
            component: Headquarters,
            path: ROUTES.HEADQUARTERS.PATH,
            breadcrumb: 'Headquarters',
            roles: [ROLES.FINANCE, ROLES.ADMIN],
            isNavigation: true,
            ...NAVBAR_ICONS[NAVBAR_ICONS_NAME.HEADQUARTERS],
          },
          {
            component: () => <Outlet />,
            path: ROUTES.HEADQUARTER.PATH,
            breadcrumb: (params: IRouteParams): string => {
              const { headquarterID = '' } = params;
              const headquarter = queryClient.getQueryData<IHeadquarter>([
                HEADQUARTERS_KEYS.HEADQUARTERS,
                headquarterID,
              ]);
              return headquarter?.title || `${headquarterID}`;
            },
            url: (params: IRouteParams): string => {
              const { headquarterID } = params;
              if (!headquarterID) {
                return '';
              }
              return generatePath(ROUTES.HEADQUARTER.PATH, {
                [ROUTES.HEADQUARTER.PARAMS.ID]: headquarterID,
              });
            },
            children: [
              {
                component: Headquarter,
                path: ROUTES.HEADQUARTER.PATH,
                roles: [ROLES.FINANCE, ROLES.ADMIN],
              },
              {
                breadcrumb: (params: IRouteParams): string => {
                  const { headquarterID = '', groupID } = params;
                  const group = queryClient.getQueryData<IHeadquarterGroup>([
                    HEADQUARTERS_KEYS.GROUPS,
                    headquarterID,
                    groupID,
                  ]);
                  return group?.title || `${groupID}`;
                },
                url: (params: IRouteParams): string => {
                  const { headquarterID, groupID } = params;
                  if (!groupID || !headquarterID) {
                    return '';
                  }
                  return generatePath(ROUTES.GROUP.PATH, {
                    [ROUTES.GROUP.PARAMS.hqID]: headquarterID,
                    [ROUTES.GROUP.PARAMS.groupID]: groupID,
                  });
                },
                path: ROUTES.GROUP.PATH,
                component: () => <Outlet />,
                isExact: true,
                displayInNavBar: false,
                roles: [ROLES.FINANCE, ROLES.ADMIN],
                children: [
                  {
                    component: Group,
                    path: ROUTES.GROUP.PATH,
                    roles: [ROLES.FINANCE, ROLES.ADMIN],
                  },
                  {
                    breadcrumb: (params: IRouteParams): string => {
                      const { internalID = '' } = params;
                      return internalID;
                    },
                    url: (params: IRouteParams): string => {
                      const { headquarterID, groupID, internalID } = params;
                      if (!groupID || !headquarterID || !internalID) {
                        return '';
                      }
                      return generatePath(ROUTES.TRANSACTION_DETAILS.PATH, {
                        [ROUTES.TRANSACTION_DETAILS.PARAMS.hqID]: headquarterID,
                        [ROUTES.TRANSACTION_DETAILS.PARAMS.groupID]: groupID,
                        [ROUTES.TRANSACTION_DETAILS.PARAMS.internalID]: internalID,
                      });
                    },
                    path: ROUTES.TRANSACTION_DETAILS.PATH,
                    component: TransactionDetails,
                    isExact: true,
                    roles: [ROLES.FINANCE],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.GROUPS.PATH,
        component: Groups,
        breadcrumb: 'Groups',
        roles: [ROLES.FINANCE],
        isNavigation: true,
        ...NAVBAR_ICONS[NAVBAR_ICONS_NAME.GROUPS],
      },
      {
        component: () => <Outlet />,
        path: ROUTES.MULTISENDER_SEED.PATH,
        url: ROUTES.MULTISENDER_SEED.PATH,
        children: [
          {
            path: ROUTES.MULTISENDER_SEED.PATH,
            component: MultisenderSeed,
            breadcrumb: 'Multisender Seed',
            roles: [ROLES.FINANCE],
          },
        ],
      },
      {
        component: () => <Outlet />,
        breadcrumb: 'Multisender wallet',
        path: ROUTES.MULTISENDER.PATH,
        url: ROUTES.MULTISENDER.PATH,
        children: [
          {
            component: Multisender,
            path: ROUTES.MULTISENDER.PATH,
            breadcrumb: 'Multisender wallet',
            roles: [ROLES.FINANCE],
            isNavigation: true,
            ...NAVBAR_ICONS[NAVBAR_ICONS_NAME.MULTISENDER],
          },
          {
            component: () => <Outlet />,
            breadcrumb: 'Multisender history',
            path: ROUTES.MULTISENDER_HISTORY.PATH,
            url: ROUTES.MULTISENDER_HISTORY.PATH,
            children: [
              {
                component: MultisenderHistory,
                path: ROUTES.MULTISENDER_HISTORY.PATH,
                breadcrumb: 'Multisender history',
                roles: [ROLES.FINANCE],
              },
              {
                component: MultisenderTransactions,
                path: ROUTES.MULTISENDER_TRANSACTIONS.PATH,
                roles: [ROLES.FINANCE],
                isExact: true,
                breadcrumb: (params: IRouteParams): string => {
                  let { id = '' } = params;

                  if (id.length) {
                    id = ellipsisText(id, 5, 5);
                  }

                  return id;
                },
                url: (params: IRouteParams): string => {
                  const { id = '' } = params;

                  return generatePath(ROUTES.MULTISENDER_TRANSACTIONS.PATH, {
                    [ROUTES.MULTISENDER_TRANSACTIONS.PARAMS.id]: id,
                  });
                },
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.SETTINGS.PATH,
        component: Settings,
        breadcrumb: 'System Settings',
        roles: [ROLES.ADMIN],
        isNavigation: true,
        ...NAVBAR_ICONS[NAVBAR_ICONS_NAME.SYSTEM_SETTINGS],
      },
      {
        component: () => <Outlet />,
        path: ROUTES.EXCHANGER_SEED.PATH,
        url: ROUTES.EXCHANGER_SEED.PATH,
        children: [
          {
            path: ROUTES.EXCHANGER_SEED.PATH,
            component: ExchangerSeed,
            breadcrumb: 'Exchanger Seed',
            roles: [ROLES.FINANCE],
          },
        ],
      },
      {
        component: () => <Outlet />,
        breadcrumb: 'Exchanger',
        path: ROUTES.EXCHANGER.PATH,
        url: ROUTES.EXCHANGER.PATH,
        children: [
          {
            path: ROUTES.EXCHANGER.PATH,
            component: Exchanger,
            breadcrumb: 'Exchanger',
            roles: [ROLES.FINANCE],
            isNavigation: false,
            ...NAVBAR_ICONS[NAVBAR_ICONS_NAME.MULTISENDER],
          },
          {
            component: ExchangerSettings,
            path: ROUTES.EXCHANGER_SETTINGS.PATH,
            url: ROUTES.EXCHANGER_SETTINGS.PATH,
            breadcrumb: 'Settings',
            roles: [ROLES.FINANCE],
          },
          {
            component: () => <Outlet />,
            breadcrumb: 'History',
            path: ROUTES.EXCHANGER_HISTORY.PATH,
            url: ROUTES.EXCHANGER_HISTORY.PATH,
            children: [
              {
                component: ExchangerHistory,
                path: ROUTES.EXCHANGER_HISTORY.PATH,
                breadcrumb: 'History',
                roles: [ROLES.FINANCE],
              },
              {
                component: ExchangerTransactions,
                path: ROUTES.EXCHANGER_TRANSACTIONS.PATH,
                roles: [ROLES.FINANCE],
                isExact: true,
                breadcrumb: (params: IRouteParams): string => {
                  let { id = '' } = params;

                  if (id.length) {
                    id = ellipsisText(id, 5, 5);
                  }

                  return id;
                },
                url: (params: IRouteParams): string => {
                  const { id = '' } = params;

                  return generatePath(ROUTES.EXCHANGER_TRANSACTIONS.PATH, {
                    [ROUTES.EXCHANGER_TRANSACTIONS.PARAMS.id]: id,
                  });
                },
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.BRANDS.PATH,
        component: Brands,
        breadcrumb: 'Brands',
        roles: [ROLES.ADMIN],
        isNavigation: true,
        ...NAVBAR_ICONS[NAVBAR_ICONS_NAME.BRANDS],
      },
      {
        component: () => <Outlet />,
        breadcrumb: 'System wallets',
        path: ROUTES.SYSTEM_WALLETS.PATH,
        url: ROUTES.SYSTEM_WALLETS.PATH,
        children: [
          {
            component: SystemWallets,
            path: ROUTES.SYSTEM_WALLETS.PATH,
            breadcrumb: 'System wallets',
            roles: [ROLES.FINANCE],
            withBalanceWarning: true,
            isNavigation: true,
            ...NAVBAR_ICONS[NAVBAR_ICONS_NAME.SYSTEM_WALLETS],
          },
          {
            component: () => <Outlet />,
            breadcrumb: 'History of replacements',
            path: ROUTES.SYSTEM_WALLET_HISTORY.PATH,
            url: ROUTES.SYSTEM_WALLET_HISTORY.PATH,
            children: [
              {
                component: SystemWalletHistory,
                path: ROUTES.SYSTEM_WALLET_HISTORY.PATH,
                breadcrumb: 'History of replacements',
                roles: [ROLES.FINANCE],
              },
            ],
          },
        ],
      },
      {
        isAccordion: true,
        accordionTitle: 'Tools',
        accordionStartIcon: NAVBAR_ICONS.TOOLS.navBarIcon,
        component: <Outlet />,
        path: '',
        children: [
          {
            path: ROUTES.WALLETS_MANAGEMENT.PATH,
            component: WalletsManagement,
            breadcrumb: 'Wallets Management',
            roles: [ROLES.FINANCE],
            isNavigation: true,
            ...NAVBAR_ICONS[NAVBAR_ICONS_NAME.SYSTEM_SETTINGS],
          },
        ],
      },
      {
        component: <Navigate to={ROUTES.HEADQUARTERS.PATH} replace />,
        path: '*',
        roles: [ROLES.FINANCE, ROLES.ADMIN],
      },
    ],
  },
] as IRoutesConfigItem[];
