import { NotificationService } from '@private/notifications';

import { Notification } from 'components';

const notificationService = new NotificationService({
  component: Notification,
  autoCloseTime: 10000,
});

export { notificationService };

export { NOTIF_TYPES } from '@private/notifications';
