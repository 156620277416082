import styled, { css, keyframes } from 'styled-components';

import { ICONS_MAP } from 'constants/icons';
import { COLORS } from 'constants/styles';

export const StyledButton = styled.button<{
  isError?: boolean;
  color?: string;
  size?: number;
}>`
  ${({ isError, color, size }) => css`
    margin-left: 7px;
    width: ${size || 24}px;
    height: ${size || 24}px;
    border-radius: 4px;
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent;
    color: ${isError ? COLORS.RED_600 : color || COLORS.WHITE};

    cursor: pointer;

    &:hover {
      background: ${COLORS.GRAY_LIGHT_300};
    }

    &:active {
      background: ${COLORS.GRAY_LIGHT_400};
    }

    &:disabled {
      color: ${COLORS.WHITE};
      opacity: 0.5;
    }
  `}
`;

const rotationAnimation = keyframes`
  100% {
    transform: scale(2) rotate(360deg);
  }
`;

export const RefreshIcon = styled(ICONS_MAP.Refresh)<{
  $isUpdating: boolean;
  $fullAnimationTimeMs: number;
}>`
  ${({ $fullAnimationTimeMs, $isUpdating }) => css`
    transform: scale(2);
    ${!!$isUpdating &&
    css`
      animation: ${rotationAnimation} ${$fullAnimationTimeMs / 1000}s 0s linear infinite;
    `}
  `}
`;
