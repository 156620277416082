import React from 'react';

import { appReactMemo } from 'hocs';

import { StyledChip, StyledClose } from './styled';

interface IChipProps {
  label: string;
}

const Chip = ({ label, ...props }: IChipProps): React.ReactElement => (
  <StyledChip deleteIcon={<StyledClose />} label={label} {...props} />
);

export default appReactMemo(Chip);
