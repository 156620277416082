import React from 'react';

import { ISelectOption, TSelectValues } from '@private/components';

import { CURRENCIES } from 'api/wallets/constants';
import { ICurrency } from 'api/wallets/types';
import { CURRENCIES_ICONS } from 'constants/icons';

import { useAppSelector } from '../useStore';

import { Container } from './styled';

interface IUseCurrenciesSelectResult {
  currencies: Pick<ICurrency, 'currency'>[];
  currenciesOptions: ISelectOption[];
  selectCurrency: (currency: TSelectValues) => void;
  selectedCurrency: TSelectValues;
}

interface IUseCurrenciesSelectProps {
  onlySystemCurrencies?: boolean;
  onlyMultisenderCurrencies?: boolean;
  onlyExchangerCurrencies?: boolean;
  currenciesList?: ICurrency[];
  multisenderCurrenciesList?: ICurrency[];
  exchangerCurrenciesList?: ICurrency[];
}

export default function useCurrenciesSelect({
  onlySystemCurrencies,
  onlyMultisenderCurrencies,
  onlyExchangerCurrencies,
  currenciesList,
  multisenderCurrenciesList,
  exchangerCurrenciesList,
}: IUseCurrenciesSelectProps = {}): IUseCurrenciesSelectResult {
  const {
    selectedCurrency: defaultCurrency,
    selectedSystemCurrency,
    selectedMultisenderCurrency,
    selectedExchangerCurrency,
  } = useAppSelector((state) => state.userSettings);
  const defaultCurrencyObj = {
    value: defaultCurrency || CURRENCIES.BTCTEST,
    label: defaultCurrency || CURRENCIES.BTCTEST,
  };

  const setDefaultObjValue = (value) => {
    defaultCurrencyObj.value = value;
    defaultCurrencyObj.label = value;
  };

  // For System Wallet page by default currency we are using USDT
  if (onlySystemCurrencies) {
    setDefaultObjValue(selectedSystemCurrency);
  }

  if (onlyMultisenderCurrencies) {
    setDefaultObjValue(selectedMultisenderCurrency);
  }

  if (onlyExchangerCurrencies) {
    setDefaultObjValue(selectedExchangerCurrency);
  }

  const selectCurrency = React.useCallback((selectedCurrencyValue) => {
    setDefaultObjValue(selectedCurrencyValue);
  }, []);

  const currenciesWithDefault = React.useMemo(() => {
    // For System Wallet page need currencies with systemSymbol
    if (currenciesList && onlySystemCurrencies) {
      return currenciesList.filter(({ systemSymbol }) => systemSymbol);
    }

    // For Multisender Wallet page
    if (currenciesList && onlyMultisenderCurrencies) {
      return multisenderCurrenciesList || [];
    }

    // For Exchanger page
    if (currenciesList && onlyExchangerCurrencies) {
      return exchangerCurrenciesList || [];
    }

    return currenciesList || [{ currency: defaultCurrencyObj.value }];
  }, [
    currenciesList,
    multisenderCurrenciesList,
    exchangerCurrenciesList,
    onlySystemCurrencies,
    onlyMultisenderCurrencies,
    onlyExchangerCurrencies,
  ]);

  const currencies = React.useMemo(() => {
    return currenciesWithDefault.map((item) => {
      return {
        value: item.currency,
        component: (
          <Container>
            {CURRENCIES_ICONS[item.currency]}
            {item.currency}
          </Container>
        ),
      };
    });
  }, [currenciesWithDefault]);

  return {
    currencies: currenciesWithDefault,
    currenciesOptions: currencies,
    selectedCurrency: defaultCurrencyObj.value,
    selectCurrency,
  };
}
