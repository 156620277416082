import styled from 'styled-components';

import { Button, NumericInput } from 'components';

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const Styled2FAInput = styled(NumericInput)`
  margin-top: 30px;
`;

export const NavButton = styled(Button)`
  width: 180px;
  &:nth-of-type(1) {
    padding-right: 30px;
  }
`;
