/* eslint-disable no-bitwise */
import JsSHA from 'jssha';

// eslint-disable-next-line
// @ts-ignore
import {
  IPreparedMultisenderTransaction,
  ISendMultisenderTransactions,
  ISendWithdrawalToWalletTransactions,
} from 'api/multisender/types';
import { IPreparedTrxDescriptionNONCE, ISendTransactions } from 'api/wallets/types';

import { getLeafNode } from './leafNode';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const EC = require('elliptic').ec;

function convertStringToHash(txHex: string): string {
  const shaObj = new JsSHA('SHA-256', 'HEX');
  shaObj.update(txHex);
  return shaObj.getHash('HEX');
}

function convertByte2Hex(byte: number): string {
  const hexByteMap = '0123456789ABCDEF';
  let str = '';
  str += hexByteMap.charAt(byte >> 4);
  str += hexByteMap.charAt(byte & 0x0f);
  return str;
}

function getSignedHex(leafNode, trxHex) {
  const ec = new EC('secp256k1');
  const hashBytes = convertStringToHash(trxHex);
  const key = ec.keyFromPrivate(leafNode.privateKey, 'bytes');
  const { r, s, recoveryParam: id } = key.sign(hashBytes);

  let rHex = r.toString('hex');
  while (rHex.length < 64) {
    rHex = `0${rHex}`;
  }

  let sHex = s.toString('hex');
  while (sHex.length < 64) {
    sHex = `0${sHex}`;
  }

  const idHex = convertByte2Hex(id);
  return rHex + sHex + idHex;
}

interface ISignUSDTTrxProps {
  seed: string;
  transactions: IPreparedTrxDescriptionNONCE[];
  currencyIndex?: number;
}

export function signUSDTTransaction({
  seed,
  transactions,
  currencyIndex,
}: ISignUSDTTrxProps): ISendTransactions[] {
  if (!currencyIndex) {
    return [];
  }

  const hashesArray = [] as ISendTransactions[];

  transactions.forEach((trx) => {
    const leafNode = getLeafNode({
      firstIndexToDerive: trx.firstIndexToDerive,
      secondIndexToDerive: trx.secondIndexToDerive,
      currencyIndex,
      seed,
    });

    if (leafNode.privateKey) {
      const signedHex = getSignedHex(leafNode, trx.hex);

      hashesArray.push({
        signature: signedHex,
        rawTransaction: trx.hex,
        fee: trx.fee,
      });
    }
  });
  return hashesArray;
}

interface ISignUSDTMultisenderTrxProps {
  seed: string;
  transactions: IPreparedTrxDescriptionNONCE[] | IPreparedMultisenderTransaction[];
  currencyIndex?: number;
  firstIndexToDerive: number;
  secondIndexToDerive: number;
}

export function signUSDTMultisenderTransaction({
  seed,
  transactions,
  firstIndexToDerive,
  secondIndexToDerive,
  currencyIndex,
}: ISignUSDTMultisenderTrxProps): ISendMultisenderTransactions[] {
  if (!currencyIndex) {
    return [];
  }

  const hashesArray = [] as ISendMultisenderTransactions[];

  const leafNode = getLeafNode({
    firstIndexToDerive,
    secondIndexToDerive,
    currencyIndex,
    seed,
  });

  transactions.forEach((trx) => {
    if (leafNode.privateKey) {
      const signedHex = getSignedHex(leafNode, trx.hex);

      hashesArray.push({
        signature: signedHex,
        rawTransaction: trx.hex,
        fee: trx.fee,
        ...(trx.title && {
          title: trx.title,
        }),
        ...(trx.parentId && {
          parentId: trx.parentId,
        }),
        ...(trx.value && {
          value: trx.value,
        }),
        ...(trx.addressFrom &&
          trx.addressTo && {
            addressFrom: trx.addressFrom,
            addressTo: trx.addressTo,
          }),
      });
    }
  });

  return hashesArray;
}

interface ISignUSDTWithdrawalToWalletTrxProps {
  seed: string;
  transactions: string[];
  currencyIndex?: number;
  firstIndexToDerive: number;
  secondIndexToDerive: number;
}

export function signUSDTWithdrawalToWalletTransaction({
  seed,
  transactions,
  secondIndexToDerive,
  firstIndexToDerive,
  currencyIndex,
}: ISignUSDTWithdrawalToWalletTrxProps): ISendWithdrawalToWalletTransactions[] {
  if (!currencyIndex) {
    return [];
  }

  const hashesArray = [] as ISendWithdrawalToWalletTransactions[];

  const leafNode = getLeafNode({
    firstIndexToDerive,
    secondIndexToDerive,
    currencyIndex,
    seed,
  });

  transactions.forEach((trxRaw) => {
    if (leafNode.privateKey) {
      const signedHex = getSignedHex(leafNode, trxRaw);

      hashesArray.push({
        signature: signedHex,
        rawTransaction: trxRaw,
      });
    }
  });

  return hashesArray;
}
