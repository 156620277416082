import React, { PropsWithChildren } from 'react';

import { IColumn } from '@private/data-grid';

import { Container, HeaderCellBody } from './styled';

interface IHeaderCellData<T> {
  columns: IColumn<T>[];
  index: number;
}

type THeaderCellProps<T> = PropsWithChildren<IHeaderCellData<T>>;

const HeaderCell = <T,>({ columns, index }: THeaderCellProps<T>): React.ReactElement => {
  const cellData = columns?.[index];
  const cellTitle = cellData?.title || '';

  return (
    <Container data-head-cell-key={cellData.dataKey}>
      <HeaderCellBody>{cellTitle}</HeaderCellBody>
    </Container>
  );
};

export default HeaderCell;
