import { Form } from 'formik';
import styled from 'styled-components';

import { Button } from 'components';
import { COLORS } from 'constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 610px;
  padding: 120px 120px 0 120px;
`;

export const Title = styled.h1`
  font-family: PT Sans;
  font-size: 32px;
  color: ${COLORS.GRAY_700};
  margin-bottom: 55px;
`;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  width: 100%;
`;

export const Hint = styled.p`
  font-family: PT Sans;
  font-size: 14px;
  color: ${COLORS.GRAY_700};
  margin-top: 28px;
  & a {
    margin-left: 5px;
    color: ${COLORS.GRAY_700};
  }
`;

export const ButtonStyled = styled(Button)`
  margin-top: 48px;
`;

export const StyledPasswordHint = styled.div`
  padding: 20px 20px 8px 20px;
  background: ${COLORS.GRAY_LIGHT_150};
  border-radius: 5px;
  font-size: 12px;
  color: ${COLORS.GRAY_700};

  margin-top: 24px;
`;

export const StyledTitleHint = styled.div`
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
`;

export const StyledMessageHint = styled.div`
  white-space: pre-wrap;
  margin-left: 4px;
  line-height: 12px;
`;
