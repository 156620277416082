import { Tooltip, tooltipClasses } from '@private/components';
import styled from 'styled-components';

import { COLORS, SHADOWS } from 'constants/styles';

export default styled(Tooltip).attrs(() => ({
  showOnClick: true,
}))`
  padding: 12px;

  background: ${COLORS.WHITE};
  border-radius: 14px;
  box-shadow: ${SHADOWS.QUATERNARY};

  ${tooltipClasses.message} {
    padding: 0;
    background: ${COLORS.WHITE};
  }

  button {
    justify-content: flex-start;
    padding: 12px 22px;

    width: 100%;

    background: ${COLORS.WHITE};
    border-radius: 6px;

    transition: background 0.4s ease;

    &:hover {
      color: ${COLORS.GRAY_500};
      background: ${COLORS.GRAY_LIGHT_400};
    }
  }
`;
