import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useModal } from '@private/modals';

import { CURRENCIES, CURRENCIES_TYPE } from 'api/wallets/constants';
import { IPreparedTransactionNONCE, TPreparedTransaction } from 'api/wallets/types';
import { IModalContainerProps } from 'components/ModalContainer';
import { ERROR_CODES } from 'constants/errors';
import { ROUTES } from 'constants/routes';
import { usePrepareTransaction } from 'queries/transactions';

import { ModalError, ModalInfo, ModalLoading } from '../WithdrawalTemplates';

export interface IWithdrawalModalConfig {
  onConfirm: (preparedTrx: TPreparedTransaction) => void;
  groupID: string;
  currency: CURRENCIES;
  currencyType: CURRENCIES_TYPE;
}

export interface IWithdrawalModalProps extends IWithdrawalModalConfig {
  onClose: () => void;
}

const WithdrawalModal = ({
  onClose,
  onConfirm,
  groupID,
  currency,
  currencyType,
}: IWithdrawalModalProps): React.ReactElement => {
  const {
    mutateAsync: prepareTransaction,
    isLoading,
    isError,
    data,
    error,
  } = usePrepareTransaction();
  const navigate = useNavigate();
  const { inputsLimit, totalWithdrawalInfo } = data || {};

  const ethCryptoFee = (data as IPreparedTransactionNONCE)?.hdWallets[0]?.cryptoFee;
  const ethUSDfee = (data as IPreparedTransactionNONCE)?.hdWallets[0]?.feeUSD;

  const getPreparedTrx = React.useCallback(() => {
    prepareTransaction({ currencyType, groupID, currencySymbol: currency });
  }, [currencyType, groupID, currency]);

  React.useEffect(() => {
    getPreparedTrx();
  }, []);

  const onConfirmClick = React.useCallback(() => {
    if (data) {
      onConfirm(data);
    }
  }, [data, onConfirm]);

  const onRetry = React.useCallback(() => {
    getPreparedTrx();
  }, [getPreparedTrx]);

  if (isLoading) {
    return (
      <ModalLoading title='Withdrawal funds' isLoading={isLoading} onClose={onClose} />
    );
  }

  const errorsConfig = {
    [ERROR_CODES.INSUFFICIENT_SYSTEM_BALANCE]: {
      rightBtnVariant: 'primary',
      rightBtnText: 'Go to System Wallets',
      onRightAction: () => {
        onClose();
        navigate(ROUTES.SYSTEM_WALLETS.PATH);
      },
    },
  } as Record<ERROR_CODES, Partial<IModalContainerProps>>;

  if (isError) {
    const errorCode = error?.response?.data.code;
    const currentError = errorCode ? errorsConfig[errorCode] : null;

    return (
      <ModalError
        isLoading={isLoading}
        onClose={onClose}
        error={error}
        onRetry={onRetry}
        currentError={currentError}
        errorCode={errorCode}
        currency={currency}
        title='Withdrawal funds'
      />
    );
  }

  return (
    <ModalInfo
      onClose={onClose}
      onConfirmClick={onConfirmClick}
      title='Withdrawal funds'
      cryptoAmountToWithdraw={totalWithdrawalInfo?.cryptoAmountToWithdraw}
      currency={currency}
      ethCryptoFee={ethCryptoFee}
      inputsCount={totalWithdrawalInfo?.inputsCount}
      feeCurrencySymbol={totalWithdrawalInfo?.feeCurrencySymbol}
      ethUSDfee={ethUSDfee}
      cryptoTransactionFee={totalWithdrawalInfo?.cryptoTransactionFee}
      transactionFeeUSD={totalWithdrawalInfo?.transactionFeeUSD}
      inputsLimit={inputsLimit}
    />
  );
};

type TUseWithdrawalModalResult = [(config: IWithdrawalModalConfig) => void, () => void];

export const useWithdrawalModal = (): TUseWithdrawalModalResult => {
  const { closeModal, showModal } = useModal();

  const showWithdrawalModal = React.useCallback((config: IWithdrawalModalConfig) => {
    showModal(<WithdrawalModal {...config} onClose={closeModal} />);
  }, []);

  return [showWithdrawalModal, closeModal];
};

export default WithdrawalModal;
