import React from 'react';

import { IAllPageFilters } from 'utils/types';

export const FiltersContext = React.createContext<{
  pageFilters: IAllPageFilters;
  disabled: boolean;
}>({
  disabled: true,
  pageFilters: {
    orderDirection: 'asc',
    numRecordsPerPage: 10,
    currentPage: 1,
  },
});
