import {
  axiosExchangerInstance,
  axiosInstance,
  axiosMultisenderInstance,
} from 'api/base';
import { IGetBackupSeedResult } from 'api/types';

import {
  normalize2FA,
  normalize2FASecret,
  normalizeBackupSeedInfo,
  normalizeSeed,
  normalizeSignIn,
  normalizeSignUp,
} from './normalizers';
import type {
  IAuthState,
  IBackupPayload,
  IGet2FASecretResult,
  IGetSeedPayload,
  IGetSeedResult,
  ISignInPayload,
  ISignUpPayload,
  ISignUpResult,
  IValidate2FAResult,
} from './types';

const authAPI = {
  async signUp(payload: ISignUpPayload): Promise<ISignUpResult> {
    const result = await axiosInstance.post('/signup', payload);
    return normalizeSignUp(result.data);
  },

  async signIn(payload: ISignInPayload): Promise<IAuthState> {
    const result = await axiosInstance.put('/signin', payload);
    return normalizeSignIn(result.data);
  },

  async validate2FA(otp: string): Promise<IValidate2FAResult> {
    const result = await axiosInstance.put('/two-factor', { otp });
    return normalize2FA(result.data);
  },

  async get2FASecret(): Promise<IGet2FASecretResult> {
    const result = await axiosInstance.get('/two-factor');
    return normalize2FASecret(result.data);
  },

  async setBackup(data: IBackupPayload): Promise<IAuthState> {
    const result = await axiosInstance.post('/backup', data);
    return normalizeSignIn(result.data);
  },

  async getSeed(data: IGetSeedPayload): Promise<IGetSeedResult> {
    const result = await axiosInstance.post('/seed', data);
    return normalizeSeed(result.data);
  },

  async getMultisenderSeed(data: IGetSeedPayload): Promise<IGetSeedResult> {
    const result = await axiosMultisenderInstance.post('wallets/seed', data);
    return normalizeSeed(result.data);
  },

  async setMultisenderBackup(data: IBackupPayload): Promise<IAuthState> {
    const result = await axiosMultisenderInstance.post('auth/backup', data);
    return normalizeSignIn(result.data);
  },

  async getMultisenderBackupSeed(): Promise<IGetBackupSeedResult> {
    const result = await axiosMultisenderInstance.get('auth/seed');
    return normalizeBackupSeedInfo(result.data);
  },

  async setExchangerBackup(data: IBackupPayload): Promise<IAuthState> {
    const result = await axiosExchangerInstance.post('auth/backup', data);
    return normalizeSignIn(result.data);
  },

  async getExchangerBackupSeed(): Promise<IGetBackupSeedResult> {
    const result = await axiosExchangerInstance.get('auth/seed');
    return normalizeBackupSeedInfo(result.data);
  },

  async getExchangerSeed(data: IGetSeedPayload): Promise<IGetSeedResult> {
    const result = await axiosExchangerInstance.post('wallets/seed', data);
    return normalizeSeed(result.data);
  },
};

export default authAPI;
