import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const StyledDescription = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${COLORS.GRAY_700};
  text-align: center;
  margin: 34px 0;
`;
