import { COLORS } from 'constants/styles';
import { appReactMemo } from 'hocs';

import FileActionBtn, { IFileActionBtnProps } from './FileActionBtn';
import {
  ErrorContainer,
  ErrorMessage,
  StyledFileIcon,
  UploadFileContainer,
  UploadFileName,
} from './styled';

interface IFileZoneProps extends IFileActionBtnProps {
  errorMessage?: string;
  fileName: string;
}

const FileZone = ({
  isSuccess,
  isFailure,
  errorMessage,
  isLoading,
  onDelete,
  fileName,
}: IFileZoneProps) => (
  <>
    <UploadFileContainer
      isSuccess={isSuccess}
      isLoading={isLoading}
      isFailure={isFailure || !!errorMessage}
    >
      <StyledFileIcon color={COLORS.GREEN_400} />
      <UploadFileName>{fileName}</UploadFileName>
      <FileActionBtn
        isLoading={isLoading}
        isSuccess={isSuccess}
        isFailure={isFailure || !!errorMessage}
        onDelete={onDelete}
      />
    </UploadFileContainer>
    {!isLoading && errorMessage && (
      <ErrorContainer>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ErrorContainer>
    )}
  </>
);

export default appReactMemo(FileZone);
