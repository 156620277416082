import { IAppliedFilter } from '@private/components';

export type TSortOrder = 'asc' | 'desc';

export interface IAllPageFilters {
  numRecordsPerPage?: number;
  currentPage?: number;
  orderDirection?: TSortOrder;
  columns?: string[];
  paginatorType?: 'page' | 'infinity';
  searchColumns?: string[];
  hideZeroBalances?: boolean;
  isHiddenExchangeTab?: boolean;
  hiddenColumns?: string[];
  activeTab?: string;
  searchQuery?: {
    filters?: IAppliedFilter[];
    globalQueryTerm?: string;
  } & Record<string, unknown>;
}

export enum StatusTypes {
  pending = 'Pending',
  success = 'Success',
  failed = 'Failed',
  completed = 'Completed',
  created = 'Created',
  canceled = 'Canceled',
}
