import { Checkbox, checkboxClasses } from '@private/components';
import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export default styled(Checkbox)`
  color: ${COLORS.GRAY_700};

  &[data-ischecked='false']:not([data-isdisabled='true']),
  &[data-ischecked='true']:not([data-isdisabled='true']) {
    &:hover {
      ${checkboxClasses.checkIcon} {
        background: ${COLORS.BLUE_50};
        border: 1px solid ${COLORS.BLUE_500};
      }
    }

    &:active {
      ${checkboxClasses.checkIcon} {
        background: ${COLORS.WHITE};
        border: 1px solid ${COLORS.BLUE_300};
        box-shadow: 0px 0px 0px 4px ${COLORS.BLUE_100};
      }
    }
  }

  &[data-isdisabled='true'] {
    color: ${COLORS.GRAY_300};

    ${checkboxClasses.checkIcon} {
      background: ${COLORS.GRAY_50};
      border: 1px solid ${COLORS.GRAY_150};

      &[data-isindeterminate='true']:after {
        background: ${COLORS.GRAY_150};
      }

      &[data-isindeterminate='false']:after {
        border-color: ${COLORS.GRAY_150};
      }
    }
  }

  ${checkboxClasses.checkIcon} {
    background: ${COLORS.WHITE};
    border: 1px solid ${COLORS.GRAY_300};

    &[data-ischecked='true'] {
      background: ${COLORS.WHITE};
      border: 1px solid ${COLORS.BLUE_300};

      &[data-isindeterminate='true']:after {
        background: ${COLORS.BLUE_300};
      }

      &[data-isindeterminate='false']:after {
        border-color: ${COLORS.BLUE_300};
      }
    }
  }
`;
