import React from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import { NOTIF_TYPES } from '@private/notifications';
import { StringParam, useQueryParams } from 'use-query-params';

import { ROUTES_CONFIG } from 'routes/config';
import { notificationService } from 'utils/notifications';

import { setUserState } from '../reducers/userSettings';

import { useAppDispatch, useAppSelector } from './useStore';

interface IUseSaveInURL<T> {
  savedData?: T;
  onSave: (data?: T) => void;
}

const useSaveInURL = <T>(paramName: string): IUseSaveInURL<T> => {
  const [isError, setIsError] = React.useState(false);
  const [query, setQuery] = useQueryParams({ [paramName]: StringParam });
  const { userState } = useAppSelector((state) => state.userSettings);

  const dispatch = useAppDispatch();
  const location = useLocation();

  const onSave = React.useCallback(
    (data?: T) => {
      const stringFilter = data ? JSON.stringify(data) : null;
      setQuery({ [paramName]: stringFilter });
    },
    [setQuery]
  );

  const currentPath = React.useMemo(() => {
    const urls = matchRoutes(ROUTES_CONFIG, location.pathname);

    if (urls) {
      return urls[urls.length - 1].route.path;
    }

    return '';
  }, [location.pathname]);

  const savedData = React.useMemo(() => {
    try {
      const stringFilter = query?.[paramName];

      if (stringFilter) {
        return JSON.parse(stringFilter);
      }

      if (userState && userState[currentPath]) {
        return userState[currentPath];
      }
    } catch (e) {
      setIsError(true);
    }
  }, [query]);

  React.useEffect(() => {
    if (currentPath) {
      const stringFilter = query?.[paramName];
      const oldState = userState || {};

      if (stringFilter) {
        const parsedFilterData = JSON.parse(stringFilter);
        const newUserStateDate = {
          ...oldState,
          [currentPath]: {
            ...parsedFilterData,
            // ...(parsedFilterData.hiddenColumns &&
            //   parsedFilterData.hiddenColumns.length && {
            //     hiddenColumns: parsedFilterData.hiddenColumns,
            //   }),
            // ...(parsedFilterData.hideZeroBalances && {
            //   hideZeroBalances: parsedFilterData.hideZeroBalances,
            // }),
            // ...(parsedFilterData.currentPage && {
            //   currentPage: parsedFilterData.currentPage,
            // }),
            // ...(parsedFilterData.numRecordsPerPage && {
            //   numRecordsPerPage: parsedFilterData.numRecordsPerPage,
            // }),
            // ...(parsedFilterData.activeTab && {
            //   activeTab: parsedFilterData.activeTab,
            // }),
            // ...(parsedFilterData.isHiddenExchangeTab && {
            //   isHiddenExchangeTab: parsedFilterData.isHiddenExchangeTab,
            // }),
          },
        };

        dispatch(setUserState(newUserStateDate));
      }
    }
  }, [query, currentPath]);

  // useEffect fixes the stack trace
  React.useEffect(() => {
    if (isError) {
      setQuery({ [paramName]: null });
      setIsError(false);

      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message: `Incorrect URL`,
      });
    }
  }, [isError]);

  return {
    savedData,
    onSave,
  };
};

export default useSaveInURL;
