import React from 'react';

import { IAppliedFilter } from '@private/components';
import { IGridProps } from '@private/data-grid';

import Loader from 'components/Loader';
import { IAllPageFilters } from 'utils/types';

import { FiltersContext } from './FiltersContext';
import { StyledDataGrid } from './styled';
import TopToolbar from './TopToolbar';

interface IDataGridLocalProps<T> extends IGridProps<T> {
  searchPlaceholder?: string;
  topToolbarChildren?: React.ReactNode;
  hidePagination?: boolean;
  isWithBalance?: boolean;
  onBalanceFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  pageFilters?: IAllPageFilters;
  onCustomFiltersChange?: (appliedFilters: IAppliedFilter[]) => void;
  onPerPageChange?: (count: number) => void;
  onPageChange?: (page: number) => void;
  onChangeSearchTerm?: (value: string) => void;
  globalSearchTerm?: string;
  balanceFilterLabelText?: string;
}

const DataGrid = <T extends { balance?: number } | unknown>({
  isLoading,
  isFetching,
  data,
  columns,
  rowHeight = () => 72,
  defaultMinWidthColumn = 150,
  isTopToolbar = true,
  isBottomToolbar = true,
  searchPlaceholder = '',
  topToolbarChildren,
  hidePagination,
  isWithBalance,
  onBalanceFilterChange,
  pageFilters,
  globalSearchTerm,
  onChangeSearchTerm,
  onPerPageChange,
  onPageChange,
  pagination,
  onCustomFiltersChange,
  balanceFilterLabelText,
  ...rest
}: IDataGridLocalProps<T>): React.ReactElement => {
  const currentContext = React.useMemo(
    () => ({
      pageFilters: pageFilters || ({} as IAllPageFilters),
      disabled: !data.length || !!isFetching,
    }),
    [pageFilters, data, isFetching]
  );

  const onPaginationPageChange = React.useCallback(
    (page: number) => {
      pagination?.onPageChange?.(page);
      onPageChange?.(page);
    },
    [onPageChange, pagination]
  );

  const onPaginationPerPageChange = React.useCallback(
    (perPage: number) => {
      pagination?.onPerPageChange?.(perPage);
      onPerPageChange?.(perPage);
    },
    [onPerPageChange, pagination]
  );

  return (
    <FiltersContext.Provider value={currentContext}>
      <StyledDataGrid
        searchKeys={['title']}
        {...rest}
        isLoading={isLoading}
        isFetching={isFetching}
        data={data}
        headerHeight={45}
        rowHeight={rowHeight}
        columns={columns}
        defaultMinWidthColumn={defaultMinWidthColumn}
        isTopToolbar={isTopToolbar}
        isBottomToolbar={!hidePagination && isBottomToolbar}
        {...(pageFilters && {
          pagination: {
            ...pagination,
            currentPage: pageFilters?.currentPage,
            rowsPerPage: pageFilters?.numRecordsPerPage,
            onPageChange: onPaginationPageChange,
            onPerPageChange: onPaginationPerPageChange,
            ...(!globalSearchTerm && {
              total: data.length,
            }),
          },
        })}
        components={{
          TopToolbar: (props) => (
            <TopToolbar
              {...props}
              data={data}
              topToolbarChildren={topToolbarChildren}
              hidePagination={hidePagination}
              searchPlaceholder={searchPlaceholder}
              isWithBalance={isWithBalance}
              onBalanceFilterChange={onBalanceFilterChange}
              globalSearchTerm={globalSearchTerm}
              onChangeSearchTerm={onChangeSearchTerm}
              initialFilters={pageFilters?.searchQuery?.filters}
              onCustomFiltersChange={onCustomFiltersChange}
              balanceFilterLabelText={balanceFilterLabelText}
            />
          ),
          Loader: () => <Loader size={70} />,
        }}
      />
    </FiltersContext.Provider>
  );
};

export default DataGrid;
