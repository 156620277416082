import { axiosInstance } from '../base';

import { normalizeBrands } from './normalizers';
import { IBrand, IGetBrandsPayload } from './types';

const brandsAPI = {
  async getBrands({ currency, headquarterId }: IGetBrandsPayload): Promise<IBrand[]> {
    const result = await axiosInstance.get('/brands', {
      params: {
        ...(!!currency && { currencySymbol: currency }),
        ...(!!headquarterId && { headquarterId }),
      },
    });
    return normalizeBrands(result.data);
  },
};

export default brandsAPI;
