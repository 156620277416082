import React from 'react';

import { ROLES } from 'api/auth/constants';

import { ConfirmSeed, Seed } from './MnemonicSteps';
import { AssignApp, Confirm, Install } from './SecondFactorSteps';

export enum SECURITY_STEP_TYPES {
  SECOND_FACTOR = '2FA',
  MNEMONIC = 'mnemonic',
}

interface ISecurityStep {
  type: SECURITY_STEP_TYPES;
  title: string;
  subtitle?: string;
  Component?: React.ReactElement;
  roles: ROLES[];
}

export const SECURITY_STEPS: ISecurityStep[] = [
  {
    type: SECURITY_STEP_TYPES.SECOND_FACTOR,
    title: 'Two-Factor Authentication',
    subtitle: 'Google Authentication',
    Component: <Install />,
    roles: [ROLES.ADMIN, ROLES.FINANCE],
  },
  {
    type: SECURITY_STEP_TYPES.SECOND_FACTOR,
    title: 'Two-Factor Authentication',
    subtitle: 'Google Authentication',
    Component: <AssignApp />,
    roles: [ROLES.ADMIN, ROLES.FINANCE],
  },
  {
    type: SECURITY_STEP_TYPES.SECOND_FACTOR,
    title: 'Two-Factor Authentication',
    subtitle: 'Google Authentication',
    Component: <Confirm />,
    roles: [ROLES.ADMIN, ROLES.FINANCE],
  },
  {
    type: SECURITY_STEP_TYPES.MNEMONIC,
    title: 'Generate Seed',
    subtitle:
      'Write down your recovery phrase on paper and store it somewhere safe. Do not store it on your computer or in network.',
    Component: <Seed />,
    roles: [ROLES.FINANCE],
  },
  {
    type: SECURITY_STEP_TYPES.MNEMONIC,
    title: 'Check your backup',
    Component: <ConfirmSeed />,
    roles: [ROLES.FINANCE],
  },
];
