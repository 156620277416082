import React from 'react';

import { Player } from '@lottiefiles/react-lottie-player';

import animationData from './loader.json';
import { Container } from './styled';

export interface ILoaderProps {
  size?: number;
  className?: string;
}

const Loader = ({ size = 34, className }: ILoaderProps): React.ReactElement => (
  <Container data-testid='loader' className={className} width={size} height={size}>
    <Player
      autoplay
      loop
      src={animationData}
      style={{ height: `${size}px`, width: `${size}px` }}
    />
  </Container>
);

export default Loader;
