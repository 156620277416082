import React from 'react';

import { appReactMemo } from 'hocs';

import { StyledSwitch } from './styled';

export interface ISwitchProps {
  label?: string;
  className?: string;
  testId?: string;
}

const Switch: React.FC<ISwitchProps> = ({ className, label, testId, ...props }) => {
  return (
    <StyledSwitch
      {...props}
      label={label || ''}
      className={className}
      data-testid={`${testId}toggle`}
    />
  );
};

export default appReactMemo(Switch);
