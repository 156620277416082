import React from 'react';
import { matchPath, useLocation, useMatch } from 'react-router-dom';

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import { CURRENCIES } from 'api/wallets/constants';
import { AccordionNavMenu } from 'components';
import Logo from 'components/Logo';
import { ICONS_MAP } from 'constants/icons';
import { ROUTES } from 'constants/routes';
import { useAppDispatch, useAppSelector, useCurrenciesSelect } from 'hooks';
import { useConfirmModal } from 'modals/ConfirmModal';
import { useGetExchangerCurrencies } from 'queries/exchanger';
import { useGetMultisenderCurrencies } from 'queries/multisender';
import { useAuthentication } from 'queries/session';
import { useCurrenciesQuery, useSystemWalletsQuery } from 'queries/wallets';
import {
  selectCurrency as selectCurrencyPersisted,
  selectExchangerCurrency,
  selectMultisenderCurrency,
  selectSystemCurrency,
} from 'reducers/userSettings';
import { IRoutesConfigItem, ROUTES_CONFIG } from 'routes/config';

import { StyledButtonToggle } from '../styled';

import {
  Container,
  ContentContainer,
  LogoContainer,
  StyledLink,
  StyledLogoutBtn,
  StyledLogoutIcon,
  StyledSelect,
} from './styled';

const IGNORED_ROUTES = [ROUTES.TRANSACTION_DETAILS.PATH];

interface IGetMenu {
  routesConfigItem: IRoutesConfigItem;
  showWarning?: boolean;
  showReplaceWalletWarning?: boolean;
  withoutIcon?: boolean;
}

export const getMenu = ({
  routesConfigItem,
  showWarning,
  showReplaceWalletWarning,
  withoutIcon,
}: IGetMenu): React.ReactElement | React.ReactElement[] | undefined => {
  const {
    path,
    navBarIcon,
    navBarActiveIcon,
    breadcrumb,
    children,
    withBalanceWarning,
    isNavigation,
    isAccordion,
    accordionStartIcon,
    accordionTitle,
  } = routesConfigItem;
  const { role } = useAuthentication();
  const firstLevelPath = path.split('/')[1];
  const match = useMatch(`/${firstLevelPath}/*`);

  if (isAccordion && accordionTitle && accordionStartIcon && children) {
    return (
      <AccordionNavMenu
        key={accordionTitle}
        accordionTitle={accordionTitle}
        accordionStartIcon={accordionStartIcon}
        items={children}
      />
    );
  }

  if (children) {
    return children
      .filter((routeConfig) =>
        routeConfig.roles && role ? routeConfig.roles.includes(role) : true
      )
      .map((routesConfigItem) =>
        getMenu({
          routesConfigItem,
          showWarning,
          showReplaceWalletWarning,
        })
      ) as React.ReactElement[];
  }

  const isActive = React.useMemo(() => !!match, [match]);

  const warningIcon = React.useMemo(() => {
    if (showReplaceWalletWarning) {
      return <ICONS_MAP.WarningRed />;
    }

    if (showWarning) {
      return <ICONS_MAP.WarningYellow />;
    }
  }, [showWarning, showReplaceWalletWarning]);

  const icon = React.useMemo(() => {
    if (withoutIcon) {
      return null;
    }

    if (isActive) {
      return navBarActiveIcon;
    }

    return navBarIcon;
  }, [withoutIcon, isActive, navBarActiveIcon, navBarIcon]);

  if (isNavigation) {
    return (
      <StyledLink data-link to={path} key={path} $isActive={isActive}>
        <>
          {icon}
          {breadcrumb}
          {/* Display warning icon when balance enough */}
          {withBalanceWarning && warningIcon}
        </>
      </StyledLink>
    );
  }
};

const NavBar = (): React.ReactElement => {
  const { data: systemWalletsData } = useSystemWalletsQuery();
  const { data: currenciesList = [] } = useCurrenciesQuery();
  const { data: multisenderCurrenciesList = [] } = useGetMultisenderCurrencies();
  const { data: exchangerCurrenciesList = [] } = useGetExchangerCurrencies();
  const { selectedExchangerCurrency } = useAppSelector((state) => state.userSettings);

  const [isOpen, setIsOpen] = React.useState(true);
  const { logout, role } = useAuthentication();
  const [showConfirmModal] = useConfirmModal();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const onlySystemCurrencies = React.useMemo(
    () => location.pathname.includes(ROUTES.SYSTEM_WALLETS.PATH),
    [location.pathname]
  );

  const onlyMultisenderCurrencies = React.useMemo(
    () => location.pathname.includes(ROUTES.MULTISENDER.PATH),
    [location.pathname]
  );

  const onlyExchangerCurrencies = React.useMemo(
    () => location.pathname.includes(ROUTES.EXCHANGER.PATH),
    [location.pathname]
  );

  const { currenciesOptions, selectedCurrency, selectCurrency } = useCurrenciesSelect({
    onlySystemCurrencies,
    onlyMultisenderCurrencies,
    onlyExchangerCurrencies,
    currenciesList,
    multisenderCurrenciesList,
    exchangerCurrenciesList,
  });

  const getSelectAction = React.useMemo(() => {
    if (onlySystemCurrencies) {
      return selectSystemCurrency;
    }

    if (onlyMultisenderCurrencies) {
      return selectMultisenderCurrency;
    }

    if (onlyExchangerCurrencies) {
      return selectExchangerCurrency;
    }

    return selectCurrencyPersisted;
  }, [location.pathname]);

  const selectCurrencyAndPersist = React.useCallback(
    (currency) => {
      dispatch(getSelectAction(currency as CURRENCIES));
      selectCurrency(currency);
    },
    [dispatch, selectCurrency, selectCurrencyPersisted, location.pathname]
  );

  React.useEffect(() => {
    // fix persist for .best and .oi, change BTCTEST default currency to BTC from currencies response
    if (onlyExchangerCurrencies) {
      const currency =
        exchangerCurrenciesList?.[0]?.currency || selectedExchangerCurrency;
      selectCurrencyAndPersist(currency);
    }
  }, [onlyExchangerCurrencies]);

  const toggleOpen = React.useCallback(() => {
    setIsOpen((open) => !open);
  }, []);

  const shouldShowCurrencySwitcher = React.useMemo(
    () => !IGNORED_ROUTES.some((route) => matchPath(route, location.pathname)),
    [location.pathname]
  );

  const onLogout = React.useCallback(() => {
    showConfirmModal({
      title: 'Logout',
      message: 'Do you want to logout',
      confirmText: 'Log out',
      onConfirm: logout,
    });
  }, [logout, showConfirmModal]);

  const showWarning = React.useMemo(() => {
    return systemWalletsData?.some((systemCurrency) => !systemCurrency.isBalanceEnough);
  }, [systemWalletsData]);

  const showReplaceWalletWarning = React.useMemo(() => {
    return systemWalletsData?.some(
      (systemCurrency) => systemCurrency.transactionsCompletedCount > 0
    );
  }, [systemWalletsData]);

  return (
    <Container isOpen={isOpen}>
      <StyledButtonToggle
        onClick={toggleOpen}
        variant='icon'
        startIcon={isOpen ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
      />

      <ContentContainer isOpen={isOpen}>
        <LogoContainer>
          <Logo noText />

          {!!currenciesOptions.length && shouldShowCurrencySwitcher && (
            <StyledSelect
              positionVariant='row'
              options={currenciesOptions}
              value={selectedCurrency}
              onChange={selectCurrencyAndPersist}
              disabled={currenciesOptions.length === 1}
            />
          )}
        </LogoContainer>

        {ROUTES_CONFIG.filter((routeConfig) =>
          routeConfig.roles && role ? routeConfig.roles.includes(role) : true
        ).map((routesConfigItem) =>
          getMenu({
            routesConfigItem,
            showWarning,
            showReplaceWalletWarning,
          })
        )}

        <StyledLogoutBtn variant='transparent' onClick={onLogout}>
          <StyledLogoutIcon />
          Logout
        </StyledLogoutBtn>
      </ContentContainer>
    </Container>
  );
};

export default NavBar;
