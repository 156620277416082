import React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { appReactMemo } from 'hocs';
import { useAuthentication } from 'queries/session';
import { use2FA } from 'queries/session/use2FA';

import {
  ButtonsRow,
  Container,
  ContentContainer,
  DescriptionContainer,
  NavButton,
  Styled2FAInput,
  SubTitle,
  Text,
  Title,
} from './styled';

const SecondFactorPage = () => {
  const { logout } = useAuthentication();
  const { mutate: sendOtp, isError, isLoading } = use2FA();
  const [otp, setOtp] = React.useState('');

  const onLogin = React.useCallback(() => {
    sendOtp(otp);
  }, [otp]);

  const onEnter = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        return onLogin();
      }
    },
    [onLogin]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', onEnter);

    return () => {
      window.removeEventListener('keydown', onEnter);
    };
  }, [onEnter]);

  return (
    <Container>
      <ContentContainer>
        <Title>Two-Factor Authentication</Title>
        <SubTitle>Google Authentication</SubTitle>
        <DescriptionContainer>
          <Text>
            <p>Enter 6-digits code </p>
            <p>from your Google Authenticator application:</p>
          </Text>
        </DescriptionContainer>

        <Styled2FAInput error={isError} onChange={setOtp} />
      </ContentContainer>

      <ButtonsRow>
        <NavButton
          variant='transparent'
          startIcon={<ChevronLeftIcon />}
          iconMargin={50}
          onClick={logout}
        >
          Previous
        </NavButton>
        <NavButton
          variant='primary'
          onClick={onLogin}
          disabled={!otp || otp.length !== 6 || isLoading}
        >
          Login
        </NavButton>
      </ButtonsRow>
    </Container>
  );
};

export default appReactMemo(SecondFactorPage);
