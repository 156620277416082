import { IGroup, IGroupBrand } from './types';

type TServerGroupBrandData = Record<string, string> & {
  id: number;
  title: string;
  groupId: number;
};

export type TServerGroupData = Record<string, string> & {
  id: number;
  totalBalance: number;
  brandsCount: number;
  headquarterId: number;
  headquarterTitle: string;
  brands: TServerGroupBrandData[];
};

export function normalizeGroupBrand(data: TServerGroupBrandData): IGroupBrand {
  return {
    id: data.id || 0,
    label: data.title || '',
    groupId: data.groupId || 0,
  };
}

export function normalizeGroup(data: TServerGroupData): IGroup {
  return {
    id: data.id || 0,
    title: data.title || '',
    brandsCount: data.brandsCount || 0,
    headquarterId: data.headquarterId || 0,
    headquarterTitle: data.headquarterTitle || '',
    brands: data.brands?.map(normalizeGroupBrand) || [],
  };
}

export function normalizeGroups(data: TServerGroupData[]): IGroup[] {
  return data.map((item) => normalizeGroup(item));
}
