import auth from './auth';
import brands from './brands';
import common from './common';
import exchanger from './exchanger';
import groups from './groups';
import headquarters from './headquarters';
import logger from './logger';
import multisender from './multisender';
import wallets from './wallets';

export default {
  auth,
  headquarters,
  wallets,
  brands,
  groups,
  common,
  multisender,
  exchanger,
  logger,
};
