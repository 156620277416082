import React from 'react';
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

import { AxiosError } from 'axios';

import api from 'api';
import {
  IActiveWallet,
  IExchangerSecrets,
  IGetMarketsPricesResult,
  IPrepareDepositTransactionPayload,
  IReplaceWalletPayload,
  ISendDepositTransactionPayload,
} from 'api/exchanger/types';
import { IPlatform } from 'api/exchanger/types';
import { IPlatformBalance } from 'api/exchanger/types';
import { IOrderResponse } from 'api/exchanger/types';
import { IExchangerHistoryResult } from 'api/exchanger/types';
import { IDepositHistory } from 'api/exchanger/types';
import { IExchangerWithdrawalLimits } from 'api/exchanger/types';
import { ISetupAccountPayload } from 'api/exchanger/types';
import { IRemoveAccountPayload } from 'api/exchanger/types';
import { ICreateOrderPayload } from 'api/exchanger/types';
import { IGetMarketPriceResult } from 'api/exchanger/types';
import { IRemoveOrderPayload } from 'api/exchanger/types';
import { IGetBackupSeedResult } from 'api/types';
import { CURRENCIES } from 'api/wallets/constants';
import { ICurrency, IPreparedTransactionBTC } from 'api/wallets/types';
import { IBaseAxiosError } from 'constants/types';
import { useAppSelector } from 'hooks/useStore';
import { useAppQuery } from 'queries/utils';
import { NOTIF_TYPES, notificationService } from 'utils/notifications';

import { IAvailablePlatform } from '../../api/exchanger/types';

import { EXCHANGER_KEYS } from './constants';

export type TUseExchangerCurrencies = UseQueryOptions<
  ICurrency[],
  AxiosError,
  ICurrency[],
  string[]
>;

export const useGetExchangerCurrencies = (payload?: {
  config?: TUseExchangerCurrencies;
}): UseQueryResult<ICurrency[]> => {
  const getCurrencies = React.useCallback(() => {
    return api.exchanger.getCurrencies();
  }, []);

  return useAppQuery({
    queryKey: [EXCHANGER_KEYS.CURRENCIES],
    queryFn: getCurrencies,
    ...payload?.config,
  });
};

export const useGetExchangerBackupSeed = (): UseQueryResult<IGetBackupSeedResult> => {
  return useAppQuery({
    queryKey: [EXCHANGER_KEYS.BACKUP_SEED],
    queryFn: api.auth.getExchangerBackupSeed,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export type TGetActiveWalletInfo = UseQueryOptions<
  IActiveWallet,
  AxiosError,
  IActiveWallet
>;

export const useGetActiveWalletInfo = (payload: {
  config?: TGetActiveWalletInfo;
  currencySymbol: CURRENCIES;
}): UseQueryResult<IActiveWallet> => {
  const getActiveWalletInfo = React.useCallback(() => {
    return api.exchanger.getActiveWallet({ currencySymbol: payload.currencySymbol });
  }, []);

  return useAppQuery({
    queryKey: [EXCHANGER_KEYS.ACTIVE_WALLET],
    queryFn: getActiveWalletInfo,
    ...payload?.config,
  });
};

export type TGetPlatformsInfo = UseQueryOptions<IPlatform[], AxiosError, IPlatform[]>;

export const useGetPlatforms = (payload?: {
  config?: TGetPlatformsInfo;
}): UseQueryResult<IPlatform[]> => {
  const getPlatforms = React.useCallback(() => {
    return api.exchanger.getPlatforms();
  }, []);

  return useAppQuery({
    queryKey: [EXCHANGER_KEYS.PLATFORMS],
    queryFn: getPlatforms,
    ...payload?.config,
  });
};

export type TGetAvailablePlatformsInfo = UseQueryOptions<
  IAvailablePlatform[],
  AxiosError,
  IAvailablePlatform[]
>;

export const useGetAvailablePlatforms = (payload?: {
  config?: TGetAvailablePlatformsInfo;
}): UseQueryResult<IAvailablePlatform[]> => {
  const getAvailablePlatforms = React.useCallback(() => {
    return api.exchanger.getAvailablePlatforms();
  }, []);

  return useAppQuery({
    queryKey: [EXCHANGER_KEYS.AVAILABLE_PLATFORMS],
    queryFn: getAvailablePlatforms,
    ...payload?.config,
  });
};

export type TGetPlatformBalancesInfo = UseQueryOptions<
  IPlatformBalance[],
  AxiosError,
  IPlatformBalance[]
>;

export const useGetPlatformBalances = (payload?: {
  config?: TGetPlatformBalancesInfo;
}): UseQueryResult<IPlatformBalance[]> => {
  const getPlatformBalances = React.useCallback(() => {
    return api.exchanger.getPlatformBalances();
  }, []);

  return useAppQuery({
    queryKey: [EXCHANGER_KEYS.PLATFORMS_BALANCE],
    queryFn: getPlatformBalances,
    ...payload?.config,
  });
};

export const useReplaceWallet = (): UseMutationResult<
  unknown,
  AxiosError,
  IReplaceWalletPayload,
  { notifID: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(api.exchanger.replaceWallet, {
    onMutate: () => {
      const notifID = notificationService.show({
        toastId: `replacing-wallet`,
        type: NOTIF_TYPES.LOADING,
        message: 'Replacing exchanger wallet...',
      });
      return { notifID };
    },
    onSuccess: (_data, _variables, context) => {
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'The exchanger wallet has been replaced',
        updateId: context?.notifID,
      });

      queryClient.invalidateQueries([EXCHANGER_KEYS.ACTIVE_WALLET]);
    },
    onError: (_error, _payload, context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message:
          (_error as IBaseAxiosError)?.response?.data?.message ||
          'Some error occurred while replacing your wallet',
        updateId: context?.notifID,
      });
    },
  });
};

export type TGetOrders = UseQueryOptions<IOrderResponse[], AxiosError, IOrderResponse[]>;

export const useGetOrders = (payload: {
  currencySymbol: CURRENCIES;
  config?: TGetOrders;
  exchangerId?: number;
  orderId?: number;
}): UseQueryResult<IOrderResponse[]> => {
  const selectedExchangerPlatform = useAppSelector(
    (state) => state.userSettings.selectedExchangerPlatform
  );
  const getOrders = React.useCallback(() => {
    return api.exchanger.getOrders({
      currencySymbol: payload?.currencySymbol as CURRENCIES,
      exchangeId: selectedExchangerPlatform.id,
    });
  }, [selectedExchangerPlatform]);

  return useAppQuery({
    queryKey: [EXCHANGER_KEYS.ORDERS],
    queryFn: getOrders,
    ...payload?.config,
  });
};

export type TGetExchangerHistory = UseQueryOptions<IExchangerHistoryResult[], AxiosError>;

export const useGetExchangerHistory = (payload: {
  currencySymbol: CURRENCIES;
  config?: TGetExchangerHistory;
}): UseQueryResult<IExchangerHistoryResult[]> => {
  const getExchangerHistory = React.useCallback(() => {
    return api.exchanger.getExchangerHistory({
      currencySymbol: payload.currencySymbol as CURRENCIES,
    });
  }, []);

  return useAppQuery({
    queryKey: [EXCHANGER_KEYS.EXCHANGER_HISTORY, payload.currencySymbol],
    queryFn: getExchangerHistory,
    ...payload?.config,
  });
};

export type TGetDepositHistory = UseQueryOptions<IDepositHistory, AxiosError>;

export const useGetDepositHistory = (payload: {
  currencySymbol: CURRENCIES;
  address: string;
  config?: TGetDepositHistory;
}): UseQueryResult<IDepositHistory> => {
  const getExchangerHistory = React.useCallback(() => {
    return api.exchanger.getExchangerDepositHistory({
      currencySymbol: payload.currencySymbol as CURRENCIES,
      address: payload.address,
    });
  }, []);

  return useAppQuery({
    queryKey: [
      EXCHANGER_KEYS.EXCHANGER_DEPOSIT_HISTORY,
      payload.address,
      payload.currencySymbol,
    ],
    queryFn: getExchangerHistory,
    ...payload?.config,
  });
};

export type TGetWithdrawalLimits = UseQueryOptions<
  IExchangerWithdrawalLimits[],
  AxiosError
>;

export const useGetWithdrawalLimits = (payload?: {
  config?: TGetWithdrawalLimits;
}): UseQueryResult<IExchangerWithdrawalLimits[]> => {
  const getWithdrawalLimits = React.useCallback(() => {
    return api.exchanger.getExchangerWithdrawalLimits();
  }, []);

  return useAppQuery({
    queryKey: [EXCHANGER_KEYS.WITHDRAWAL_LIMITS],
    queryFn: getWithdrawalLimits,
    ...payload?.config,
  });
};

export type TGetExchangerSecrets = UseQueryOptions<IExchangerSecrets[], AxiosError>;

export const useGetExchangerSecrets = (payload: {
  exchangerId?: number;
  config?: TGetExchangerSecrets;
}): UseQueryResult<IExchangerSecrets[]> => {
  const getSecrets = React.useCallback(() => {
    return api.exchanger.getExchangerSecrets({ exchangerId: payload.exchangerId });
  }, []);

  return useAppQuery({
    queryKey: [EXCHANGER_KEYS.EXCHANGER_SECRETS, payload.exchangerId],
    queryFn: getSecrets,
    ...payload?.config,
  });
};

export const useSetupAccount = (): UseMutationResult<
  unknown,
  AxiosError,
  ISetupAccountPayload,
  { notifID: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(api.exchanger.setupAccount, {
    onMutate: () => {
      const notifID = notificationService.show({
        toastId: `replacing-wallet`,
        type: NOTIF_TYPES.LOADING,
        message: 'Creating account in process...',
      });

      return { notifID };
    },
    onSuccess: (_data, variables, context) => {
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'The account has been saved',
        updateId: context?.notifID,
      });

      queryClient.invalidateQueries([EXCHANGER_KEYS.PLATFORMS]);
      queryClient.invalidateQueries([EXCHANGER_KEYS.AVAILABLE_PLATFORMS]);
      queryClient.invalidateQueries([EXCHANGER_KEYS.PLATFORMS_BALANCE]);
      queryClient.invalidateQueries([EXCHANGER_KEYS.WITHDRAWAL_LIMITS]);
      queryClient.invalidateQueries([
        EXCHANGER_KEYS.EXCHANGER_SECRETS,
        variables.exchangeId,
      ]);
    },
    onError: (_error, _payload, context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message:
          (_error as IBaseAxiosError)?.response?.data?.message ||
          'Some error occurred while creating your account',
        updateId: context?.notifID,
      });
    },
  });
};

export const useRemoveAccount = (): UseMutationResult<
  unknown,
  AxiosError,
  IRemoveAccountPayload,
  { notifID: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(api.exchanger.removeAccount, {
    onMutate: () => {
      const notifID = notificationService.show({
        toastId: `replacing-wallet`,
        type: NOTIF_TYPES.LOADING,
        message: 'Removing account in process...',
      });

      return { notifID };
    },
    onSuccess: (_data, variables, context) => {
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'The account has been removed',
        updateId: context?.notifID,
      });

      queryClient.invalidateQueries([EXCHANGER_KEYS.PLATFORMS]);
      queryClient.invalidateQueries([EXCHANGER_KEYS.AVAILABLE_PLATFORMS]);
      queryClient.invalidateQueries([EXCHANGER_KEYS.PLATFORMS_BALANCE]);
      queryClient.invalidateQueries([
        EXCHANGER_KEYS.EXCHANGER_SECRETS,
        variables.exchangeId,
      ]);
    },
    onError: (_error, _payload, context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message:
          (_error as IBaseAxiosError)?.response?.data?.message ||
          'Some error occurred while removing your account',
        updateId: context?.notifID,
      });
    },
  });
};

export const useCreateOrder = (): UseMutationResult<
  unknown,
  AxiosError,
  ICreateOrderPayload
> => {
  const queryClient = useQueryClient();

  return useMutation(api.exchanger.createOrder, {
    onMutate: () => {},
    onSuccess: (_data, variables, _context) => {
      if (variables.responseInfo.success) {
        variables.responseInfo.success.push({
          title: variables.title,
          message: '',
        });
      }

      queryClient.invalidateQueries([EXCHANGER_KEYS.ORDERS]);
      queryClient.invalidateQueries([EXCHANGER_KEYS.PLATFORMS_BALANCE]);
    },
    onError: (_error, payload, _context) => {
      if (payload.responseInfo.errors) {
        payload.responseInfo.errors.push({
          title: payload.title,
          message:
            (_error as IBaseAxiosError)?.response?.data?.message ||
            'Some error occurred while created your order',
        });
      }
    },
  });
};

export type TGetMarketPrice = UseQueryOptions<IGetMarketPriceResult, AxiosError>;

export const useGetMarketPrice = (payload: {
  currencySymbol: CURRENCIES;
  config?: TGetMarketPrice;
}): UseQueryResult<IGetMarketPriceResult> => {
  const getMarketPrice = React.useCallback(() => {
    return api.exchanger.getMarketPrice({ currencySymbol: payload.currencySymbol });
  }, []);

  return useAppQuery({
    queryKey: [EXCHANGER_KEYS.MARKET_PRICE, payload.currencySymbol],
    queryFn: getMarketPrice,
    ...payload?.config,
  });
};

export type TGetMarketsPrices = UseQueryOptions<IGetMarketsPricesResult, AxiosError>;

export const useGetMarketsPrices = (payload: {
  currencySymbol: CURRENCIES;
  config?: TGetMarketsPrices;
}): UseQueryResult<IGetMarketsPricesResult> => {
  const getMarketsPrices = React.useCallback(() => {
    return api.exchanger.getMarketsPrices({ currencySymbol: payload.currencySymbol });
  }, []);

  return useAppQuery({
    queryKey: [EXCHANGER_KEYS.MARKETS_PRICES, payload.currencySymbol],
    queryFn: getMarketsPrices,
    ...payload?.config,
  });
};

export const useRemoveOrder = (): UseMutationResult<
  unknown,
  AxiosError,
  IRemoveOrderPayload,
  { notifID: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(api.exchanger.removeOrder, {
    onMutate: () => {
      const notifID = notificationService.show({
        toastId: `replacing-wallet`,
        type: NOTIF_TYPES.LOADING,
        message: 'Cancelling in process...',
      });

      return { notifID };
    },
    onSuccess: (_data, _variables, context) => {
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'Order has been canceled',
        updateId: context?.notifID,
      });

      queryClient.invalidateQueries([EXCHANGER_KEYS.ORDERS]);
    },
    onError: (_error, _payload, context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message:
          (_error as IBaseAxiosError)?.response?.data?.message ||
          'Some error occurred while canceling your order',
        updateId: context?.notifID,
      });
    },
  });
};

export const usePrepareDepositTransactions = (): UseMutationResult<
  IPreparedTransactionBTC,
  AxiosError,
  IPrepareDepositTransactionPayload
> => {
  return useMutation(api.exchanger.getPrepareDepositTransactions);
};

export const useSendDepositTransactions = (): UseMutationResult<
  void,
  AxiosError,
  ISendDepositTransactionPayload
> => {
  const queryClient = useQueryClient();
  const sendTrx = React.useCallback((payload: ISendDepositTransactionPayload) => {
    return api.exchanger.sendDepositTransactions(payload);
  }, []);

  return useMutation(sendTrx, {
    onMutate: () => {},
    onSuccess: (_data, _context) => {
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'Deposit successfully sent',
      });

      queryClient.invalidateQueries([EXCHANGER_KEYS.ACTIVE_WALLET]);
      queryClient.invalidateQueries([EXCHANGER_KEYS.PLATFORMS_BALANCE]);
      queryClient.invalidateQueries([EXCHANGER_KEYS.WITHDRAWAL_LIMITS]);
      queryClient.invalidateQueries([EXCHANGER_KEYS.EXCHANGER_HISTORY]);
      queryClient.invalidateQueries([EXCHANGER_KEYS.EXCHANGER_DEPOSIT_HISTORY]);
    },
    onError: (_error, _variables, _context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message:
          (_error as IBaseAxiosError)?.response?.data?.message || 'Transaction failed',
      });
    },
  });
};
