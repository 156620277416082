import styled from 'styled-components';

import { Button, FormikInput } from 'components';
import { StyledIndex } from 'components/IndexedChip/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  width: 370px;
  margin-top: 70px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 15px;

  width: 100%;
`;

export const NavButton = styled(Button)`
  width: 180px;
  &:nth-of-type(1) {
    padding-right: 50px;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const CustomIndex = styled(StyledIndex)`
  margin-top: 10px;
`;

export const StyledInput = styled(FormikInput)`
  width: 100%;
  max-width: 300px;
`;
