/* eslint-disable */
import { RLP } from '@ethereumjs/rlp';
import { addHexPrefix } from '@ethereumjs/util';
import { Transaction } from '@ethereumjs/tx';
import { Common } from '@ethereumjs/common';
import { Buffer } from 'buffer';

import {
  ETHNetwork,
  IPreparedTrxDescriptionNONCE,
  ISendTransactions,
} from 'api/wallets/types';
import { getLeafNode } from './leafNode';

export interface ISignEthTransactionProps {
  seed: string;
  transactions: IPreparedTrxDescriptionNONCE[];
  currencyIndex?: number;
  network: ETHNetwork;
}

export const signEthTransaction = ({
  seed,
  transactions,
  currencyIndex,
  network,
}: ISignEthTransactionProps): ISendTransactions[] => {
  if (!currencyIndex) {
    return [];
  }

  const hashesArray: ISendTransactions[] = [];

  transactions.forEach((trx) => {
    const leafNode = getLeafNode({
      firstIndexToDerive: trx.firstIndexToDerive,
      secondIndexToDerive: trx.secondIndexToDerive,
      currencyIndex,
      seed,
    });

    if (leafNode.privateKey) {
      const args = RLP.decode(Buffer.from(trx.hex, 'hex')).slice(0, 6);

      const common = new Common({ chain: BigInt(network) });
      const tx = Transaction.fromTxData(
        {
          //@ts-ignore
          nonce: addHexPrefix(args[0].toString('hex')),
          //@ts-ignore
          gasPrice: addHexPrefix(args[1].toString('hex')),
          //@ts-ignore
          gasLimit: addHexPrefix(args[2].toString('hex')),
          //@ts-ignore
          to: addHexPrefix(args[3].toString('hex')),
          //@ts-ignore
          value: addHexPrefix(args[4].toString('hex')),
          //@ts-ignore
          data: addHexPrefix(args[5].toString('hex')),
        },
        { common }
      );

      const signedHex = tx.sign(leafNode.privateKey).serialize().toString('hex');

      hashesArray.push({
        signature: trx.hex,
        rawTransaction: `0x${signedHex}`,
        fee: trx.fee,
      });
    }
  });

  return hashesArray;
};
