import {
  Button,
  FiltersButton,
  filtersButtonClasses,
  inputClasses,
  RecordsPerPage,
  recordsPerPageClasses,
} from '@private/components';
import styled from 'styled-components';

import { dropdownStyle, SearchInput } from 'components';
import { COLORS } from 'constants/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${filtersButtonClasses.selectFilter} {
    ${dropdownStyle};
  }
`;

export const TopToolbarLeft = styled.div`
  display: flex;
  column-gap: 16px;
`;

export const TopToolbarRight = styled.div`
  display: flex;
  gap: 24px;
`;

export const StyledInputSearch = styled(SearchInput)`
  min-width: 400px;
`;

export const StyledFilterButton = styled(Button)`
  background: ${COLORS.GRAY_LIGHT_300};
  height: 40px;
  border: none;

  &[data-is-active='true'] {
    background: ${COLORS.GRAY_LIGHT_200};
  }
`;

export const StyledFiltersButton = styled(FiltersButton)`
  ${filtersButtonClasses.selectFilter} {
    ${dropdownStyle};
  }

  ${filtersButtonClasses.inputFilter} {
    ${inputClasses.input} {
      height: 40px;
      background: ${COLORS.GRAY_LIGHT_300};
      color: ${COLORS.GRAY_700};

      &::placeholder {
        color: ${COLORS.GRAY_500};
      }
    }
  }
`;

export const StyledRecordsPerPage = styled(RecordsPerPage)`
  ${recordsPerPageClasses.text} {
    color: ${COLORS.GRAY_500};
  }

  ${recordsPerPageClasses.select} {
    width: 90px;
    ${dropdownStyle};
  }
`;

export const StyledSettings = styled(Button)`
  border-radius: 6px;
  color: ${COLORS.GRAY_800};
  background-color: ${COLORS.GRAY_LIGHT_300};

  &[data-is-open='true'] {
    color: ${COLORS.GRAY_700};
    background: ${COLORS.GRAY_LIGHT_500};
  }
`;
