import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import styled from 'styled-components';

import Button from 'components/Button';
import { COLORS, SHADOWS } from 'constants/styles';

export const Content = styled.div`
  transition: all 2s;
  overflow: hidden;
  position: relative;
  min-width: 600px;
  background: ${COLORS.WHITE};
  border-radius: 15px;
  box-shadow: ${SHADOWS.TERTIARY};
`;

export const ContentContainer = styled.div`
  overflow: hidden;
  padding: 24px;
  width: 100%;
  height: 100%;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  margin-bottom: 24px;
`;

export const TitleStyled = styled.div`
  font-family: PT Sans;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${COLORS.GRAY_700};
`;

export const ButtonStyled = styled(Button)`
  font-size: 14px;
  min-width: 160px;
`;

export const FooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin-top: 32px;

  button:not(:last-child) {
    margin-right: 15px;
  }
`;

export const StyledLoader = styled(LinearProgress).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    return !['isVisible'].includes(prop) && defaultValidatorFn(prop);
  },
})<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: ${({ isVisible }) => (!isVisible ? 'translateY(-100%)' : 'translateY(0)')};
  transition: 0.3s;

  & .${linearProgressClasses.bar} {
    background: ${COLORS.BLUE_300};
  }
`;
