import React from 'react';
import { useLocation } from 'react-router-dom';

import { ICONS_MAP } from 'constants/icons';
import { appReactMemo } from 'hocs';
import { useAuthentication } from 'queries/session';
import { getMenu } from 'routes/AuthorizedStack/NavBar';
import { IRoutesConfigItem } from 'routes/config';

import { AccordionTitle, StyledAccordion } from './styled';

interface IAccordionNavMenu {
  accordionStartIcon: React.ReactElement;
  accordionTitle: string;
  items: IRoutesConfigItem[];
}

const AccordionNavMenu = ({
  accordionStartIcon,
  accordionTitle,
  items,
}: IAccordionNavMenu): React.ReactElement => {
  const { role } = useAuthentication();
  const location = useLocation();

  const filteredItems = React.useMemo(() => {
    return items.filter((routeConfig) =>
      routeConfig.roles && role ? routeConfig.roles.includes(role) : true
    );
  }, []);

  const isActiveChild = React.useMemo(() => {
    const isActive = filteredItems.some((item) => {
      return item.path.includes(location.pathname);
    });

    return isActive;
  }, [filteredItems]);

  return (
    <StyledAccordion
      endIcon={<ICONS_MAP.ArrowDown />}
      title={
        <AccordionTitle>
          {accordionStartIcon}
          {accordionTitle}
        </AccordionTitle>
      }
      isInitialOpen={isActiveChild}
      key={accordionTitle}
    >
      {
        filteredItems.map((routesConfigItem) =>
          getMenu({
            routesConfigItem,
            withoutIcon: true,
          })
        ) as React.ReactElement[]
      }
    </StyledAccordion>
  );
};

export default appReactMemo(AccordionNavMenu);
