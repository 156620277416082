import {
  IMultilineInputProps,
  MultilineInput,
  multilineInputClasses,
} from '@private/components';
import styled, { css } from 'styled-components';

import { COLORS } from 'constants/styles';

export interface IStyledInputProps extends IMultilineInputProps {
  sizeVariant?: string;
  showError?: boolean;
  errorMessage?: string;
}

export const StyledMultilineInput = styled(MultilineInput)<IStyledInputProps>`
  ${multilineInputClasses.multilineInput} {
    padding: 0;

    &:focus {
      border: 2px solid ${COLORS.BLUE_50};
      background: ${COLORS.WHITE};
      outline: none;
    }

    ${({ error }) =>
      error &&
      css`
        color: ${COLORS.RED_700};
        border-color: ${COLORS.RED_700};
      `}
  }

  ${multilineInputClasses.label} {
    color: ${COLORS.GRAY_700};
    font-weight: 800;
  }

  ${multilineInputClasses.textarea} {
    padding: 10px 14px;

    border-radius: 5px;
    border-color: ${COLORS.GRAY_300};
    transition: all 0.3s ease;

    &::placeholder {
      font-size: 14px;
      color: ${COLORS.GRAY_300};
      font-weight: normal;
    }

    &:disabled,
    &:hover {
      background: ${COLORS.GRAY_LIGHT_100};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${multilineInputClasses.label} {
        color: ${COLORS.GRAY_300};
      }
    `}
`;

export const Error = styled.div`
  color: ${COLORS.RED_700};
  font-size: 14px;
  margin-top: 6px;
  min-height: 20px;
`;
