import { AutocompleteSelect, autocompleteSelectClasses } from '@private/components';
import styled, { css } from 'styled-components';

import { COLORS, SHADOWS } from 'constants/styles';

export const dropdownStyle = css<{ error?: boolean; disabled?: boolean }>`
  ${autocompleteSelectClasses.label} {
    font-size: 13px;
    color: ${COLORS.GRAY_700};
    font-weight: 800;
  }

  ${autocompleteSelectClasses.inputWrapper} {
    row-gap: 4px;
  }

  ${autocompleteSelectClasses.inputWrapper} {
    height: 40px;
    background: ${COLORS.GRAY_LIGHT_200};
    box-shadow: none;
    border-radius: 5px;

    &:hover {
      background: ${COLORS.GRAY_LIGHT_300};
    }

    ${({ error }) =>
      error &&
      css`
        border-color: ${COLORS.RED_700};
      `}
  }

  ${autocompleteSelectClasses.input} {
    font-size: 14px;
    color: ${COLORS.GRAY_700};

    &::placeholder {
      color: ${COLORS.GRAY_500};
    }
  }

  ${autocompleteSelectClasses.optionsWrapper} {
    box-shadow: ${SHADOWS.MAIN};
  }

  ${autocompleteSelectClasses.option} {
    color: ${COLORS.GRAY_700};

    &[data-is-selected='true'],
    &[data-is-selected='true']:hover {
      background: ${COLORS.GRAY_LIGHT_500};
    }

    &:hover,
    &[data-is-highlighted='true'] {
      background: ${COLORS.GRAY_LIGHT_300};
      color: ${COLORS.GRAY_700};
    }
  }

  ${autocompleteSelectClasses.error} {
    min-height: 20px;
    margin-top: 4px;
    font-size: 13px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${autocompleteSelectClasses.label} {
        color: ${COLORS.GRAY_300};
      }

      ${autocompleteSelectClasses.input} {
        color: ${COLORS.GRAY_500};
      }
    `}
`;

export const StyledSelect = styled(AutocompleteSelect)`
  ${dropdownStyle};
`;
