import styled from 'styled-components';

import { COLORS, GRADIENTS } from 'constants/styles';

export const ChipWrapper = styled.div`
  background: ${COLORS.GRAY_50};
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 8px;
`;

export const StyledIndex = styled.div`
  width: 24px;
  height: 24px;
  text-align: center;
  background: ${GRADIENTS.TERTIARY};
  padding: 4px;
  border-radius: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.WHITE};
`;

export const StyledLabel = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  color: ${COLORS.GRAY_700};
`;
