import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const StepTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StepTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;

  color: ${COLORS.GRAY_700};
`;

export const StepNumber = styled.p`
  position: relative;

  width: 30px;
  height: 30px;

  border: 2px solid ${COLORS.BLUE_200};
  border-radius: 50%;

  &::before {
    content: attr(data-stepnumber);

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    font-weight: 700;
    font-size: 16px;

    color: ${COLORS.BLUE_300};
  }
`;
