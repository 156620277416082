import React from 'react';

import { appReactMemo } from 'hocs';

import { Error, IStyledInputProps, StyledMultilineInput } from './styled';

const MultilineInput: React.FC<IStyledInputProps> = ({
  error,
  showError,
  errorMessage,
  ...rest
}) => {
  return (
    <>
      <StyledMultilineInput {...rest} error={error} />
      {showError && <Error>{error && errorMessage}</Error>}
    </>
  );
};

export default appReactMemo(MultilineInput);
