import styled from 'styled-components';

import { ICONS_MAP } from 'constants/icons';
import { COLORS } from 'constants/styles';

export const SAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 8px;
  background-color: ${COLORS.GRAY_LIGHT_100};
  padding: 16px;
  margin-bottom: 20px;
`;

export const SAmount = styled.h3`
  font-family: PT Sans;
  font-size: 24px;
  font-weight: 700;
  color: ${COLORS.GRAY_700};
  margin: 8px 0;
`;

export const SMessage = styled.p`
  font-family: PT Sans;
  font-size: 16px;
  font-weight: 400;
  color: ${COLORS.GRAY_700};
  & > span {
    color: ${COLORS.RED_600};
    font-weight: bold;
  }
`;

export const SInfoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const SInfoDesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SFeeIcon = styled(ICONS_MAP.FeeIcon)`
  margin-left: 12px;
  margin-right: 26px;
`;

export const SWarningIcon = styled(ICONS_MAP.WarningRed)`
  margin-right: 22px;
`;

export const AvailableIcon = styled(ICONS_MAP.Available)`
  background: ${COLORS.GRAY_LIGHT_150};
  border-radius: 8px;
`;
