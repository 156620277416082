import styled, { css } from 'styled-components';

import { Button } from 'components';
import { COLORS, SHADOWS } from 'constants/styles';
import { StatusTypes } from 'utils/types';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 24px 40px;
  overflow-y: auto;
`;

export const PageTitle = styled.h2`
  max-width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;

  white-space: nowrap;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: ${COLORS.GRAY_700};
`;

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
`;

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PageHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-right: 20px;
`;

export const StyledPageHeaderInfo = styled(PageHeaderInfo)`
  margin-bottom: 20px;
`;

export const Status = styled.div<{ status?: string; isActive?: boolean }>`
  color: ${({ status, isActive }) => {
    const currentStatus = status && status.toLowerCase();
    if (currentStatus === 'success' || isActive) {
      return COLORS.GREEN_400;
    }
    if (currentStatus === 'pending' || !isActive) {
      return COLORS.RED_600;
    }
  }};
`;

export const QuantityWrapper = styled.div`
  background: ${COLORS.GRAY_LIGHT_300};
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.BLUE_400};
`;

interface IStyledIcon {
  width?: string;
  height?: string;
  marginRight?: number;
}

export const StyledIcon = styled.div<IStyledIcon>`
  ${({ width = 'unset', height = 'unset', marginRight = 10 }) => css`
    display: flex;
    align-items: center;

    margin-right: ${marginRight}px;

    svg {
      width: ${width};
      height: ${height};
    }
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
`;

export const StyledButtonToggle = styled(Button)`
  position: absolute;
  right: 0;
  top: 28px;
  transform: translateX(50%) rotate(90deg);
  background: ${COLORS.WHITE};
  box-shadow: ${SHADOWS.MAIN};
  border-radius: 50%;
  width: 32px;
  height: 32px;

  padding: 0;

  &:hover {
    background: ${COLORS.GRAY_300};
  }

  &:active {
    background: ${COLORS.WHITE};
  }

  z-index: 1;
`;

export const PlatformContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
  font-size: 14px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

interface IStyledStatus {
  status: StatusTypes;
  fontSize?: number;
}

export const StyledStatus = styled.p<IStyledStatus>`
  ${({ fontSize }) => css`
    font-size: ${fontSize || 16}px;
  `}

  color: ${({ status }: { status: string }) => {
    switch (status) {
      case StatusTypes.pending:
      case StatusTypes.created:
        return COLORS.YELLOW_700;
      case StatusTypes.completed:
      case StatusTypes.success:
        return COLORS.GREEN_400;
      case StatusTypes.canceled:
      case StatusTypes.failed:
        return COLORS.RED_600;
      default:
        return COLORS.GRAY_300;
    }
  }};
`;

interface ICustomIconSize {
  size?: number;
}

export const CustomIconSize = styled.div<ICustomIconSize>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ size }) => css`
    svg {
      width: ${size || 20}px;
      height: ${size || 20}px;
    }
  `}
`;
