import { Stepper, stepperClasses } from '@private/components';
import styled from 'styled-components';

import { COLORS, GRADIENTS } from 'constants/styles';

export default styled(Stepper)`
  ${stepperClasses.text} {
    color: ${COLORS.GRAY_700};
  }

  ${stepperClasses.stepSlider} {
    &::before {
      background: ${GRADIENTS.QUATERNARY};
    }
  }

  ${stepperClasses.step} {
    &::before {
      background: ${COLORS.GRAY_LIGHT_100};
    }
  }
`;
