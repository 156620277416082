import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const SendPaymentsMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SendPaymentsMessage = styled.div`
  margin-bottom: 24px;

  color: ${COLORS.GRAY_700};
  text-align: center;
  font-weight: 400;
  font-size: 16px;
`;

export const SendPaymentsWarning = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 12px 16px;

  color: ${COLORS.GRAY_700};
  font-weight: 400;
  font-size: 16px;

  min-width: 270px;
  background-color: ${COLORS.GRAY_LIGHT_150};
  border-radius: 8px;
`;
