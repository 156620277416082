import React from 'react';

import { ReactComponent as ActiveBrandsIcon } from 'assets/icons/navBarIcons/activeBrands.svg';
import { ReactComponent as ActiveGroupsIcon } from 'assets/icons/navBarIcons/activeGroups.svg';
import { ReactComponent as ActiveHeadquartersIcon } from 'assets/icons/navBarIcons/activeHeadquarters.svg';
import { ReactComponent as ActiveMultisenderIcon } from 'assets/icons/navBarIcons/activeMultisender.svg';
import { ReactComponent as ActiveSystemSettingsIcon } from 'assets/icons/navBarIcons/activeSystemSettings.svg';
import { ReactComponent as ActiveSystemWalletsIcon } from 'assets/icons/navBarIcons/activeSystemWallets.svg';
import { ReactComponent as ActiveToolsIcon } from 'assets/icons/navBarIcons/activeTools.svg';
import { ReactComponent as DefaultBrandsIcon } from 'assets/icons/navBarIcons/defaultBrands.svg';
import { ReactComponent as DefaultGroupsIcon } from 'assets/icons/navBarIcons/defaultGroups.svg';
import { ReactComponent as DefaultHeadquartersIcon } from 'assets/icons/navBarIcons/defaultHeadquarters.svg';
import { ReactComponent as DefaultMultisenderIcon } from 'assets/icons/navBarIcons/defaultMultisender.svg';
import { ReactComponent as DefaultSystemSettingsIcon } from 'assets/icons/navBarIcons/defaultSystemSettings.svg';
import { ReactComponent as DefaultSystemWalletsIcon } from 'assets/icons/navBarIcons/defaultSystemWallets.svg';
import { ReactComponent as DefaultToolsIcon } from 'assets/icons/navBarIcons/defaultTools.svg';

export enum NAVBAR_ICONS_NAME {
  HEADQUARTERS = 'HEADQUARTERS',
  GROUPS = 'GROUPS',
  BRANDS = 'BRANDS',
  SYSTEM_WALLETS = 'SYSTEM_WALLETS',
  SYSTEM_SETTINGS = 'SYSTEM_SETTINGS',
  MULTISENDER = 'MULTISENDER',
  TOOLS = 'TOOLS',
}

export interface INavBarIcons {
  navBarIcon?: React.ReactElement;
  navBarActiveIcon?: React.ReactElement;
}

export const NAVBAR_ICONS: Record<NAVBAR_ICONS_NAME, INavBarIcons> = {
  [NAVBAR_ICONS_NAME.HEADQUARTERS]: {
    navBarIcon: <DefaultHeadquartersIcon />,
    navBarActiveIcon: <ActiveHeadquartersIcon />,
  },
  [NAVBAR_ICONS_NAME.GROUPS]: {
    navBarIcon: <DefaultGroupsIcon />,
    navBarActiveIcon: <ActiveGroupsIcon />,
  },
  [NAVBAR_ICONS_NAME.BRANDS]: {
    navBarIcon: <DefaultBrandsIcon />,
    navBarActiveIcon: <ActiveBrandsIcon />,
  },
  [NAVBAR_ICONS_NAME.SYSTEM_WALLETS]: {
    navBarIcon: <DefaultSystemWalletsIcon />,
    navBarActiveIcon: <ActiveSystemWalletsIcon />,
  },
  [NAVBAR_ICONS_NAME.SYSTEM_SETTINGS]: {
    navBarIcon: <DefaultSystemSettingsIcon />,
    navBarActiveIcon: <ActiveSystemSettingsIcon />,
  },
  [NAVBAR_ICONS_NAME.MULTISENDER]: {
    navBarIcon: <DefaultMultisenderIcon />,
    navBarActiveIcon: <ActiveMultisenderIcon />,
  },
  [NAVBAR_ICONS_NAME.TOOLS]: {
    navBarIcon: <DefaultToolsIcon />,
    navBarActiveIcon: <ActiveToolsIcon />,
  },
};
