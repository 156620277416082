import BIP32Factory, { BIP32Interface } from 'bip32';
import * as ecc from 'tiny-secp256k1';

interface IGetLeafNodeProps {
  seed: string;
  currencyIndex: number;
  firstIndexToDerive: number;
  secondIndexToDerive: number;
}

export function getLeafNode({
  currencyIndex,
  seed,
  firstIndexToDerive,
  secondIndexToDerive,
}: IGetLeafNodeProps): BIP32Interface {
  const bip32 = BIP32Factory(ecc);
  const hdRoot = bip32.fromSeed(Buffer.from(seed, 'hex'));

  return hdRoot
    .deriveHardened(44)
    .deriveHardened(currencyIndex)
    .deriveHardened(0)
    .derive(firstIndexToDerive)
    .derive(secondIndexToDerive);
}
