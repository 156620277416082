import React from 'react';

import { ModalContainer } from 'components';
import { IModalContainerProps } from 'components/ModalContainer';
import { ERROR_CODES } from 'constants/errors';
import { useCurrenciesQuery } from 'queries/wallets';
import { parseErrorMessage } from 'utils/common';

import { CURRENCIES } from '../../api/wallets/constants';

import { SInfoContainer, SInfoDesContainer, SMessage, SWarningIcon } from './styled';

export interface IWithdrawalModalProps {
  onClose: () => void;
  onRetry: () => void;
  isLoading: boolean;
  error?: unknown;
  currentError: Partial<IModalContainerProps> | null;
  errorCode?: ERROR_CODES | Partial<IModalContainerProps> | null;
  currency?: CURRENCIES;
  title: string;
}

const ModalError = ({
  onClose,
  isLoading,
  onRetry,
  error,
  currentError,
  errorCode,
  currency,
  title,
}: IWithdrawalModalProps): React.ReactElement => {
  const { getSystemWalletCurrency } = useCurrenciesQuery();

  return (
    <ModalContainer
      title={title}
      rightBtnText={currentError?.rightBtnText || 'Retry'}
      rightBtnVariant={currentError?.rightBtnVariant || 'danger'}
      onDismiss={onClose}
      onLeftAction={currentError?.onLeftAction || onClose}
      onRightAction={currentError?.onRightAction || onRetry}
      disableRightBtn={isLoading}
    >
      <SInfoContainer>
        <SWarningIcon />
        <SInfoDesContainer>
          {errorCode === ERROR_CODES.INSUFFICIENT_SYSTEM_BALANCE ? (
            <SMessage>
              <span>Insufficient funds on </span>
              <b>{getSystemWalletCurrency(currency)}</b>
              <span> system wallet. You need to make a deposit to proceed.</span>
            </SMessage>
          ) : (
            <>
              <SMessage>Withdrawal error:</SMessage>
              <SMessage>{parseErrorMessage(error)}</SMessage>
            </>
          )}
        </SInfoDesContainer>
      </SInfoContainer>
    </ModalContainer>
  );
};

export default ModalError;
