import React from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IInputProps } from '@private/components';

import { appReactMemo } from 'hocs';

import { StyledInput } from './styled';

const Input: React.FC<IInputProps> = ({ error, type, endIcon, ...rest }) => {
  const [currentType, setCurrentType] = React.useState(type);

  const getIcon = React.useCallback(() => {
    if (type === 'password') {
      if (currentType === 'password') {
        return <VisibilityIcon />;
      }
      return <VisibilityOffIcon />;
    }

    return endIcon;
  }, [currentType, error, endIcon, type]);

  const handleTypeChange = React.useCallback(() => {
    setCurrentType((state) => (state === 'password' ? 'text' : 'password'));
  }, []);

  const getOnEndIconClick = React.useCallback(() => {
    if (type !== 'password') {
      return rest.onEndIconClick;
    }
    return handleTypeChange || rest.onIconClick;
  }, [rest.onEndIconClick, rest.onIconClick]);

  const getEndIconSize = React.useCallback(() => {
    if (type === 'password') {
      return 24;
    }
    if (error) {
      return 17;
    }
    return rest.endIconSize;
  }, [type, error, rest.endIconSize]);

  const onKeyDown = React.useCallback((evt) => {
    const prohibitedKeys = ['e', ','];

    rest?.onKeyDown?.(evt);
    return prohibitedKeys.includes(evt.key.toLowerCase()) && evt.preventDefault();
  }, []);

  return (
    <StyledInput
      {...rest}
      {...(type === 'number' && {
        onKeyDown,
      })}
      type={currentType}
      error={error}
      endIcon={getIcon()}
      onEndIconClick={getOnEndIconClick()}
      endIconSize={getEndIconSize()}
    />
  );
};

export default appReactMemo(Input);
