import React from 'react';
import { useLocation } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { FormikInput } from 'components';
import { ROUTES } from 'constants/routes';
import { appReactMemo } from 'hocs';
import useBackup from 'queries/session/useBackup';
import { useSecurityContext } from 'routes/UnauthorizedStack/Security/context';
import { useSecurityBackup } from 'utils/crypto/backups';

import { ButtonsRow } from '../../styled';

import {
  Container,
  CustomIndex,
  InputsContainer,
  NavButton,
  Row,
  StyledInput,
} from './styled';

const generateRandomMnemonicIndexes = (): number[] => {
  const result = new Set<number>();
  while (result.size < 4) {
    const index = Math.floor(Math.random() * 24);
    if (!result.has(index)) {
      result.add(index);
    }
  }

  return Array.from(result);
};

interface IFormConfig {
  initialValues: Record<number | string, string>;
  validationSchema?: Yup.AnyObjectSchema;
}

const ConfirmSeedPage = () => {
  const location = useLocation();

  const isMultisenderSetSeedPage = React.useMemo(() => {
    return location.pathname === ROUTES.MULTISENDER_SEED.PATH;
  }, [location.pathname]);

  const isExchangerSetSeedPage = React.useMemo(() => {
    return location.pathname === ROUTES.EXCHANGER_SEED.PATH;
  }, [location.pathname]);

  const { goBack } = useSecurityContext();
  const { data: backups } = useSecurityBackup({
    isMultisenderBackup: isMultisenderSetSeedPage,
    isExchangerBackup: isExchangerSetSeedPage,
  });
  const { mutate, isLoading } = useBackup({
    isMultisender: isMultisenderSetSeedPage,
    isExchanger: isExchangerSetSeedPage,
  });

  React.useEffect(() => {
    if (
      window.CONFIG.API_URL.endsWith('.dev') ||
      window.CONFIG.API_URL.endsWith('.best')
    ) {
      // eslint-disable-next-line
      console.log({ backups });
    }
  }, [backups]);

  const onSuccess = React.useCallback(() => {
    if (backups) {
      mutate({
        seed: `${backups?.seedRemainings}`,
        currencies: backups?.pubKeys,
      });
    }
  }, [backups, mutate]);

  const mnemonicWordsToCheck = React.useMemo(() => {
    return generateRandomMnemonicIndexes();
  }, []);

  const formConfig: IFormConfig = React.useMemo(() => {
    const mnemonicList = backups?.mnemonic || [];
    const validationSchema: Record<number | string, Yup.StringSchema> = {
      secret: Yup.string().ensure().required().oneOf([backups?.secretFromSeed]),
    };

    const result: IFormConfig = {
      initialValues: {
        secret: '',
      },
    };
    mnemonicWordsToCheck.forEach((item) => {
      result.initialValues[item] = '';
      validationSchema[item + 1] = Yup.string()
        .ensure()
        .required()
        .oneOf([mnemonicList[item]]);
    });

    result.validationSchema = Yup.object().shape(validationSchema);
    return result;
  }, [mnemonicWordsToCheck, backups]);

  return (
    <Formik
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={onSuccess}
    >
      {({ isValid, dirty }) => {
        return (
          <Container>
            <InputsContainer>
              {mnemonicWordsToCheck.map((item) => {
                return (
                  <Row key={item}>
                    <CustomIndex>{item + 1}</CustomIndex>
                    <StyledInput required name={`${item + 1}`} />
                  </Row>
                );
              })}
              <FormikInput name='secret' label='Confirm Secret' required />
            </InputsContainer>

            <ButtonsRow>
              <NavButton
                variant='transparent'
                onClick={goBack}
                startIcon={<ChevronLeftIcon />}
                iconMargin={30}
              >
                Back
              </NavButton>
              <NavButton
                type='submit'
                onClick={onSuccess}
                variant='primary'
                disabled={!dirty || !isValid || isLoading}
              >
                Confirm
              </NavButton>
            </ButtonsRow>
          </Container>
        );
      }}
    </Formik>
  );
};

export default appReactMemo(ConfirmSeedPage);
