import { inputClasses, NumericInput, numericInputClasses } from '@private/components';
import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const StyledNumericInput = styled(NumericInput)`
  max-width: 232px;

  ${numericInputClasses.input} {
    &:not(:last-child):after {
      display: none;
    }
  }

  ${inputClasses.input} {
    width: 32px;
    height: 40px;

    &:focus {
      border: 2px solid ${COLORS.BLUE_50};
    }
  }
`;
