import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 220px;
  text-align: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 156%;
  color: #bdbdbd;
  margin-top: 28px;
`;

export const Subtitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #bdbdbd;
  margin-top: 4px;
`;
