import { Link } from 'react-router-dom';

import { Formik } from 'formik';

import { ISignInPayload } from 'api/auth/types';
import { FormikInput } from 'components';
import { PASSWORD_REQUIREMENTS } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { appReactMemo } from 'hocs';
import { useRegister } from 'queries/session';

import { initialValues, signUpSchema } from './helpers';
import {
  ButtonStyled,
  Container,
  FormStyled,
  Hint,
  StyledMessageHint,
  StyledPasswordHint,
  StyledTitleHint,
  Title,
} from './styled';

const SignUp = () => {
  const { mutate: register, isLoading } = useRegister();

  const onSubmit = ({ email, password }: ISignInPayload) => {
    register({
      email,
      password,
    });
  };

  return (
    <Container>
      <Title>Sign Up</Title>
      <Formik
        initialValues={initialValues}
        validationSchema={signUpSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isValid, dirty }) => (
          <FormStyled onSubmit={handleSubmit}>
            <FormikInput
              autoFocus
              autoComplete='username'
              name='email'
              label='Email'
              required
            />

            <FormikInput
              type='password'
              name='password'
              label='Password'
              autoComplete='new-password'
              required
            />

            <FormikInput
              type='password'
              name='confirmPassword'
              label='Confirm Password'
              autoComplete='new-password'
              required
            />

            <StyledPasswordHint>
              <StyledTitleHint>{PASSWORD_REQUIREMENTS.TITLE}</StyledTitleHint>
              <StyledMessageHint>{PASSWORD_REQUIREMENTS.HINT}</StyledMessageHint>
            </StyledPasswordHint>

            <ButtonStyled
              variant='primary'
              type='submit'
              loading={isLoading}
              disabled={!isValid || !dirty}
            >
              Register
            </ButtonStyled>
          </FormStyled>
        )}
      </Formik>
      <Hint>
        Already have an account?
        <Link to={ROUTES.SIGN_IN.PATH}>Log in!</Link>
      </Hint>
    </Container>
  );
};

export default appReactMemo(SignUp);
