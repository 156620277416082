import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

import { parseErrorMessage } from 'utils/common';
import { NOTIF_TYPES, notificationService } from 'utils/notifications';

export const useQueryData = <T>(queryKey: string | string[]): T | undefined => {
  const queryClient = useQueryClient();

  const { data } = useQuery<T | undefined>(
    queryKey,
    () => queryClient.getQueryData(queryKey),
    { enabled: false }
  );
  return data;
};

export const useAppQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...options,
    onError: (error) => {
      const errorMessage = parseErrorMessage(error);
      const isTimeout = errorMessage.toLowerCase().includes('timeout');
      if (isTimeout) {
        notificationService.show({
          type: NOTIF_TYPES.ERROR,
          message: errorMessage,
          toastId: 'timeout',
        });
        return;
      }
      options.onError?.(error);
    },
  });
};
