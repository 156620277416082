export enum WALLETS_KEYS {
  CURRENCIES = 'wallets/currencies',
  WITHDRAWAL_WALLETS = 'wallets',
  WITHDRAWAL_HISTORY = 'wallets/history',
  SETTINGS = 'wallets/settings',
  SYSTEM_WALLET = 'wallets/system',
  CURRENT_BLOCK = '/wallets/last-block/time',
  WALLET_INFO = 'wallets/info',
  SYSTEM_WALLET_HISTORY = 'wallets/system_history',
  BLOCKED_WALLETS = 'wallets/blocked',
}
