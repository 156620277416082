import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 13px;
`;

export const Active = styled(NavLink)`
  color: ${COLORS.BLUE_200};
  transition: 0.2s;
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: ${COLORS.BLUE_200};
  }
  &:active {
    text-decoration: underline;
    color: ${COLORS.BLUE_300};
  }
  &:not(:nth-of-type(1)) {
    margin-left: 13px;
    &::before {
      content: '/';
      position: absolute;
      left: -8px;
    }
  }
`;

export const Disabled = styled.span`
  color: ${COLORS.GRAY_500};
  font-weight: normal;
  display: flex;
  &::before {
    content: '/';
    margin-left: 5px;
    margin-right: 5px;
  }
`;
