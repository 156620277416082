import styled from 'styled-components';

import { Button, NumericInput } from 'components';
import { COLORS } from 'constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 610px;
  padding: 120px;
`;

export const Text = styled.h4`
  font-family: PT Sans;
  font-size: 18px;
  color: ${COLORS.GRAY_700};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  margin-top: 24px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const Styled2FAInput = styled(NumericInput)`
  margin-top: 30px;
`;

export const NavButton = styled(Button)`
  width: 180px;
  &:nth-of-type(1) {
    padding-right: 30px;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-family: PT Sans;
  font-size: 32px;
  color: ${COLORS.GRAY_700};
`;

export const SubTitle = styled.h4`
  font-family: PT Sans;
  font-size: 18px;
  align-self: flex-start;
  color: ${COLORS.GRAY_700};
  margin-top: 16px;
`;
