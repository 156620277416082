import React from 'react';

import { IAutocompleteSelectProps } from '@private/components';
import { useField } from 'formik';

import { Select } from 'components';
import { appReactMemo } from 'hocs';

interface IFormikSelect extends IAutocompleteSelectProps {
  name: string;
}

const FormikSelect: React.FC<IFormikSelect> = ({
  name,
  showError = true,
  options,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);

  const onBlur = React.useCallback(() => {
    helpers.setTouched(true);
  }, []);

  const onSelectedOption = React.useCallback((option) => {
    helpers.setValue(option);
  }, []);

  return (
    <Select
      {...rest}
      showError={showError}
      blurOnSelect={false}
      options={options}
      value={field.value}
      onChange={onSelectedOption}
      onBlur={onBlur}
      error={!!(meta.error && meta.touched)}
      errorMessage={meta.error}
    />
  );
};

export default appReactMemo(FormikSelect);
