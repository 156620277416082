import React from 'react';

import { IAutocompleteSelectProps } from '@private/components';

import { appReactMemo } from 'hocs';

import { StyledSelect } from './styled';

const Select: React.FC<IAutocompleteSelectProps> = (rest) => {
  return <StyledSelect popoverGap={3} {...rest} />;
};

export default appReactMemo(Select);
