import React from 'react';

import { useModal } from '@private/modals';

import { IPreparedMultisenderTransaction } from 'api/multisender/types';
import { CURRENCIES, CURRENCIES_TYPE } from 'api/wallets/constants';
import { useGetCurrencyData } from 'hooks';
import { usePrepareTransaction as useMultisenderPrepareTransaction } from 'queries/multisender';

import { ModalInfo, ModalLoading } from '../../WithdrawalTemplates';

export interface IWithdrawalModalConfig {
  onConfirm: (preparedTrx: IPreparedMultisenderTransaction) => void;
  currency: CURRENCIES;
  currencyType: CURRENCIES_TYPE;
  activityId: string;
  pages: number;
}

export interface IWithdrawalModalProps extends IWithdrawalModalConfig {
  onClose: () => void;
}

const WithdrawalModal = ({
  onClose,
  onConfirm,
  activityId,
  pages,
  currency,
  currencyType,
}: IWithdrawalModalProps): React.ReactElement => {
  const currencyData = useGetCurrencyData(currency);
  const {
    mutateAsync: prepareTransaction,
    isLoading,
    data,
  } = useMultisenderPrepareTransaction();

  const ethCryptoFee = (data as IPreparedMultisenderTransaction)?.preparedTransactions[0]
    ?.cryptoFee;
  const ethUSDfee = (data as IPreparedMultisenderTransaction)?.preparedTransactions[0]
    ?.feeUSD;

  const getPreparedTrx = React.useCallback(() => {
    prepareTransaction({
      currencyType,
      activityId,
      pageNumber: 1,
    });
  }, [currencyType, currency, activityId, pages]);

  React.useEffect(() => {
    getPreparedTrx();
  }, []);

  const onConfirmClick = React.useCallback(() => {
    if (data) {
      onConfirm(data);
    }
  }, [data, onConfirm]);

  if (isLoading) {
    return (
      <ModalLoading title='Withdrawal funds' isLoading={isLoading} onClose={onClose} />
    );
  }

  return (
    <ModalInfo
      onClose={onClose}
      onConfirmClick={onConfirmClick}
      title='Withdrawal funds'
      cryptoAmountToWithdraw={data?.amount}
      currency={currency}
      feeCurrencySymbol={currencyData?.systemSymbol as CURRENCIES}
      ethCryptoFee={ethCryptoFee}
      inputsCount={data?.preparedTransactions?.length || 0}
      ethUSDfee={ethUSDfee}
      cryptoTransactionFee={data?.cryptoFee}
      transactionFeeUSD={data?.feeUSD}
    />
  );
};

type TUseWithdrawalModalResult = [(config: IWithdrawalModalConfig) => void, () => void];

export const useWithdrawalMultisenderModal = (): TUseWithdrawalModalResult => {
  const { closeModal, showModal } = useModal();

  const showWithdrawalModal = React.useCallback((config: IWithdrawalModalConfig) => {
    showModal(<WithdrawalModal onClose={closeModal} {...config} />);
  }, []);

  return [showWithdrawalModal, closeModal];
};

export default WithdrawalModal;
