import { Button, IButtonProps } from '@private/components';
import styled, { css } from 'styled-components';

import { COLORS } from 'constants/styles';

export const StyledButton = styled(Button)<IButtonProps>`
  border-radius: 6px;
  transition: 0.2s;
  min-height: 25px;
  padding: 0px 18px;

  span {
    padding-top: 2px;
  }

  &:disabled svg {
    color: ${COLORS.GRAY_300};
  }

  ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return css`
          border-radius: 6px;
          font-weight: bold;
          transition: 0.2s;

          min-height: 40px;
          background: ${COLORS.BLUE_400};
          color: ${COLORS.WHITE};

          &:focus {
            outline: none;
          }

          &:hover {
            background: ${COLORS.BLUE_400};
          }

          &:active {
            background: ${COLORS.BLUE_500};
          }

          &:disabled {
            background-color: ${COLORS.GRAY_LIGHT_300};
            pointer-events: none;
            color: ${COLORS.GRAY_300};
          }
        `;
      case 'secondary':
        return css`
          border-radius: 6px;
          font-weight: bold;
          transition: 0.2s;

          min-height: 40px;
          background: ${COLORS.GRAY_LIGHT_300};
          color: ${COLORS.BLUE_400};

          &:focus {
            outline: none;
          }

          &:hover {
            background: ${COLORS.GRAY_LIGHT_400};
          }

          &:active {
            background: ${COLORS.GRAY_LIGHT_500};
          }

          &:disabled {
            background-color: ${COLORS.GRAY_LIGHT_300};
            box-shadow: none;
            color: ${COLORS.GRAY_300};
            pointer-events: none;
          }
        `;
      case 'light':
        return css`
          border-radius: 6px;
          font-weight: bold;
          transition: 0.2s;
          min-height: 40px;
          background: ${COLORS.GRAY_LIGHT_300};
          color: ${COLORS.GRAY_700};

          svg {
            color: ${COLORS.GRAY_500};
          }

          &:focus {
            outline: none;
          }

          &:hover {
            background: ${COLORS.GRAY_LIGHT_400};
          }

          &:active {
            background: ${COLORS.GRAY_LIGHT_500};
          }

          &:disabled {
            background-color: ${COLORS.GRAY_LIGHT_300};
            box-shadow: none;
            color: ${COLORS.GRAY_300};
            pointer-events: none;
          }
        `;
      case 'icon':
        return css`
          font-weight: bold;
          transition: 0.2s;
          color: ${COLORS.GRAY_500};
          border-radius: 6px;
          width: auto;
          padding: 7px;

          svg + span {
            margin-left: 15px;
          }

          &:focus {
            outline: none;
          }

          &:hover {
            background: ${COLORS.GRAY_LIGHT_400};
          }

          &:active {
            background: ${COLORS.GRAY_LIGHT_500};
          }

          &:disabled {
            background-color: ${COLORS.GRAY_LIGHT_300};
            box-shadow: none;
            color: ${COLORS.GRAY_300};
            pointer-events: none;
          }
        `;
      case 'danger':
        return css`
          border-radius: 6px;
          font-weight: bold;

          transition: 0.2s;
          min-height: 40px;

          background-color: ${COLORS.RED_600};
          color: ${COLORS.WHITE};

          &:focus {
            outline: none;
          }

          &:hover {
            background: ${COLORS.RED_500};
          }

          &:disabled {
            background-color: ${COLORS.RED_500};
          }
        `;
      case 'iconSolid':
        return css`
          font-weight: bold;
          transition: 0.2s;
          color: ${COLORS.GRAY_500};
          border-radius: 50%;
          padding: 0px;
          background: none;
          width: auto;
          svg + span {
            margin-left: 15px;
          }

          &:focus {
            outline: none;
          }

          &:hover {
            color: ${COLORS.BLUE_300};
          }

          &:active {
            color: ${COLORS.BLUE_400};
          }

          &:disabled {
            color: ${COLORS.GRAY_300};
            box-shadow: none;
            pointer-events: none;
          }
        `;
      case 'transparent':
        return css`
          border-radius: 6px;
          font-weight: bold;
          border: none;
          transition: 0.2s;
          min-height: 40px;
          background-color: transparent;
          color: ${COLORS.GRAY_700};

          &:focus {
            outline: none;
          }

          &:hover {
            background: ${COLORS.GRAY_LIGHT_400};
          }

          &:active {
            background: ${COLORS.GRAY_LIGHT_500};
          }

          &:disabled {
            background: none;
            color: ${COLORS.GRAY_500};
          }
        `;
      case 'link':
        return css`
          border-radius: 6px;
          font-weight: 400;
          border: none;
          transition: 0.2s;
          min-height: 40px;
          background-color: transparent;
          color: ${COLORS.BLUE_300};

          &:focus {
            outline: none;
          }

          &:hover {
            color: ${COLORS.BLUE_400};
          }

          &:active {
            color: ${COLORS.BLUE_300};
          }

          &:disabled {
            background: none;
            color: ${COLORS.GRAY_500};
          }
        `;
      case 'bordered':
        return css`
          min-height: 40px;
          border-radius: 6px;
          font-weight: bold;
          border: 1px solid ${COLORS.GRAY_700};
          transition: 0.2s;
          background-color: transparent;
          color: ${COLORS.GRAY_700};

          &:focus {
            outline: none;
          }

          &:hover {
            background: ${COLORS.GRAY_LIGHT_100};
            box-shadow: none;
          }

          &:active {
            background: ${COLORS.GRAY_LIGHT_200};
          }

          &:disabled {
            background: none;
            color: ${COLORS.GRAY_500};
          }
        `;
      default:
        return css`
          border-radius: 6px;
          font-weight: bold;
          transition: 0.2s;

          min-height: 40px;
          background: ${COLORS.BLUE_400};
          color: ${COLORS.WHITE};

          &:focus {
            outline: none;
          }

          &:hover {
            background: ${COLORS.BLUE_400};
          }

          &:active {
            background: ${COLORS.BLUE_500};
          }

          &:disabled {
            background-color: ${COLORS.GRAY_LIGHT_300};
            pointer-events: none;
            color: ${COLORS.GRAY_300};
          }
        `;
    }
  }}
`;
