import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QRCode from 'qrcode.react';

import { appReactMemo } from 'hocs';
import { useAuthentication } from 'queries/session';
import { useSecurityContext } from 'routes/UnauthorizedStack/Security/context';

import { ButtonsRow } from '../../styled';
import { Container, Text } from '../styled';

import { DescriptionContainer, NavButton, QRCodeHolder, SecretInput } from './styled';

const AssignAppPage = () => {
  const { goNext, goBack, secondFactorSecret } = useSecurityContext();
  const { user } = useAuthentication();
  return (
    <Container>
      <div>
        <DescriptionContainer>
          <Text>Scan QR-code in Google Authenticator app</Text>
        </DescriptionContainer>

        <QRCodeHolder>
          <QRCode
            size={120}
            value={`otpauth://totp/hd-wallet (${user})?secret=${secondFactorSecret}`}
          />
          <div>
            <p>Please save this Secret Key.</p>
            <p>
              This Secret Key will allow you to recover your Google Authenticator in case
              of phone loss.
            </p>
          </div>
        </QRCodeHolder>
        <SecretInput readOnly value={secondFactorSecret} />
      </div>

      <ButtonsRow>
        <NavButton
          variant='transparent'
          onClick={goBack}
          startIcon={<ChevronLeftIcon />}
          iconMargin={30}
        >
          Previous
        </NavButton>
        <NavButton
          variant='light'
          onClick={goNext}
          endIcon={<ChevronRightIcon />}
          iconMargin={30}
        >
          Next
        </NavButton>
      </ButtonsRow>
    </Container>
  );
};

export default appReactMemo(AssignAppPage);
