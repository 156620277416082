import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const SliderWrapper = styled.div`
  & .slick-dots {
    bottom: -62px;
  }
  & .slick-dots li button:before {
    color: ${COLORS.GRAY_300};
    font-size: 8px;
  }
  & .slick-dots li.slick-active button:before {
    color: ${COLORS.GRAY_300};
  }
`;
