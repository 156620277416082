import { saveLog } from 'utils/logToStorage';

import { axiosMultisenderInstance } from '../base';
import { ICurrency } from '../wallets/types';

import {
  normalizeExport,
  normalizeMultisenderCurrencies,
  normalizeMultisenderHistory,
  normalizeMultisenderTransactions,
  normalizeWalletInfo,
  normalizeWalletsValidation,
} from './normalizers';
import {
  IGetMultisenderWithdrawalInfo,
  IGetMultisenderWithdrawalInfoPayload,
  IMultisenderTransactionDataResult,
  IPreparedMultisenderTransaction,
  IPreparedMultisenderWithdrawalTransaction,
  IPrepareMultisenderTransactionPayload,
  IPrepareWithdrawalToWallet,
  ISendMultisenderTransactionPayload,
  ISendWithdrawalToWalletTransactionPayload,
} from './types';
import {
  IExport,
  IGetSystemWalletInfoResponse,
  IGetSystemWalletInfoResult,
  IMultisenderWithdrawal,
  IValidateWalletsPayload,
  IValidateWalletsResult,
} from './types';

const multisenderAPI = {
  async exportWalletsCSVExample(): Promise<IExport> {
    const result = await axiosMultisenderInstance.get(
      '/wallets/withdrawal/validate/example'
    );

    return normalizeExport(result.data);
  },

  async validateForWithdrawal({
    currency,
    csvFile,
  }: IValidateWalletsPayload): Promise<IValidateWalletsResult> {
    const result = await axiosMultisenderInstance.post<IValidateWalletsResult>(
      `wallets/withdrawal/validate/${currency}`,
      csvFile
    );

    return normalizeWalletsValidation(result.data);
  },

  async getSystemWalletInfo(currencySymbol: string): Promise<IGetSystemWalletInfoResult> {
    const result = await axiosMultisenderInstance.get<IGetSystemWalletInfoResponse>(
      `/wallets/wallet`,
      {
        params: {
          ...(currencySymbol && { currencySymbol }),
        },
      }
    );

    return normalizeWalletInfo(result.data);
  },

  async getMultisenderHistory(currencySymbol: string): Promise<IMultisenderWithdrawal[]> {
    const response = await axiosMultisenderInstance.get(`/wallets/withdrawals`, {
      params: {
        ...(!!currencySymbol && { currencySymbol }),
      },
    });
    return normalizeMultisenderHistory(response.data);
  },

  async getMultisenderTransactions(
    id: string
  ): Promise<IMultisenderTransactionDataResult> {
    const response = await axiosMultisenderInstance.get(`/wallets/transactions/${id}`);
    return normalizeMultisenderTransactions(response.data);
  },

  async getWithdrawalToWalletTransaction({
    walletAddress,
    currencySymbol,
    currencyType,
    wallets,
  }: IPrepareWithdrawalToWallet): Promise<IPreparedMultisenderWithdrawalTransaction> {
    const params = { currencySymbol };

    const result = await axiosMultisenderInstance.post(
      `/wallets/transactions/${currencyType}/prepare/remains/${walletAddress}`,
      { wallets },
      { params }
    );

    saveLog(result?.request?.responseURL || '');

    return result.data;
  },

  async sendWithdrawalToWalletTransaction(
    payload: ISendWithdrawalToWalletTransactionPayload
  ): Promise<void> {
    const { currencyType, transactions, currencySymbol } = payload;
    const params = { currencySymbol };

    await axiosMultisenderInstance.post(
      `wallets/transactions/remains/${currencyType}/send`,
      { transactions },
      { params }
    );
  },

  async getWithdrawalInfo({
    activityId,
    currencySymbol,
  }: IGetMultisenderWithdrawalInfoPayload): Promise<IGetMultisenderWithdrawalInfo> {
    const params = { currencySymbol };

    const result = await axiosMultisenderInstance.get(
      `wallets/withdrawal/retry/info/${activityId}`,
      { params }
    );

    return result.data;
  },

  async getWithdrawalTransaction({
    currencyType,
    activityId,
    pageNumber,
  }: IPrepareMultisenderTransactionPayload): Promise<IPreparedMultisenderTransaction> {
    const params = { pageNumber };

    const result = await axiosMultisenderInstance.get(
      `wallets/transactions/${currencyType}/prepare/${activityId}`,
      { params }
    );

    saveLog(result?.request?.responseURL || '');

    return result.data;
  },

  async sendWithdrawalTransaction(
    payload: ISendMultisenderTransactionPayload
  ): Promise<void> {
    const { currencyType, activityId, transactions, seedId, currencySymbol } = payload;
    const params = { currencySymbol };

    await axiosMultisenderInstance.post(
      `wallets/transactions/${currencyType}/send/${activityId}`,
      { transactions, seedId },
      {
        timeout: 30 * 1000 * 60,
        params,
      }
    );
  },

  async getMultisenderCurrencies(): Promise<ICurrency[]> {
    const result = await axiosMultisenderInstance.get(`wallets/currencies`);
    return normalizeMultisenderCurrencies(result.data);
  },
};

export default multisenderAPI;
