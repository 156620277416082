import React from 'react';

import { CURRENCIES } from 'api/wallets/constants';
import { ModalContainer } from 'components';

import {
  AvailableIcon,
  SAmount,
  SAmountContainer,
  SWarningIcon,
} from '../WithdrawalDetails/styled';

import { SFeeIcon, SInfoContainer, SInfoDesContainer, SMessage } from './styled';

export interface IWithdrawalModalProps {
  onClose: () => void;
  onConfirmClick: () => void;
  title: string;
  currency: CURRENCIES;
  ethCryptoFee?: number;
  ethUSDfee?: number;
  cryptoTransactionFee?: number;
  transactionFeeUSD?: number;
  inputsLimit?: number;
  feeCurrencySymbol?: CURRENCIES;
  cryptoAmountToWithdraw?: number;
  inputsCount?: number;
}

const ModalInfo = ({
  onClose,
  onConfirmClick,
  title,
  cryptoAmountToWithdraw,
  currency,
  ethCryptoFee,
  inputsCount,
  feeCurrencySymbol,
  ethUSDfee,
  cryptoTransactionFee,
  transactionFeeUSD,
  inputsLimit,
}: IWithdrawalModalProps): React.ReactElement => {
  return (
    <ModalContainer
      title={title}
      rightBtnText='Next'
      rightBtnVariant='primary'
      onDismiss={onClose}
      onLeftAction={onClose}
      onRightAction={onConfirmClick}
    >
      <SAmountContainer>
        <AvailableIcon />
        <SAmount>{`${cryptoAmountToWithdraw} ${currency}`}</SAmount>
        <SMessage>{`available from ${inputsCount} inputs`}</SMessage>
        {[CURRENCIES.ETH].includes(currency) && (
          <SMessage>
            {`Fee per transaction is: ${ethCryptoFee} ${
              feeCurrencySymbol || currency
            } = ${ethUSDfee} USD`}
          </SMessage>
        )}
      </SAmountContainer>
      <SInfoContainer>
        <SFeeIcon />
        <SInfoDesContainer>
          <SMessage>Total transaction fee is:</SMessage>
          <SMessage>
            {`${cryptoTransactionFee} ${feeCurrencySymbol || currency} = `}
            <span>{`${transactionFeeUSD} USD`}</span>
          </SMessage>
        </SInfoDesContainer>
      </SInfoContainer>

      {[CURRENCIES.BTC, CURRENCIES.BTCTEST].includes(currency) &&
        inputsCount &&
        inputsLimit &&
        inputsCount > inputsLimit && (
          <SInfoContainer>
            <SWarningIcon />
            <SInfoDesContainer>
              <SMessage>{`Withdrawal is possible only for ${inputsLimit}`}</SMessage>
              <SMessage>
                {`inputs per one and will be done  ${Math.ceil(
                  inputsCount / inputsLimit
                )}`}
              </SMessage>
            </SInfoDesContainer>
          </SInfoContainer>
        )}
    </ModalContainer>
  );
};

export default ModalInfo;
