import React from 'react';

import { appReactMemo } from 'hocs';

import { RefreshIcon, StyledButton } from './styled';

interface IRefreshBtnProps {
  onUpdate?: () => void;
  isError?: boolean;
  tooltipText?: string;
  debounce?: number;
  disableDebounce?: boolean;
  fullAnimationTimeMs?: number;
  isUpdating?: boolean;
  color?: string;
  size?: number;
}

const RefreshButton = ({
  onUpdate,
  isError = false,
  debounce = 60 * 1000,
  disableDebounce = false,
  fullAnimationTimeMs = 750,
  isUpdating = false,
  color,
  size,
}: IRefreshBtnProps) => {
  const [isClicked, setIsClicked] = React.useState(false);
  const disabled = isClicked && !isUpdating && !isError;

  const onClick = React.useCallback(() => {
    if (disabled) {
      return;
    }
    if (!disableDebounce) {
      setIsClicked(true);
    }
    onUpdate?.();
  }, [onUpdate, disabled]);

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isClicked && !disableDebounce) {
      timeout = setTimeout(() => {
        setIsClicked(false);
      }, debounce);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isClicked, debounce, disableDebounce]);

  return (
    <StyledButton
      disabled={disabled}
      isError={isError && !isUpdating}
      onClick={onClick}
      color={color}
      size={size}
    >
      <RefreshIcon $isUpdating={isUpdating} $fullAnimationTimeMs={fullAnimationTimeMs} />
    </StyledButton>
  );
};

export default appReactMemo(RefreshButton);
