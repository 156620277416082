import BIP32Factory, { BIP32Interface } from 'bip32';
import { networks, Psbt } from 'bitcoinjs-lib';
// eslint-disable-next-line
// @ts-ignore
import * as ecc from 'tiny-secp256k1';

import { BTCNetwork, IPreparedTransactionBTC } from 'api/wallets/types';

export default class HdTx {
  private unsignedTx: IPreparedTransactionBTC;

  private transaction: Psbt;

  private network: BTCNetwork;

  private index;

  constructor(
    unsignedTx: IPreparedTransactionBTC,
    network: BTCNetwork = 'bitcoin',
    index = 1
  ) {
    this.unsignedTx = unsignedTx;
    this.network = network;
    this.index = index;
    this.transaction = new Psbt({ network: networks[network] });
  }

  private addInputs(hdRoot: BIP32Interface) {
    const inputsToSign: { index: number; privateKey: BIP32Interface }[] = [];
    let index = 0;
    this.unsignedTx.hdWallets.forEach((hdWallet) => {
      const leafNode = hdRoot
        .deriveHardened(44)
        .deriveHardened(this.index)
        .deriveHardened(0)
        .derive(hdWallet.firstIndexToDerive)
        .derive(hdWallet.secondIndexToDerive);
      hdWallet.inputs.forEach((hdWalletInput) => {
        this.transaction.addInput({
          index: hdWalletInput.outputIndex,
          hash: hdWalletInput.hash,
          nonWitnessUtxo: Buffer.from(hdWalletInput.rawHex, 'hex'),
        });
        inputsToSign.push({
          privateKey: leafNode,
          index,
        });
        index += 1;
      });
    });
    return {
      inputsToSign,
      transaction: this.transaction,
    };
  }

  private addOutputs() {
    this.unsignedTx.walletsToWithdraw.forEach((walletToWithdraw) => {
      this.transaction.addOutput({
        address: walletToWithdraw.address,
        value: Number(walletToWithdraw.amountToSend),
      });
    });
  }

  generateTx(seed: string): string {
    const bip32 = BIP32Factory(ecc);
    const hdRoot = bip32.fromSeed(Buffer.from(seed, 'hex'), networks[this.network]);
    this.addOutputs();

    const { inputsToSign } = this.addInputs(hdRoot);
    inputsToSign.forEach((inputToSign) => {
      this.transaction.signInput(inputToSign.index, inputToSign.privateKey);
    });

    this.transaction.finalizeAllInputs();
    const completedTx = this.transaction.extractTransaction();
    const txHash = completedTx.toHex();
    return txHash;
  }
}
