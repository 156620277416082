import React from 'react';

import { IAppliedFilter } from '@private/components';
import { ColumnsSettingsButton, ICustomTopToolbarProps } from '@private/data-grid';
import { useUpdateEffect } from '@private/hooks';

import { Checkbox } from 'components/Controls';
import { ICONS_MAP } from 'constants/icons';
import { useSaveInURL } from 'hooks';
import { IAllPageFilters } from 'utils/types';

import {
  Container,
  StyledFilterButton,
  StyledFiltersButton,
  StyledInputSearch,
  StyledRecordsPerPage,
  StyledSettings,
  TopToolbarLeft,
  TopToolbarRight,
} from './styled';

interface ITopToolbar<T> extends ICustomTopToolbarProps<T> {
  data: T[];
  searchPlaceholder: string;
  hidePagination?: boolean;
  topToolbarChildren?: React.ReactNode;
  isWithBalance?: boolean;
  globalSearchTerm?: string;
  initialFilters?: IAppliedFilter[];
  balanceFilterLabelText?: string;
  onBalanceFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCustomFiltersChange?: (appliedFilters: IAppliedFilter[]) => void;
  onChangeSearchTerm?: (value: string) => void;
  onPageChange?: (page: number) => void;
}

const TopToolbar = <T,>({
  searchPlaceholder,
  hidePagination,
  topToolbarChildren,
  filters,
  pagination,
  columnsSettings,
  searchFilter,
  isWithBalance,
  globalSearchTerm,
  initialFilters,
  onBalanceFilterChange,
  onChangeSearchTerm,
  onCustomFiltersChange,
  balanceFilterLabelText = 'Hide Zero balances',
}: ITopToolbar<T>): React.ReactElement => {
  const { savedData, onSave } = useSaveInURL<IAllPageFilters>('pageFilters');
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);
  const { setHiddenColumns, columns } = columnsSettings;

  useUpdateEffect(() => {
    const hiddenColumns = columns.reduce((acc: string[], item) => {
      if (!item.visible) {
        acc.push(item.dataKey.toString());
      }
      return acc;
    }, []);

    onSave({
      ...savedData,
      hiddenColumns,
    } as IAllPageFilters);
  }, [savedData, columns]);

  const onChangeInputSearch = React.useCallback(
    (value: string) => {
      onChangeSearchTerm?.(value);
      searchFilter.onChange(value);
    },
    [searchFilter, onChangeSearchTerm]
  );

  const onChangeFilters = React.useCallback(
    (appliedFilters: IAppliedFilter[]) => {
      onCustomFiltersChange?.(appliedFilters);
    },
    [filters, onCustomFiltersChange]
  );

  React.useEffect(() => {
    // set input search value from URL
    const title = (savedData?.searchQuery?.globalSearchTerm as string) || '';
    onChangeInputSearch(title);

    // set popup filters from URL
    if (initialFilters?.length) {
      const appliedFilters = initialFilters as IAppliedFilter[];
      filters?.setAppliedFilters?.(appliedFilters);
      onChangeFilters(appliedFilters);
    }

    // set hidden columns from URL
    if (savedData && savedData.hiddenColumns) {
      setHiddenColumns(savedData.hiddenColumns as (keyof T)[]);
    }
  }, []);

  const onMount = React.useCallback((node) => {
    setContainerRef(node);
  }, []);

  const onZeroBalanceChange = React.useCallback((e) => {
    onChangeInputSearch('');
    onBalanceFilterChange?.(e);
  }, []);

  return (
    <Container ref={onMount}>
      <TopToolbarLeft>
        {searchPlaceholder && (
          <StyledInputSearch
            {...searchFilter}
            {...(globalSearchTerm && { value: globalSearchTerm })}
            {...(onChangeSearchTerm && { onChange: onChangeInputSearch })}
            placeholder={searchPlaceholder}
          />
        )}
        {filters && (
          <StyledFiltersButton
            {...filters}
            onApply={onChangeFilters}
            initialFilters={initialFilters}
            portalContainer={containerRef}
            styledFilterButton={StyledFilterButton}
          />
        )}
        {onBalanceFilterChange && (
          <Checkbox
            label={balanceFilterLabelText}
            checked={isWithBalance}
            onChange={onZeroBalanceChange}
          />
        )}
      </TopToolbarLeft>
      <TopToolbarRight>
        {topToolbarChildren}
        {!hidePagination && <StyledRecordsPerPage {...pagination.perPageSelect} />}
        <ColumnsSettingsButton
          {...columnsSettings}
          settingsIcon={<ICONS_MAP.GridSettings />}
          styledButton={StyledSettings}
          portalContainer={containerRef}
        />
      </TopToolbarRight>
    </Container>
  );
};

export default TopToolbar;
