import React from 'react';
import { useIsMutating } from 'react-query';

import { useModal } from '@private/modals';

import { Input, ModalContainer } from 'components';

import { Styled2FAInput, StyledStepper, Text, Title } from './styled';

enum SECURITY_STEPS {
  OTP = 0,
  SEED = 1,
}

const TOTAL_STEPS = Object.keys(SECURITY_STEPS).length / 2;

export interface ISecurityModalConfig {
  onOtpSend: (props: {
    otp: string;
    onError: () => void;
    goSeedStep?: () => void;
  }) => Promise<void>;
  onSeedSend?: (props: { seed: string; onError: () => void }) => Promise<void>;
}

export interface ISecurityModalProps extends ISecurityModalConfig {
  onClose: () => void;
}

const SecurityModal = ({
  onOtpSend,
  onSeedSend,
  onClose,
}: ISecurityModalProps): React.ReactElement => {
  const [step, setStep] = React.useState<SECURITY_STEPS>(SECURITY_STEPS.OTP);
  const isMutating = useIsMutating();
  const [isLoading, setIsLoading] = React.useState(false);

  const [otp, setOtp] = React.useState('');
  const [hasOTPFailed, setOTPFailed] = React.useState(false);

  const [secret, setSecret] = React.useState('');
  const [hasSecretFailed, setHasSecretFailed] = React.useState(false);

  const goNext = React.useCallback(() => {
    setStep(SECURITY_STEPS.SEED);
  }, []);

  const onSecretChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSecret(event.target.value);
    },
    []
  );

  const onOtp = React.useCallback(async () => {
    const onNext = onSeedSend ? goNext : undefined;
    setIsLoading(true);

    try {
      await onOtpSend({
        otp,
        onError: () => {
          setOTPFailed(true);
        },
        goSeedStep: onNext,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    setIsLoading(false);
  }, [onOtpSend, otp]);

  const onSeed = React.useCallback(async () => {
    setIsLoading(true);
    await onSeedSend?.({
      seed: secret,
      onError: () => {
        setHasSecretFailed(true);
      },
    });
    setIsLoading(false);
  }, [onSeedSend, secret]);

  const onEnter = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== 'Enter') {
        return;
      }
      switch (step) {
        case SECURITY_STEPS.OTP:
          return onOtp();
        case SECURITY_STEPS.SEED:
        default:
          return onSeed();
      }
    },
    [onSeed, onOtp, step]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', onEnter);
    return () => {
      window.removeEventListener('keydown', onEnter);
    };
  }, [onEnter]);

  const renderCurrentStep = () => {
    switch (step) {
      case SECURITY_STEPS.SEED:
        return (
          <Input
            type='password'
            value={secret}
            error={hasSecretFailed}
            onChange={onSecretChange}
            autoComplete='new-password'
            label='Secret Key'
          />
        );
      default:
        return (
          <>
            <Title>Google Authentication</Title>
            <Text>Please complete the following verification.</Text>

            <Styled2FAInput
              required
              error={hasOTPFailed}
              onChange={setOtp}
              label='Enter 6-digits code from your Google Authenticator application:'
            />
          </>
        );
    }
  };

  const otpInvalid = step === SECURITY_STEPS.OTP && otp.length < 6;
  const secretInvalid = step === SECURITY_STEPS.SEED && !secret;
  return (
    <ModalContainer
      title='Security Verification'
      rightBtnText='Next'
      enableLoadingIndicator
      isLoading={!!isMutating}
      onRightAction={step === SECURITY_STEPS.OTP ? onOtp : onSeed}
      disableRightBtn={!!isMutating || otpInvalid || secretInvalid || isLoading}
      leftBtnText='Cancel'
      onLeftAction={onClose}
      onDismiss={onClose}
    >
      {!!onSeedSend && <StyledStepper maxSteps={TOTAL_STEPS} currentStep={step} />}

      {renderCurrentStep()}
    </ModalContainer>
  );
};

type TUseSecurityModalResult = [(config: ISecurityModalConfig) => void, () => void];

export const useSecurityModal: () => TUseSecurityModalResult = () => {
  const { closeModal, showModal } = useModal();

  const showSecurityModal = React.useCallback((config: ISecurityModalConfig) => {
    showModal(<SecurityModal {...config} onClose={closeModal} />);
  }, []);

  return [showSecurityModal, closeModal];
};

export default SecurityModal;
