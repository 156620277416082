import { useMutation, UseMutationResult } from 'react-query';

import { AxiosError } from 'axios';

import api from 'api';
import { ILoggerPayload } from 'api/logger/types';

export const useLogger = (): UseMutationResult<void, AxiosError, ILoggerPayload> => {
  return useMutation(api.logger.createLog);
};
