import React from 'react';

import { CURRENCIES } from 'api/wallets/constants';
import { RefreshButton } from 'components';
import { CURRENCIES_ICONS, ICONS_MAP } from 'constants/icons';
import formatDate, { diffByDatetime } from 'utils/date';

import {
  Container,
  CurrentBlock,
  IconWrapper,
  InfoWrapper,
  Nowrap,
  Row,
  Text,
  Title,
} from './styled';

interface ICurrencyStatistic {
  available?: number;
  pending?: number;
  updateDateTimestamp?: number;
  isUpdating?: boolean;
  isError?: boolean;
  onUpdate?: () => void;
  totalWithdrawals?: number;
  currentBlockTime?: number;
  currentBlockHeight?: number;
  blockedBalance?: number;
  currency?: CURRENCIES;
  children?: React.ReactNode;
}

const CurrencyStatistic: React.FC<ICurrencyStatistic> = ({
  available = 0,
  totalWithdrawals = 0,
  blockedBalance = 0,
  pending = 0,
  currency,
  onUpdate,
  isUpdating,
  isError,
  updateDateTimestamp,
  children,
  currentBlockTime,
  currentBlockHeight,
}) => {
  const currentBlockTimeEpoch = React.useMemo(() => {
    return (currentBlockTime || 0) * 1000;
  }, [currentBlockTime]);

  return (
    <Container>
      <div>
        {currency && <IconWrapper>{CURRENCIES_ICONS[currency]}</IconWrapper>}
        <InfoWrapper>
          <div>
            <Row>
              <ICONS_MAP.StatisticAvailable data-icon />
              <Title data-important>Available</Title>
              <Nowrap>
                <Text data-important>
                  {`${updateDateTimestamp ? available : '–'} ${currency}`}
                </Text>
                <RefreshButton
                  onUpdate={onUpdate}
                  disableDebounce
                  isUpdating={isUpdating}
                  isError={isError}
                />
              </Nowrap>
            </Row>
            <Row>
              <ICONS_MAP.StatisticBlocked data-icon />
              <Title data-important>Blocked Balance</Title>
              <Text data-important>
                {`${updateDateTimestamp ? blockedBalance : '–'} ${currency}`}
              </Text>
            </Row>
            <Row>
              <Title>Last updated:</Title>
              <Text>
                {`${
                  updateDateTimestamp
                    ? formatDate(new Date(updateDateTimestamp).toJSON())
                    : '–'
                }`}
              </Text>
            </Row>
          </div>
          <div>
            <Row>
              <ICONS_MAP.StatisticTotal data-icon />
              <Title data-important>Total Withdrawals</Title>
              <Text data-important>
                {`${updateDateTimestamp ? totalWithdrawals : '–'} ${currency}`}
              </Text>
            </Row>
            <Row>
              <ICONS_MAP.StatisticPending data-icon />
              <Title data-important>Pending Amount</Title>
              <Text data-important>
                {`${updateDateTimestamp ? pending : '–'} ${currency}`}
              </Text>
            </Row>
            <Row>
              <Title>Current block:</Title>
              <Text>
                {currentBlockHeight && currentBlockTime ? (
                  <CurrentBlock>
                    <p>{currentBlockHeight}</p>
                    <p>
                      &nbsp;(
                      {diffByDatetime(currentBlockTimeEpoch, new Date().getTime())}
                      <span>&nbsp;mins ago)</span>
                    </p>
                  </CurrentBlock>
                ) : (
                  '-'
                )}
              </Text>
            </Row>
          </div>
        </InfoWrapper>
      </div>
      {children}
    </Container>
  );
};

export default CurrencyStatistic;
