import styled from 'styled-components';

import { Button, InputCopy } from 'components';
import { COLORS } from 'constants/styles';

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const QRCodeHolder = styled.div`
  margin-top: 60px;

  display: flex;
  flex-direction: row;
  align-items: center;

  & > canvas {
    margin-right: 30px;
  }

  font-family: PT Sans;
  font-size: 15px;
  color: ${COLORS.GRAY_700};
`;

export const SecretInput = styled(InputCopy)`
  margin-top: 50px;
`;

export const NavButton = styled(Button)`
  width: 180px;
  &:nth-of-type(1) {
    padding-right: 30px;
  }
  &:nth-of-type(2) {
    padding-left: 30px;
  }
`;
