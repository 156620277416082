import React from 'react';
import {
  UseMutateFunction,
  useMutation,
  UseMutationResult,
  useQueryClient,
  UseQueryResult,
} from 'react-query';

import { AxiosError } from 'axios';

import api from 'api';
import { IExport, IExportPayload } from 'api/headquarters/types';
import { TError } from 'api/types';
import {
  IPrepareTransactionPayload,
  ISendTransactionPayload,
  ITransactionDetails,
  TPreparedTransaction,
} from 'api/wallets/types';
import { IBaseAxiosError } from 'constants/types';
import { HEADQUARTERS_KEYS } from 'queries/headquarters/constants';
import { useAppQuery } from 'queries/utils';
import { WALLETS_KEYS } from 'queries/wallets/constants';
import { downloadCSV } from 'utils/csv';
import { NOTIF_TYPES, notificationService } from 'utils/notifications';

import { TRANSACTION_KEYS } from './constants';

export const usePrepareTransaction = (): UseMutationResult<
  TPreparedTransaction,
  TError,
  IPrepareTransactionPayload
> => {
  return useMutation(api.wallets.getWithdrawalTransaction);
};

interface IUseExportTransactionsResult {
  doExportTrx: UseMutateFunction<IExport, unknown, IExportPayload, { notifID: string }>;
}

type TUseExportTransactionResponse = IExport;

export const useExportTransactions = (): IUseExportTransactionsResult => {
  const { mutate } = useMutation<
    TUseExportTransactionResponse,
    AxiosError,
    IExportPayload,
    { notifID: string }
  >(api.headquarters.exportTransactions, {
    retry: 3,
    onMutate: () => {
      const notifID = notificationService.show({
        type: NOTIF_TYPES.LOADING,
        message: 'Exporting HQ transactions...',
      });
      return { notifID };
    },
    onError: (_error, _variables, context) => {
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'Failed to export transactions',
        updateId: context?.notifID,
      });
    },
    onSuccess: (data, _variables, context) => {
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'Successfully exported transactions',
        updateId: context?.notifID,
      });
      downloadCSV(data.content, data.fileName);
    },
  });

  return {
    doExportTrx: mutate,
  };
};

interface ISendTransactionMutationProps extends ISendTransactionPayload {
  notifID: string;
  isSystem?: boolean;
}
export const useSendTransaction = (): UseMutationResult<
  void,
  AxiosError,
  ISendTransactionMutationProps
> => {
  const queryClient = useQueryClient();
  const sendTrx = React.useCallback((payload: ISendTransactionMutationProps) => {
    const { notifID: _notifID, ...rest } = payload;
    return api.wallets.sendWithdrawalTransaction(rest);
  }, []);
  return useMutation(sendTrx, {
    onMutate: ({ notifID, isSystem }) => {
      if (isSystem && notifID) {
        notificationService.show({
          type: NOTIF_TYPES.LOADING,
          message: 'Processing system transactions...',
          updateId: notifID,
        });
      }
    },
    onSuccess: (_data, { notifID, isSystem }, _context) => {
      if (isSystem) {
        notificationService.show({
          type: NOTIF_TYPES.LOADING,
          message: 'Waiting for system transactions to complete...',
          updateId: notifID,
        });
        queryClient.invalidateQueries([WALLETS_KEYS.SYSTEM_WALLET]);
      } else {
        notificationService.show({
          type: NOTIF_TYPES.SUCCESS,
          message: 'Successfully sent transaction',
          updateId: notifID,
        });
      }

      queryClient.invalidateQueries([HEADQUARTERS_KEYS.HEADQUARTERS]);
      queryClient.invalidateQueries([HEADQUARTERS_KEYS.GROUPS]);
      queryClient.invalidateQueries([WALLETS_KEYS.WITHDRAWAL_HISTORY]);
    },
    onError: (_error, { notifID }, _context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message:
          (_error as IBaseAxiosError)?.response?.data?.message || 'Transaction failed',
        updateId: notifID,
      });
    },
  });
};

export const useTransactionDetailsQuery = (
  internalID: string
): UseQueryResult<ITransactionDetails> => {
  const getTransactionDetails = React.useCallback(() => {
    return api.wallets.getTransactionDetails(internalID);
  }, [internalID]);

  return useAppQuery({
    queryKey: [TRANSACTION_KEYS.TRANSACTION_DETAILS, internalID],
    queryFn: getTransactionDetails,
  });
};
