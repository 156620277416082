import { AxiosError } from 'axios';

import { ITokenData } from 'api/auth/types';

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

interface IWaitForProps<T> {
  callback: () => Promise<T>;
  predicate: (data: T) => boolean;
  interval?: number;
  maxTimeout?: number;
  onError?: (error: unknown) => void;
}

export const waitFor = async <T>({
  callback,
  predicate,
  interval = 1000,
  maxTimeout = 10 * 60 * 1000,
  onError,
}: IWaitForProps<T>): Promise<T | undefined> => {
  const startTime = Date.now();
  let response;

  try {
    // eslint-disable-next-line no-await-in-loop, no-cond-assign
    while (!predicate((response = await callback()))) {
      if (Date.now() - startTime > maxTimeout) {
        throw new Error('Timeout error. Server is unavailable for too long');
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep(interval);
    }
  } catch (e) {
    onError?.(e);
    return;
  }
  return response;
};

export const readJWTData = (jwt: string): ITokenData | undefined => {
  const encodedDataParts = jwt.split('.');
  if (encodedDataParts.length !== 3) {
    return;
  }

  try {
    const encodedData = encodedDataParts[1];
    const decoded = window.atob(encodedData);
    const result = JSON.parse(decoded) as ITokenData;
    if (result.exp && typeof result.exp === 'number') {
      result.exp *= 1000;
    }
    if (result.iat && typeof result.iat === 'number') {
      result.iat *= 1000;
    }
    return result;
  } catch {
    return undefined;
  }
};

type TError = Record<string, unknown>;
type IParseError = AxiosError<{
  message?: string;
}>;

export const parseErrorMessage = (error?: unknown): string => {
  return (
    (error as IParseError)?.response?.data?.message ||
    ((error as TError)?.message as string) ||
    'Unknown Error'
  );
};

interface IPromiseSequenceProps<T> {
  stopOnError?: boolean;
  onError?: (error: unknown) => void;
  onSuccess?: (results: T[]) => void;
  onSettle?: (error?: unknown) => void;
}
export const promiseSequence = async <T>(
  sequence: (() => Promise<T>)[],
  { onError, onSuccess, onSettle, stopOnError = true }: IPromiseSequenceProps<T>
): Promise<void> => {
  const results: T[] = [];

  for (let i = 0; i < sequence.length; i += 1) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const result = await sequence[i]();
      results.push(result);
    } catch (e) {
      if (stopOnError) {
        onSettle?.(e);
        return onError?.(e);
      }
      onError?.(e);
    }
  }

  onSettle?.();
  return onSuccess?.(results);
};
