import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ModalProvider } from '@private/modals';
import { ToastContainer } from '@private/notifications';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { NetworkProvider } from 'hooks';
import queryClient from 'queries';
import store, { persistor } from 'reducers';
import IndexRoute from 'routes';
import ErrorBoundary from 'routes/ErrorBoundary';
import GlobalFonts from 'theme/globalFonts';
import GlobalReset from 'theme/reset';

const App = (): React.ReactElement => {
  // eslint-disable-next-line
  console.log('APP_VERSION - ', window.CONFIG.APP_VERSION);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <GlobalReset />
          <GlobalFonts />
          <ToastContainer closeButton={false} />
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <QueryClientProvider client={queryClient}>
              <NetworkProvider>
                <ReactQueryDevtools position='bottom-right' initialIsOpen={false} />
                <ModalProvider>
                  <ErrorBoundary>
                    <IndexRoute />
                  </ErrorBoundary>
                </ModalProvider>
              </NetworkProvider>
            </QueryClientProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
