import React from 'react';
import { Location } from 'react-router-dom';

import { NotFoundContent } from 'components';
import queryClient from 'queries';
import { withRouter } from 'routes/ErrorBoundary/withRouter';

import { Container, RefreshBtn } from './styled';

interface Props {
  children: React.ReactNode;
  router?: {
    location: Location;
  };
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    } as State;
  }

  public static getDerivedStateFromError(_error: unknown): State {
    return { hasError: true };
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    const { router } = this.props;
    const { hasError } = this.state;
    return (
      nextProps?.router?.location?.pathname !== router?.location?.pathname ||
      nextState.hasError !== hasError
    );
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { router } = this.props;
    if (
      prevProps?.router?.location?.pathname !== router?.location?.pathname &&
      prevState.hasError
    ) {
      this.resetError();
    }
  }

  componentWillUnmount() {
    this.resetError();
  }

  resetError = () => {
    this.setState({
      hasError: false,
    });
    queryClient.resetQueries({
      predicate: (query) => {
        return query.state.status !== 'success';
      },
    });
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Container>
          <NotFoundContent title='Something went wrong...' />
          <RefreshBtn onClick={this.resetError}>Try again</RefreshBtn>
        </Container>
      );
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
