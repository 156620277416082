import React from 'react';

import { Loader } from 'components';
import { appReactMemo } from 'hocs';

import { ICONS_MAP } from '../../../constants/icons';

import { StateFailure, StateSuccess, StyledButtonDelete } from './styled';

export interface IFileActionBtnProps {
  isSuccess: boolean;
  isFailure: boolean;
  isLoading: boolean;
  onDelete: () => void;
}

const FileActionBtn = ({
  isSuccess,
  isFailure,
  isLoading,
  onDelete,
}: IFileActionBtnProps) => {
  if (isLoading) {
    return <Loader />;
  }

  const btnDelete = React.useMemo(
    () => (
      <StyledButtonDelete
        onClick={onDelete}
        variant='icon'
        size='sm'
        startIcon={<ICONS_MAP.CloseIcon />}
      />
    ),
    [onDelete]
  );

  if (isSuccess) {
    return (
      <>
        <StateSuccess>Success</StateSuccess>
        {btnDelete}
      </>
    );
  }

  if (isFailure) {
    return (
      <>
        <StateFailure>Error</StateFailure>
        {btnDelete}
      </>
    );
  }

  return btnDelete;
};

export default appReactMemo(FileActionBtn);
