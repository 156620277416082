import React from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { appReactMemo } from 'hocs';

import logo from 'assets/imgs/logo.svg';
import logoNoText from 'assets/imgs/logoNoText.svg';

import { StyledLink, StyledLogo } from './styled';

interface ILogoProps {
  className?: string;
  noText?: boolean;
  disableLink?: boolean;
}

const Logo = ({
  className,
  noText = false,
  disableLink,
}: ILogoProps): React.ReactElement => {
  const location = useLocation();
  const isALink = !disableLink && location.pathname !== ROUTES.HEADQUARTERS.PATH;

  const LogoImg = React.useMemo(() => {
    return (
      <StyledLogo
        className={!isALink ? className : undefined}
        withoutText={noText}
        src={noText ? logoNoText : logo}
        alt='logo'
      />
    );
  }, [noText, isALink]);

  if (isALink) {
    return (
      <StyledLink className={className} to='/'>
        {LogoImg}
      </StyledLink>
    );
  }

  return LogoImg;
};

export default appReactMemo(Logo);
