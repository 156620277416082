import styled from 'styled-components';

import { COLORS, SHADOWS } from 'constants/styles';

export const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  gap: 15px;

  padding: 16px 25px 12px 95px;

  min-height: 94px;

  background: ${COLORS.GRAY_700};
  box-shadow: ${SHADOWS.SECONDARY};
  border-radius: 16px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);

  & > svg {
    width: 60px;
    height: 60px;
    display: block;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:not(:last-of-type) {
    margin-bottom: 6px;
  }

  svg[data-icon] {
    margin-right: 8px;
  }
`;

export const Title = styled.p`
  margin-right: 8px;

  font-weight: 400;
  font-size: 14px;
  color: ${COLORS.GRAY_300};

  &[data-important] {
    font-weight: 700;
  }

  white-space: nowrap;
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${COLORS.GRAY_300};

  &[data-important] {
    font-size: 18px;

    color: ${COLORS.WHITE};
  }
  letter-spacing: 0.01em;
  white-space: nowrap;
`;

export const CurrentBlock = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const Nowrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;
