import * as yup from 'yup';

export const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
};

export const signUpSchema = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Required'),
  password: yup
    .string()
    .min(8, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()_\-+={[} \]|\\:;"'<,>.?\\/])/,
      {
        message: 'Password does not meet the requirements',
      }
    ),
  confirmPassword: yup
    .string()
    .required('Required')
    .test(
      'passwords-match',
      'Passwords do not match',
      function validateConfirmPass(value) {
        return !!this.parent.password && this.parent.password === value;
      }
    ),
});
