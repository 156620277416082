import formatDate from 'utils/date';
import sortByDate from 'utils/sortByDate';

import {
  IExchangerSecrets,
  IExchangerWithdrawalLimits,
  IGetMarketsPricesResponse,
  IGetMarketsPricesResult,
  IPreparedTransactionBTCExchangerResponse,
} from '../exchanger/types';
import { IGetMarketPrice, IGetMarketPriceResult } from '../exchanger/types';

import { CURRENCIES, CURRENCIES_TYPE } from './constants';
import {
  IBlockedWalletsResponse,
  IBlockedWalletsResult,
  ICurrency,
  ICurrentBlockResult,
  IPreparedTransactionBTC,
  IPrepareWithdrawalToWalletResponse,
  IRestoreWalletResult,
  ISetting,
  ISystemTrxStatus,
  ISystemWallet,
  ISystemWalletHistoryResponse,
  ISystemWalletHistoryResult,
  ITransaction,
  ITransactionDetails,
  IValidateWithdrawalWalletsResult,
  IWalletInfo,
  IWithdrawalHistory,
  IWithdrawalWallet,
  Networks,
  TTransactionFee,
} from './types';

type TServerData = Record<string, string>;

export function normalizeCurrency(data: TServerData): ICurrency {
  return {
    currency: (data.symbol || '') as CURRENCIES,
    index: +data.index,
    network: (data.network as ICurrency['network']) || 'bitcoin',
    explorerUrl: data.explorerUrl || '',
    type: (data.type as CURRENCIES_TYPE) || CURRENCIES_TYPE.UTXO,
    systemDecimals: +data.systemDecimals,
    systemSymbol: (data.systemSymbol as CURRENCIES) || null,
    contractAddress: data.contractAddress,
    decimals: Number(data.decimals) || 2,
  };
}

export function normalizeCurrencies(data: TServerData[]): ICurrency[] {
  return data.map((item) => normalizeCurrency(item));
}

export interface IWalletsValidationResponse {
  id?: string;
  invalid: string[];
  existing: string[];
}

export function normalizeWalletsValidation(
  data: IWalletsValidationResponse
): IValidateWithdrawalWalletsResult {
  return {
    id: data.id,
    invalid: data.invalid || [],
    existing: data.existing || [],
  };
}

export function normalizeWithdrawalWallet(data: TServerData): IWithdrawalWallet {
  return {
    address: data.address || '',
    quantity: data.quantity ? +data.quantity : 0,
    isActive: !!data.isActive,
    amount: data.amount ? +data.amount : 0,
    amountFiat: {
      USD: data.amountUsd ? +data.amountUsd : 0,
      EUR: data.amountEuro ? +data.amountEuro : 0,
    },
  };
}

export type TServerTransactionData = Record<string, string> & {
  currencySymbol: CURRENCIES;
};

export function normalizeTransaction(data: TServerTransactionData): ITransaction {
  return {
    internalId: data.internalId || '',
    hash: data.hash || '',
    status: data.status || '',
    date: data.date || '',
    fee: data.fee ? +data.fee : 0,
    amount: data.amount ? +data.amount : 0,
    currency: data.currencySymbol || CURRENCIES.BTC,
    amountFiat: {
      USD: data.amountUsd ? +data.amountUsd : 0,
      EUR: data.amountEuro ? +data.amountEuro : 0,
    },
  };
}

export function normalizeWithdrawalHistory(data: TServerData): IWithdrawalHistory {
  return {
    headquarterID: data.headquarterId || '',
    totalWithdrawal: data.totalWithdrawn ? +data.totalWithdrawn : 0,
    pendingWithdrawals: data.pendingWithdrawn ? +data.pendingWithdrawn : 0,
    transactions: Array.isArray(data.transactions)
      ? data.transactions.map(normalizeTransaction)
      : [],
  };
}

export function normalizeWithdrawalSetting(
  data: Record<string, string | number>
): ISetting {
  return {
    currency: (data?.currencySymbol as CURRENCIES) || '',
    network: (data?.network as Networks) || '',
    walletsToGenerate: (data.amountToGenerate as number) || 0,
    minWithdrawalPercent: (data.minWithdrawalPercent as number) || 0,
    maxWithdrawalPercent: (data.maxWithdrawalPercent as number) || 0,
    withdrawalWalletsAmount: (data.withdrawalWalletsAmount as number) || 0,
    transactionFee: (data.transactionFee as TTransactionFee) || 'high',
  };
}

export function normalizeTransactionDetails(data: TServerData): ITransactionDetails {
  return {
    transactionId: data.transactionId || '',
    transactions: Array.isArray(data.transactions)
      ? data.transactions.map(normalizeTransaction)
      : [],
  };
}

export function normalizeSystemWallet(
  data: Record<string, string | number>
): ISystemWallet {
  return {
    currencySymbol: (data?.currencySymbol as CURRENCIES) || '',
    systemCurrencySymbol: (data?.systemCurrencySymbol as CURRENCIES) || '',
    address: (data?.address as string) || '',
    balance: (data.balance as number) || 0,
    recommendedBalance: (data.recommendedBalance as number) || 0,
    amountToDeposit: (data.amountToDeposit as number) || 0,
    walletCount: (data.walletCount as number) || 0,
    isBalanceEnough: !!data.isBalanceEnough,
    transactionsCompletedCount: (data.transactionsCompletedCount as number) || 0,
  };
}

export function normalizeSystemWallets(
  data: Record<string, string | number>[]
): ISystemWallet[] {
  return data.map((item) => normalizeSystemWallet(item));
}

export function normalizeSystemTrxStatus(data: Record<string, string>): ISystemTrxStatus {
  return {
    isCompleted: !!data.status,
  };
}

export function normalizeCurrentBlock(data: ICurrentBlockResult): ICurrentBlockResult {
  return {
    time: data.time,
    height: data.height,
  };
}

export function normalizeRestoreWalletInfo(
  data: IRestoreWalletResult
): IRestoreWalletResult {
  return {
    status: data.status,
  };
}

export function normalizeWalletInfo(data: IWalletInfo): IWalletInfo {
  return {
    address: data.address || '',
    category: data.category,
    isActive: data.isActive,
    deletedAt: data.deletedAt,
    createdAt: data.createdAt,
    deletable: data.deletable,
    isDeleted: data.isDeleted,
    brand: {
      id: data.brand.id,
      title: data.brand.title,
    },
    group: {
      id: data.group.id,
      title: data.group.title,
    },
    headquarter: {
      id: data.headquarter.id,
      title: data.headquarter.title,
    },
    currencySymbol: data.currencySymbol,
    firstIndex: data.firstIndex || 0,
    secondIndex: data.secondIndex,
  };
}

function normalizeSystemWalletHistoryItem(
  data: ISystemWalletHistoryResponse
): ISystemWalletHistoryResult {
  const currency = data.userCurrency.currency.systemSymbol;
  let removeKey;

  if (currency === CURRENCIES.TRX) {
    removeKey = 'transaction/:hash';
  } else if (currency === CURRENCIES.ETH) {
    removeKey = 'tx/:hash';
  }

  const explorerUrl = data.userCurrency.currency.explorerUrl.replace(
    removeKey,
    `address/${data.address}`
  );

  return {
    id: data.id,
    explorerUrl,
    address: data.address,
    replacedAt: data.replacedAt || '-',
    firstDerivationIndex: 0,
    secondDerivationIndex: data.derivationIndex || 0,
    currencySymbol: data.userCurrency.currency.symbol,
    systemCurrencySymbol: data.userCurrency.currency.systemSymbol,
    type: data.userCurrency.currency.type,
    balance: data.balance.systemBalance || 0,
    network: data.userCurrency.currency.network,
  };
}

export function normalizeSystemWalletHistory(
  data: ISystemWalletHistoryResponse[]
): ISystemWalletHistoryResult[] {
  const normalizedData = data
    .filter((item) => item.balance.systemBalance > 0)
    .map((item) => normalizeSystemWalletHistoryItem(item));

  return sortByDate(normalizedData as [], 'replacedAt');
}

export function normalizeWithdrawToWalletTransactions(
  data: IPrepareWithdrawalToWalletResponse[]
): IPrepareWithdrawalToWalletResponse[] {
  return data.map((item) => ({
    hex: item.hex,
    firstIndexToDerive: item.firstIndexToDerive || 0,
    secondIndexToDerive: item.secondIndexToDerive || 0,
    fee: item.fee || 0,
    feeUSD: item.feeUSD || 0,
    feeEUR: item.feeEUR || 0,
    cryptoFee: item.cryptoFee || 0,
  }));
}

export function normalizeBlockedWallets(
  data: IBlockedWalletsResponse[]
): IBlockedWalletsResult[] {
  return data.map((item) => {
    // TODO an artificial date until the backend figures out how not to bind to the date if the lock is on forever
    const dateForever = '2050-01-01T00:00:00.000Z';
    let isForever = false;

    if (dateForever === item.blockedUntil) {
      isForever = true;
    }

    const formatResponseDate = (date) => {
      return `${formatDate(date).replace(' ', 'T')}:00`;
    };

    return {
      address: item.address,
      blockedAmount: item.blockedAmount,
      blockedUntil: isForever ? null : formatResponseDate(item.blockedUntil),
      blockedAt: formatResponseDate(item.blockedAt),
      createdAt: formatResponseDate(item.createdAt),
      status: item.status,
      currencySymbol: item.currencySymbol,
      hq: item.brandInfo?.group?.headquarter?.title || '-',
      brand: item.brandInfo?.merchantName || '-',
      group: item.brandInfo?.group?.title || '-',
    };
  });
}

export function normalizeWithdrawToWalletTransaction(
  data: IPreparedTransactionBTCExchangerResponse
): IPreparedTransactionBTC {
  return {
    isExistMore: false,
    inputsCount: 0,
    inputsLimit: 0,
    totalWithdrawalInfo: {
      amountToWithdraw: data.totalWithdrawalInfo.amountToWithdraw,
      cryptoAmountToWithdraw: data.totalWithdrawalInfo.cryptoAmountToWithdraw,
      amountToWithdrawEUR: data.totalWithdrawalInfo.amountToWithdrawEUR,
      amountToWithdrawUSD: data.totalWithdrawalInfo.amountToWithdrawUSD,
      transactionFee: data.transactionFee,
      cryptoTransactionFee: data.totalWithdrawalInfo.cryptoTransactionFee,
      transactionFeeEUR: data.totalWithdrawalInfo.transactionFeeEUR,
      transactionFeeUSD: data.totalWithdrawalInfo.transactionFeeUSD,
      inputsCount: 0,
      feeCurrencySymbol: data.totalWithdrawalInfo.feeCurrencySymbol,
    },
    amountToWithdraw: data.totalWithdrawalInfo.amountToWithdraw,
    transactionFee: data.transactionFee,
    hdWallets: data.systemWallets.map((item) => ({
      address: item.address,
      firstIndexToDerive: item.firstIndexToDerive,
      secondIndexToDerive: item.secondIndexToDerive,
      inputs: item.inputs,
    })),
    walletsToWithdraw: data.wallets.map((item) => ({
      address: item.address,
      amountToSend: item.amount,
    })),
  };
}

export function normalizeWithdrawalLimits(
  data: IExchangerWithdrawalLimits[]
): IExchangerWithdrawalLimits[] {
  return data.map((item) => ({
    exchangeId: item.exchangeId,
    exchangeName: item.exchangeName,
    limits: item.limits,
  }));
}

export function normalizeExchangeSecrets(data: IExchangerSecrets[]): IExchangerSecrets[] {
  return data.map((item) => ({
    id: item.id,
    title: item.title,
    createdAt: item.createdAt,
    secrets: item.secrets,
    currencies: item.currencies,
  }));
}

export function normalizeMarketPrice(data: IGetMarketPrice): IGetMarketPriceResult {
  return {
    USDT: data.USD,
  };
}

export function normalizeMarketsPrices(
  data: IGetMarketsPricesResponse
): IGetMarketsPricesResult {
  const preparedData = {};

  data.exchanges.forEach((item) => {
    preparedData[item.title.toLowerCase()] = item.rate;
  });

  return preparedData;
}
