import styled, { keyframes } from 'styled-components';

const showAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div<{ width: number; height: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    animation: ${showAnimation} 0.75s 0s linear infinite;
  }
`;
