import { autocompleteClasses } from '@mui/material/Autocomplete';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import MUITextField, { textFieldClasses } from '@mui/material/TextField';
import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const StyledTextField = styled(MUITextField)`
  &.${textFieldClasses.root} {
    & label {
      position: relative;
      transform: none;
      font-family: inherit;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: ${COLORS.GRAY_700};
      margin-bottom: 4px;

      & span {
        color: ${COLORS.BLUE_300};
        font-size: 13px;
      }
    }

    & .${outlinedInputClasses.root} {
      min-height: 32px;
      padding: 0px 10px;
      border-radius: 5px;

      & fieldset {
        border: 1px solid ${COLORS.GRAY_300};
      }

      &:hover fieldset {
        border: 1px solid ${COLORS.BLUE_300};
      }

      &.${autocompleteClasses.focused} fieldset {
        border: 1px solid ${COLORS.BLUE_300};
      }
    }
  }
`;
