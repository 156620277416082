import {
  useMutation,
  UseMutationResult,
  useQueryClient,
  UseQueryResult,
} from 'react-query';

import { AxiosError } from 'axios';

import api from 'api';
import { IGet2FASecretResult, IValidate2FAResult } from 'api/auth/types';
import { useAppQuery } from 'queries/utils';

import { SESSION_KEYS } from './constants';
import { sessionCache, updateAuthHeader } from './utils';

export const use2FA = (): UseMutationResult<IValidate2FAResult, AxiosError, string> => {
  const queryClient = useQueryClient();

  return useMutation(api.auth.validate2FA, {
    onSuccess: (response) => {
      sessionCache.replaceStorage({ token: response.token });
      updateAuthHeader(response.token);
      queryClient.setQueryData([SESSION_KEYS.TOKEN], response.token);
      queryClient.setQueryData([SESSION_KEYS.IS_2FA_PASSED], true);
    },
  });
};

export const use2FASecret = (): UseQueryResult<IGet2FASecretResult, AxiosError> => {
  return useAppQuery({
    queryKey: [SESSION_KEYS.SECOND_FACTOR_SECRET],
    queryFn: api.auth.get2FASecret,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
