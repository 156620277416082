import React from 'react';

import { ROLES } from 'api/auth/constants';
import { Stepper } from 'components';
import { appReactMemo } from 'hocs';
import { useAuthentication } from 'queries/session';
import { use2FASecret } from 'queries/session/use2FA';

import { SECURITY_STEP_TYPES, SECURITY_STEPS } from './constants';
import { SecurityContext } from './context';
import { Container, ContentContainer, StepNumber, SubTitle, Title } from './styled';

const SecurityPage = () => {
  const { is2FAConfigured, role } = useAuthentication();
  const { data } = use2FASecret();
  const [currentStep, setStep] = React.useState(0);

  const notCompletedSteps = React.useMemo(() => {
    return SECURITY_STEPS.filter((step) => {
      const availableToRole = step.roles.includes(role as ROLES);
      const secondFactorCondition = is2FAConfigured
        ? step.type !== SECURITY_STEP_TYPES.SECOND_FACTOR
        : true;
      return availableToRole && secondFactorCondition;
    });
  }, []);

  const goForward = React.useCallback(() => {
    setStep((state) => {
      const nextStep = state + 1;
      return nextStep < notCompletedSteps.length ? nextStep : state;
    });
  }, [notCompletedSteps]);

  const goBack = React.useCallback(() => {
    setStep((state) => {
      const prevStep = state - 1;
      return prevStep >= 0 ? prevStep : state;
    });
  }, [notCompletedSteps]);

  const { title, subtitle, type, Component } = notCompletedSteps[currentStep];

  const size = type === SECURITY_STEP_TYPES.SECOND_FACTOR ? 'small' : 'big';

  const contextValue = React.useMemo(
    () => ({ goNext: goForward, goBack, secondFactorSecret: data?.secret || '' }),
    [goForward, goBack, data?.secret]
  );

  return (
    <SecurityContext.Provider value={contextValue}>
      <Container size={size}>
        <ContentContainer size={size}>
          <Title>{title}</Title>
          {!!subtitle && <SubTitle>{subtitle}</SubTitle>}
          <StepNumber>{`Step ${currentStep + 1}`}</StepNumber>
          <Stepper currentStep={currentStep} maxSteps={notCompletedSteps.length} />
        </ContentContainer>
        {Component}
      </Container>
    </SecurityContext.Provider>
  );
};

export default appReactMemo(SecurityPage);
