import React from 'react';

import { useOnclickOutside } from '@private/hooks';

import { PopoverStyled } from 'components';
import { appReactMemo } from 'hocs';

interface IPopoverComponent {
  popoverBody: React.ReactElement;
  popoverContent: React.ReactElement;
}

const Popover = ({
  popoverBody,
  popoverContent,
}: IPopoverComponent): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handlePopover = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const clickOutsideHandler = React.useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [isOpen]);

  const [_, setWrapperRef] = useOnclickOutside(clickOutsideHandler, {
    ignoreClass: [`${PopoverStyled}`],
  });

  return (
    <div ref={setWrapperRef}>
      <PopoverStyled placement='bottom-end' component={popoverBody} open={isOpen}>
        <div onClick={handlePopover}>{popoverContent}</div>
      </PopoverStyled>
    </div>
  );
};

export default appReactMemo(Popover);
