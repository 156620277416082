import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { useAuthentication } from 'queries/session';
import { SESSION_KEYS } from 'queries/session/constants';
import { useQueryData } from 'queries/utils';
import SecondFactorRoute from 'routes/UnauthorizedStack/SecondFactor';
import SecurityRoute from 'routes/UnauthorizedStack/Security';
import SignInRoute from 'routes/UnauthorizedStack/SignIn';
import SignUpRoute from 'routes/UnauthorizedStack/SignUp';

const UnauthorizedStack = (): React.ReactElement => {
  const { isAuthenticated, is2FAConfigured } = useAuthentication();
  const is2FAPassed = useQueryData<boolean>([SESSION_KEYS.IS_2FA_PASSED]);

  const DefaultRedirect = React.useMemo(() => {
    let route = ROUTES.SIGN_IN.PATH;

    if (isAuthenticated && is2FAConfigured && !is2FAPassed) {
      route = ROUTES.SECOND_FACTOR.PATH;
    } else if (isAuthenticated) {
      route = ROUTES.SECURITY.PATH;
    }

    return <Route path='*' element={<Navigate to={route} />} />;
  }, [isAuthenticated, is2FAConfigured, is2FAPassed]);

  const renderUnauthorized = React.useCallback(
    () => (
      <>
        <Route path={ROUTES.SIGN_IN.PATH} element={<SignInRoute />} />
        <Route path={ROUTES.SIGN_UP.PATH} element={<SignUpRoute />} />
      </>
    ),
    []
  );

  const renderSecuritySetup = React.useCallback(
    () => (
      <>
        <Route path={ROUTES.SECURITY.PATH} element={<SecurityRoute />} />
        <Route path={ROUTES.SECOND_FACTOR.PATH} element={<SecondFactorRoute />} />
      </>
    ),
    []
  );

  return (
    <Routes>
      {isAuthenticated ? renderSecuritySetup() : renderUnauthorized()}
      {DefaultRedirect}
    </Routes>
  );
};

export default UnauthorizedStack;
