import React from 'react';
import { useMutation, useQueryClient, UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useModal } from '@private/modals';

import api from 'api';
import { ROLES } from 'api/auth/constants';
import { IAuthState, ISignInPayload } from 'api/auth/types';
import { ROUTES } from 'constants/routes';
import { IBaseAxiosError } from 'constants/types';
import { useQueryData } from 'queries/utils';
import { readJWTData } from 'utils/common';
import { NOTIF_TYPES, notificationService } from 'utils/notifications';

import { SESSION_KEYS } from './constants';
import { logoutWithQuery, sessionCache, updateAuthHeader } from './utils';

interface IUseAuthenticationResult {
  isAuthorized: boolean;
  isAuthenticated: boolean;
  is2FAConfigured: boolean;
  isSeedSet: boolean;
  isFetching: boolean;
  isInitialized: boolean;
  user?: string;
  role?: ROLES;
  error: UseQueryResult<IAuthState>['error'];
  login: (form: ISignInPayload) => void;
  logout: () => void;
}

const useAuthentication = (): IUseAuthenticationResult => {
  const client = useQueryClient();
  const navigate = useNavigate();
  const { closeModal } = useModal();

  const isInitialized = useQueryData<boolean>(SESSION_KEYS.IS_INITIALIZED);
  const token = useQueryData<string>([SESSION_KEYS.TOKEN]);
  const is2FAPassed = useQueryData<boolean>([SESSION_KEYS.IS_2FA_PASSED]);
  const tokenData = readJWTData(token || '');

  const logout = React.useCallback(() => {
    logoutWithQuery(client);
    closeModal();
    navigate('/');
  }, [client]);

  const {
    mutate: login,
    error,
    isLoading,
  } = useMutation<IAuthState, unknown, ISignInPayload, { notifID: string }>(
    api.auth.signIn,
    {
      retry: 0,
      onMutate: () => {
        const notifID = notificationService.show({
          type: NOTIF_TYPES.LOADING,
          message: 'Signing in...',
          toastId: 'sign-in',
        });

        return { notifID };
      },
      onSuccess: (response) => {
        updateAuthHeader(response.token);

        sessionCache.replaceStorage({ token: response.token });
        client.setQueryData([SESSION_KEYS.TOKEN], response.token);

        if (response.is2FASet) {
          navigate(ROUTES.SECOND_FACTOR.PATH);
        }

        notificationService.remove('sign-in');
      },
      onError: (_error, _variables, context) => {
        notificationService.show({
          updateId: context?.notifID,
          type: NOTIF_TYPES.ERROR,
          message:
            (_error as IBaseAxiosError)?.response?.data?.message ||
            'Some error occurred while signing in',
        });
      },
    }
  );

  return {
    login,
    logout,
    isAuthenticated: !!token,
    isAuthorized: !!token && !!is2FAPassed && !!tokenData?.isSeedSet,
    is2FAConfigured: !!tokenData?.is2FASet,
    isSeedSet: !!tokenData?.isSeedSet,
    user: tokenData?.email,
    error,
    role: tokenData?.role,
    isFetching: isLoading,
    isInitialized: !!isInitialized,
  };
};

export default useAuthentication;
