import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QRCode from 'qrcode.react';

import { GOOGLE_AUTHENTICTOR } from 'constants/links';
import { appReactMemo } from 'hocs';
import { useSecurityContext } from 'routes/UnauthorizedStack/Security/context';

import { ReactComponent as AppStoreBagde } from 'assets/imgs/appStoreBadge.svg';
import { ReactComponent as GoogleAuth } from 'assets/imgs/googleAuth.svg';
import { ReactComponent as GooglePlayBadge } from 'assets/imgs/googlePlayBadge.svg';

import { Container, Text } from '../styled';

import { AppsContainer, DescriptionContainer, NextBtn, QRCodeHolder } from './styled';

const InstallPage = () => {
  const { goNext } = useSecurityContext();
  return (
    <Container>
      <div>
        <DescriptionContainer>
          <GoogleAuth />
          <Text>Download and install the Google Authenticator app</Text>
        </DescriptionContainer>

        <AppsContainer>
          <QRCodeHolder>
            <QRCode size={100} value={GOOGLE_AUTHENTICTOR.ANDROID} />
            <a
              href={GOOGLE_AUTHENTICTOR.ANDROID}
              target='_blank'
              rel='noopener noreferrer'
            >
              <GooglePlayBadge />
            </a>
          </QRCodeHolder>

          <QRCodeHolder>
            <QRCode size={100} value={GOOGLE_AUTHENTICTOR.IOS} />
            <a href={GOOGLE_AUTHENTICTOR.IOS} target='_blank' rel='noopener noreferrer'>
              <AppStoreBagde />
            </a>
          </QRCodeHolder>
        </AppsContainer>
      </div>
      <NextBtn
        variant='light'
        onClick={goNext}
        endIcon={<ChevronRightIcon />}
        iconMargin={30}
      >
        Next
      </NextBtn>
    </Container>
  );
};

export default appReactMemo(InstallPage);
