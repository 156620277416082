import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const Container = styled.div`
  height: 100%;
  background: ${COLORS.WHITE};
  padding: 12px;
  border-bottom: 1px solid ${COLORS.GRAY_200};

  color: ${COLORS.GRAY_500};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const HeaderCellBody = styled.div`
  display: flex;
  align-items: center;
`;
