import React from 'react';

import { IPreparedMultisenderTransaction } from 'api/multisender/types';
import { CURRENCIES, CURRENCIES_TYPE } from 'api/wallets/constants';
import useLoggerControl from 'hooks/useLogger';
import { usePaymentProcessingModal } from 'modals/Multisender/PaymendProcessing';
import { useWithdrawalMultisenderModal } from 'modals/Multisender/WithdrawalDetails';
import { useSecurityModal } from 'modals/SecurityModal';
import {
  usePrepareTransaction,
  usePrepareWithdrawToWalletTransaction,
  useSendRemainsToWalletTransaction,
  useSendTransaction,
} from 'queries/multisender';
import { useGetSeed } from 'queries/session/useBackup';
import { useCurrenciesQuery } from 'queries/wallets';
import { waitFor } from 'utils/common';
import { getSeedFromSecret } from 'utils/crypto/backups';
import {
  signUSDTMultisenderTransaction,
  signUSDTWithdrawalToWalletTransaction,
} from 'utils/crypto/transactions-usdt';
import { NOTIF_TYPES, notificationService } from 'utils/notifications';

import { useAppSelector } from '../useStore';

interface IStartWithdrawalProps {
  activityId?: string;
  pages?: number;
  preparedTrx?: IPreparedMultisenderTransaction;
  onRequestSend?: () => void;
  walletAddress?: string;
  wallets?: string[];
  isRemainsTransaction?: boolean;
}

interface IStartSendingTransactionProps {
  activityId?: string;
  pageNumber?: number;
  composedSeed?: string;
  errorNotifConfig: {
    type: NOTIF_TYPES;
    message: string;
    updateId: string;
  };
  notifID: string;
  onError?: () => void;
  preparedTrx?: IPreparedMultisenderTransaction;
  walletAddress?: string;
  wallets?: string[];
  isRemainsTransaction?: boolean;
}

interface IStartWithdrawalRemainsProps {
  walletAddress: string;
  wallets: string[];
}

interface TUseWithdrawalProps {
  isPreparedModal?: boolean;
  isWithoutWarning?: boolean;
  isWithoutNavigate?: boolean;
}

type TUseWithdrawalResult = {
  startWithdrawal: (data: IStartWithdrawalProps) => void;
  startWithdrawalRemains: (data: IStartWithdrawalRemainsProps) => void;
  isPreparing: boolean;
};

interface IHashMultisender {
  rawTransaction: string;
  fee: number;
  signature?: string;
  title?: string;
  parentId?: number;
  addressFrom?: string;
  addressTo?: string;
  value?: string;
}

const useWithdrawal = (data?: TUseWithdrawalProps): TUseWithdrawalResult => {
  const { sendLogData } = useLoggerControl();
  const [showModal, closeModal] = useSecurityModal();
  const [showPaymentProcessingModal] = usePaymentProcessingModal({
    isWithoutNavigate: data?.isWithoutNavigate,
    isWithoutWarning: data?.isWithoutWarning,
  });
  const [useWithdrawalModal] = useWithdrawalMultisenderModal();

  const { data: currencies } = useCurrenciesQuery();
  const currency = useAppSelector(
    (state) => state.userSettings.selectedMultisenderCurrency
  );

  const currentCurrency = React.useMemo(
    () => currencies?.find((item) => item.currency === currency),
    [currencies, currency]
  );

  const { mutateAsync: getSeed } = useGetSeed({ isMultisender: true });
  const { isLoading: isPreparing, mutateAsync: prepareTransaction } =
    usePrepareTransaction();
  const { mutateAsync: prepareWithdrawalToWalletTransaction } =
    usePrepareWithdrawToWalletTransaction();
  const { mutateAsync: sendTransaction } = useSendTransaction();
  const { mutateAsync: sendWithdrawalToWalletTransaction } =
    useSendRemainsToWalletTransaction();

  const getPreparedTransactions = React.useCallback(
    async ({
      activityId,
      pageNumber,
      seed,
      preparedTrx,
      walletAddress,
      wallets,
      isRemainsTransaction,
    }: {
      seed: string;
      wallets?: string[];
      activityId?: string;
      pageNumber?: number;
      walletAddress?: string;
      isRemainsTransaction?: boolean;
      preparedTrx?: IPreparedMultisenderTransaction;
    }) => {
      const arrayOfHashes:
        | IHashMultisender[]
        | Array<{ rawTransaction: string; signature: string }> = [];

      let transaction;

      try {
        if (isRemainsTransaction) {
          transaction = await prepareWithdrawalToWalletTransaction({
            currencySymbol: currency,
            currencyType: currentCurrency?.type || CURRENCIES_TYPE.UTXO,
            walletAddress: walletAddress || '',
            wallets: wallets || [],
          });
        } else {
          transaction =
            preparedTrx ||
            (await prepareTransaction({
              activityId: activityId || '',
              pageNumber: pageNumber || 1,
              currencyType: currentCurrency?.type || CURRENCIES_TYPE.UTXO,
            }));
        }

        // Handling ETH transactions (nonce)
        if (currentCurrency?.type === CURRENCIES_TYPE.NONCE) {
          let signedTransactions;

          if (isRemainsTransaction) {
            const castedTrx = transaction;

            castedTrx.transactions.forEach((item) => {
              signedTransactions = signUSDTWithdrawalToWalletTransaction({
                seed,
                transactions: item.rawTransactions,
                currencyIndex: currentCurrency?.index,
                firstIndexToDerive: item.systemWallet.firstIndexToDerive,
                secondIndexToDerive: item.systemWallet.secondIndexToDerive,
              });
            });
          } else {
            const castedTrx = transaction as IPreparedMultisenderTransaction;

            if (
              currentCurrency.systemSymbol?.includes(CURRENCIES.TRX) ||
              currentCurrency.currency.includes(CURRENCIES.USDT)
            ) {
              signedTransactions = signUSDTMultisenderTransaction({
                seed,
                transactions: castedTrx.preparedTransactions,
                currencyIndex: currentCurrency?.index,
                firstIndexToDerive: castedTrx.systemWallet.firstIndexToDerive,
                secondIndexToDerive: castedTrx.systemWallet.secondIndexToDerive,
              });
            }
          }

          arrayOfHashes.push(...(signedTransactions || []));
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        return { data: null, error: e?.response?.data?.message || e.message };
      }

      return {
        data: arrayOfHashes,
        error: null,
        withdrawalActivityId: transaction.withdrawalActivityId,
      };
    },
    [prepareTransaction, currentCurrency]
  );

  const startSendingTransactions = React.useCallback(
    async ({
      activityId,
      pageNumber,
      composedSeed,
      errorNotifConfig,
      notifID,
      onError,
      preparedTrx,
      walletAddress,
      wallets,
      isRemainsTransaction,
    }: IStartSendingTransactionProps) => {
      let preparedProps;

      if (isRemainsTransaction) {
        preparedProps = {
          walletAddress,
          wallets,
          seed: composedSeed,
          isRemainsTransaction,
        };
      } else {
        preparedProps = {
          activityId,
          pageNumber,
          seed: composedSeed,
          preparedTrx,
        };
      }

      const signedTransactions = await getPreparedTransactions(preparedProps);

      if (signedTransactions.error) {
        notificationService.show({
          ...errorNotifConfig,
          message: signedTransactions.error,
        });

        onError?.();

        sendLogData({
          errors: signedTransactions.error,
        });

        return;
      }

      if (!signedTransactions.data?.length) {
        notificationService.show({
          type: NOTIF_TYPES.ERROR,
          message: 'Please top up your wallet',
          updateId: notifID,
        });

        return;
      }

      if (isRemainsTransaction) {
        closeModal();

        try {
          await sendWithdrawalToWalletTransaction({
            currencySymbol: currency,
            seedId: composedSeed,
            transactions: signedTransactions.data,
            currencyType: currentCurrency?.type || CURRENCIES_TYPE.UTXO,
          });
        } catch {
          notificationService.show({
            ...errorNotifConfig,
            message: 'Withdrawal failed',
            updateId: notifID,
          });

          return undefined;
        }

        notificationService.show({
          type: NOTIF_TYPES.SUCCESS,
          message: 'The withdrawal is successful',
          updateId: notifID,
        });
      } else {
        try {
          // withdrawalActivityId if resend failed transactions
          await sendTransaction({
            notifID: notifID || '',
            activityId: signedTransactions.withdrawalActivityId || activityId,
            pageNumber: pageNumber || 1,
            currencySymbol: currency,
            currencyType: currentCurrency?.type || CURRENCIES_TYPE.UTXO,
            seedId: composedSeed || '',
            transactions: signedTransactions.data,
          });
        } catch {
          return undefined;
        }
      }
    },
    []
  );

  const startSecurityVerification = React.useCallback(
    ({
      activityId,
      pages,
      preparedTrx,
      onRequestSend,
      walletAddress,
      wallets,
      isRemainsTransaction,
    }: IStartWithdrawalProps) => {
      let seedRemaining = '';

      showModal({
        onOtpSend: async ({ otp, onError, goSeedStep }) => {
          const response = await getSeed(
            { otp },
            {
              onError,
              onSuccess: goSeedStep,
            }
          );

          seedRemaining = response.seed;
        },
        onSeedSend: async ({ seed, onError }) => {
          const notifID = notificationService.show({
            type: NOTIF_TYPES.LOADING,
            message: 'Processing transaction',
          });

          const errorNotifConfig = {
            type: NOTIF_TYPES.ERROR,
            message: 'Transaction failed',
            updateId: notifID,
          };

          const composedSeed = getSeedFromSecret(seed, seedRemaining);

          if (isRemainsTransaction) {
            startSendingTransactions({
              composedSeed,
              errorNotifConfig,
              notifID,
              onError,
              wallets,
              walletAddress,
              isRemainsTransaction,
            });
          } else {
            let pageNumber = 0;

            showPaymentProcessingModal();
            onRequestSend?.();

            await waitFor({
              callback: () => {
                pageNumber += 1;

                return startSendingTransactions({
                  activityId,
                  pageNumber,
                  composedSeed,
                  errorNotifConfig,
                  notifID,
                  onError,
                  preparedTrx,
                });
              },
              predicate: () => pageNumber >= (pages || 1),
            });

            notificationService.remove(notifID);
          }
        },
      });
    },
    [getPreparedTransactions]
  );

  const startWithdrawal = React.useCallback(
    async ({ activityId, pages, onRequestSend }: IStartWithdrawalProps) => {
      if (data?.isPreparedModal) {
        useWithdrawalModal({
          onConfirm: (preparedTrx) =>
            startSecurityVerification({
              activityId,
              pages,
              preparedTrx,
            }),
          currencyType: currentCurrency?.type || CURRENCIES_TYPE.UTXO,
          currency,
          activityId: activityId || '',
          pages: pages || 1,
        });
      } else {
        startSecurityVerification({ activityId, pages, onRequestSend });
      }
    },
    [startSecurityVerification, currency, currentCurrency]
  );

  const startWithdrawalRemains = React.useCallback(
    async ({ walletAddress, wallets }: IStartWithdrawalRemainsProps) => {
      startSecurityVerification({
        walletAddress,
        wallets,
        isRemainsTransaction: true,
      });
    },
    [currency, currentCurrency]
  );

  return {
    startWithdrawal,
    startWithdrawalRemains,
    isPreparing,
  };
};

export default useWithdrawal;
