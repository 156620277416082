import React from 'react';

import { IButtonProps } from '@private/components';
import { Tooltip } from '@private/components';

import { appReactMemo } from 'hocs';

import { StyledButton } from './styled';

interface IProps extends IButtonProps {
  tooltip?: string;
  tooltipOnClick?: boolean;
  closeIn?: number;
}

const Button = React.forwardRef<HTMLButtonElement, IProps>(
  ({ tooltip = '', tooltipOnClick, closeIn, ...rest }, ref) => {
    return (
      <Tooltip
        showOnClick={tooltipOnClick}
        disabled={rest.disabled}
        positionGap={4}
        closeIn={closeIn}
        component={tooltip}
      >
        <StyledButton {...rest} ref={ref} />
      </Tooltip>
    );
  }
);

export default appReactMemo(Button);
