// eslint disabled for not to describe state types
/* eslint-disable */
import { CURRENCIES } from 'api/wallets/constants';

export const migrations = {
  1: (state) => {
    return {
      ...state,
      userSettings: {
        selectedCurrency: CURRENCIES.BTC,
        selectedSystemCurrency: CURRENCIES.USDT,
      },
    };
  },
  2: (state) => {
    return {
      ...state,
      userSettings: {
        selectedCurrency: CURRENCIES.BTC,
        selectedSystemCurrency: CURRENCIES.USDT,
        selectedMultisenderCurrency: CURRENCIES.USDT,
      },
    };
  },
  3: (state) => {
    return {
      ...state,
      userSettings: {
        selectedCurrency: CURRENCIES.BTC,
        selectedSystemCurrency: CURRENCIES.USDT,
        selectedMultisenderCurrency: CURRENCIES.USDT,
        userState: {},
      },
    };
  },
  4: (state) => {
    return {
      ...state,
      userSettings: {
        selectedCurrency: CURRENCIES.BTC,
        selectedSystemCurrency: CURRENCIES.USDT,
        selectedMultisenderCurrency: CURRENCIES.USDT,
        selectedExchangerCurrency: CURRENCIES.BTC,
        selectedExchangerPlatformCurrency: CURRENCIES.BTC,
        selectedExchangerPlatform: {},
        userState: {},
      },
    };
  },
};
