import styled from 'styled-components';

import { Button, IndexedChip, InputCopy } from 'components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  gap: 30px;

  width: 100%;
  margin-top: 50px;
`;

export const SecretTextField = styled(InputCopy)`
  width: 300px;
`;

export const NavButton = styled(Button)`
  width: 180px;
  &:not(:first-of-type) {
    margin-left: 8px;
  }
  &:nth-of-type(2) {
    padding-left: 50px;
  }
`;

export const MnemonicContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(1fr);
  grid-template-columns: repeat(auto-fill, 157px);
  grid-gap: 8px 24px;

  width: 100%;
`;

export const MnemonicItem = styled(IndexedChip)`
  width: 100%;
`;
