import { IGetBackupSeedResult } from 'api/types';

import {
  IAuthState,
  IGet2FASecretResult,
  IGetSeedResult,
  ISignUpResult,
  IValidate2FAResult,
} from './types';

interface IAuthServerResponse {
  token: string;
  is2FASet: boolean;
  isSeedSet: boolean;
}

export function normalizeSignIn(data: IAuthServerResponse): IAuthState {
  return {
    token: data.token,
    is2FASet: data.is2FASet || false,
    isSeedSet: data.isSeedSet || false,
  };
}

export function normalizeSignUp(data: Record<string, string>): ISignUpResult {
  return {
    id: data.id.toString(),
    email: data.email,
  };
}

export function normalize2FA(data: Record<string, string>): IValidate2FAResult {
  return {
    token: data.token || '',
  };
}

export function normalize2FASecret(data: Record<string, string>): IGet2FASecretResult {
  return {
    secret: data.twoFaSecret || '',
  };
}

export function normalizeSeed(data: Record<string, string>): IGetSeedResult {
  return {
    seed: data.seed || '',
  };
}

export function normalizeBackupSeedInfo(
  data: IGetBackupSeedResult
): IGetBackupSeedResult {
  return {
    isSeedExist: data.isSeedExist,
  };
}
