import React from 'react';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AxiosError } from 'axios';

import api from 'api';
import {
  IAuthState,
  IBackupPayload,
  IGetSeedPayload,
  IGetSeedResult,
} from 'api/auth/types';
import { ROUTES } from 'constants/routes';
import { IBaseAxiosError } from 'constants/types';
import { NOTIF_TYPES, notificationService } from 'utils/notifications';

import { EXCHANGER_KEYS } from '../exchanger/constants';
import { MULTISENDER_KEYS } from '../multisender/constants';

import { SESSION_KEYS } from './constants';
import { sessionCache, updateAuthHeader } from './utils';

interface IUseSetBackup {
  isMultisender?: boolean;
  isExchanger?: boolean;
}

const useSetBackup = (
  data?: IUseSetBackup
): UseMutationResult<IAuthState, AxiosError, IBackupPayload> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isBackupForFeature = data?.isMultisender || data?.isExchanger;

  if (isBackupForFeature) {
    const setBackupData = React.useMemo(() => {
      return {
        multisender: {
          api: api.auth.setMultisenderBackup,
          queryKey: [MULTISENDER_KEYS.BACKUP_SEED],
          navigatePath: ROUTES.MULTISENDER.PATH,
        },
        exchanger: {
          api: api.auth.setExchangerBackup,
          queryKey: [EXCHANGER_KEYS.BACKUP_SEED],
          navigatePath: ROUTES.EXCHANGER.PATH,
        },
      };
    }, []);

    const backupData = React.useMemo(() => {
      if (data.isMultisender) {
        return setBackupData.multisender;
      }

      return setBackupData.exchanger;
    }, [data]);

    return useMutation(backupData.api, {
      onSuccess: () => {
        notificationService.show({
          type: NOTIF_TYPES.SUCCESS,
          message: 'Seed successfully generated',
        });

        queryClient.setQueryData(backupData.queryKey, { isSeedExist: true });
        queryClient.invalidateQueries(backupData.queryKey);

        navigate(backupData.navigatePath);
      },
      onError: (_error) => {
        let message = 'Seed already exist';

        if ((_error as IBaseAxiosError)?.response?.status === 500) {
          message = _error.message;
        }

        notificationService.show({
          type: NOTIF_TYPES.ERROR,
          message: (_error as IBaseAxiosError)?.response?.data?.message || message,
        });
      },
    });
  }

  return useMutation(api.auth.setBackup, {
    onSuccess: (response) => {
      updateAuthHeader(response.token);
      sessionCache.replaceStorage({ token: response.token });
      queryClient.setQueryData([SESSION_KEYS.TOKEN], response.token);
    },
  });
};

interface IUseGetSeedProps {
  isMultisender?: boolean;
  isExchanger?: boolean;
}

export const useGetSeed = (
  data?: IUseGetSeedProps
): UseMutationResult<IGetSeedResult, AxiosError, IGetSeedPayload> => {
  if (data?.isMultisender) {
    return useMutation(api.auth.getMultisenderSeed, {
      mutationKey: SESSION_KEYS.SEED,
    });
  }

  if (data?.isExchanger) {
    return useMutation(api.auth.getExchangerSeed, {
      mutationKey: SESSION_KEYS.SEED,
    });
  }

  return useMutation(api.auth.getSeed, {
    mutationKey: SESSION_KEYS.SEED,
  });
};

export default useSetBackup;
