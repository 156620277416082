import {
  mdiArrowLeft,
  mdiArrowRight,
  mdiCalendarMonth,
  mdiCheck,
  mdiCheckBold,
  mdiClose,
  mdiMagnify,
  mdiMenuDown,
  mdiPencil,
  mdiPlus,
} from '@mdi/js';

import { CURRENCIES } from 'api/wallets/constants';

import { ReactComponent as AccordionArrow } from 'assets/icons/accordion-arrow.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as AddWalletIcon } from 'assets/icons/addWallet.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowDown.svg';
import { ReactComponent as BlockWallet } from 'assets/icons/block-withdrawal.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as CloseBordered } from 'assets/icons/close-bordered.svg';
import { ReactComponent as BitcoinSvg } from 'assets/icons/currencies/bitcoin.svg';
import { ReactComponent as DAIIcon } from 'assets/icons/currencies/DAI.svg';
import { ReactComponent as ETHSvg } from 'assets/icons/currencies/eth.svg';
import { ReactComponent as Euros } from 'assets/icons/currencies/eur.svg';
import { ReactComponent as TrxIcon } from 'assets/icons/currencies/trx.svg';
import { ReactComponent as Dollars } from 'assets/icons/currencies/usd.svg';
import { ReactComponent as Usdc } from 'assets/icons/currencies/USDC.svg';
import { ReactComponent as UsdtIcon } from 'assets/icons/currencies/usdt.svg';
import { ReactComponent as UsdtERC20 } from 'assets/icons/currencies/USDT_ERC20.svg';
import { ReactComponent as DeactivateIcon } from 'assets/icons/deactivate.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as BinanceIcon } from 'assets/icons/exchanger/binance-lg.svg';
import { ReactComponent as BinanceIconSmall } from 'assets/icons/exchanger/binance-sm.svg';
import { ReactComponent as HuobiIcon } from 'assets/icons/exchanger/huobi-lg.svg';
import { ReactComponent as HuobiIconSmall } from 'assets/icons/exchanger/huobi-sm.svg';
import { ReactComponent as KrakenIcon } from 'assets/icons/exchanger/kraken-lg.svg';
import { ReactComponent as KrakenIconSmall } from 'assets/icons/exchanger/kraken-sm.svg';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import { ReactComponent as FailedIcon } from 'assets/icons/failed.svg';
import { ReactComponent as FeeIconSVG } from 'assets/icons/feeIcon.svg';
import { ReactComponent as File } from 'assets/icons/filetable.svg';
import { ReactComponent as GridSettingsIcon } from 'assets/icons/grid-settings.svg';
import { ReactComponent as HistoryIcon } from 'assets/icons/history.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as InfoIconSm } from 'assets/icons/info-icon.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as DefaultTools } from 'assets/icons/navBarIcons/defaultTools.svg';
import { ReactComponent as PendingIcon } from 'assets/icons/pending.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/repeat.svg';
import { ReactComponent as ReplaceWalletIcon } from 'assets/icons/replace-wallet.svg';
import { ReactComponent as ReplaceWallets } from 'assets/icons/replaceWallets.svg';
import { ReactComponent as RestoreIcon } from 'assets/icons/restore.svg';
import { ReactComponent as RoundedCloseIcon } from 'assets/icons/rounded-close.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as SignMessageIcon } from 'assets/icons/sign-message.svg';
import { ReactComponent as StatisticAvailableIcon } from 'assets/icons/statisticBanner/available.svg';
import { ReactComponent as StatisticBlockedIcon } from 'assets/icons/statisticBanner/blocked.svg';
import { ReactComponent as StatisticPendingIcon } from 'assets/icons/statisticBanner/pending.svg';
import { ReactComponent as StatisticTotalIcon } from 'assets/icons/statisticBanner/total.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as SumOfDepositToBeDoneIcon } from 'assets/icons/sumOfDepositToBeDone.svg';
import { ReactComponent as TotalWithdrawalFeeIcon } from 'assets/icons/totalWithdrawalFee.svg';
import { ReactComponent as UnblockWalletIcon } from 'assets/icons/unblock-wallet.svg';
import { ReactComponent as WarningRedIcon } from 'assets/icons/warningRed.svg';
import { ReactComponent as WarningYellowIcon } from 'assets/icons/warningYellow.svg';
import { ReactComponent as AvailableIcon } from 'assets/imgs/balance-available.svg';
import { ReactComponent as TotalIcon } from 'assets/imgs/balance-withdrawals.svg';
import { ReactComponent as SadPaper } from 'assets/imgs/sad_paper.svg';
import { ReactComponent as WalletOutline } from 'assets/imgs/wallet-outline.svg';
import { ReactComponent as WhitelistUpload } from 'assets/imgs/whitelistUpload.svg';

export const MUI_ICONS_MAP = {
  menuDown: mdiMenuDown,
  check: mdiCheck,
  done: mdiCheckBold,
  edit: mdiPencil,
  create: mdiPlus,
};

export const ICONS_MAP = {
  Bitcoin: BitcoinSvg,
  Ethereum: ETHSvg,
  ListUpload: WhitelistUpload,
  ReplaceWallets,
  Wallet: WalletOutline,
  Total: TotalIcon,
  Available: AvailableIcon,
  Success: SuccessIcon,
  Info: InfoIcon,
  Error: FailedIcon,
  Delete: DeleteIcon,
  Add: AddIcon,
  Edit: EditIcon,
  Deactivate: DeactivateIcon,
  AddWallet: AddWalletIcon,
  Export: ExportIcon,
  ReplaceWallet: ReplaceWalletIcon,
  FeeIcon: FeeIconSVG,
  Pending: PendingIcon,
  WarningRed: WarningRedIcon,
  WarningYellow: WarningYellowIcon,
  Refresh: RefreshIcon,
  TotalWithdrawalFee: TotalWithdrawalFeeIcon,
  SumOfDepositToBeDone: SumOfDepositToBeDoneIcon,
  ArrowLeft: mdiArrowLeft,
  ArrowRight: mdiArrowRight,
  GridSettings: GridSettingsIcon,
  Close: mdiClose,
  Search: mdiMagnify,
  CalendarMonth: mdiCalendarMonth,
  History: HistoryIcon,
  Repeat: RepeatIcon,
  Restore: RestoreIcon,
  Key: KeyIcon,
  Settings: SettingsIcon,
  RoundedClose: RoundedCloseIcon,
  InfoIcon: InfoIconSm,
  ArrowDown: ArrowDownIcon,
  UnblockWallet: UnblockWalletIcon,
  StatisticAvailable: StatisticAvailableIcon,
  StatisticPending: StatisticPendingIcon,
  StatisticTotal: StatisticTotalIcon,
  StatisticBlocked: StatisticBlockedIcon,
  SignMessage: SignMessageIcon,
  BlockWallet,
  DefaultTools,
  AccordionArrow,
  CloseIcon,
  SadPaper,
  File,
  Logout,
  CalendarIcon,
  SearchIcon,
  CloseBordered,
};

export const CURRENCIES_ICONS = {
  [CURRENCIES.BTC]: <BitcoinSvg />,
  [CURRENCIES.BTCTEST]: <BitcoinSvg />,
  [CURRENCIES.ETH]: <ETHSvg />,
  [CURRENCIES.ETHTEST]: <ETHSvg />,
  [CURRENCIES.DAI]: <DAIIcon />,
  [CURRENCIES.DAITEST]: <DAIIcon />,
  [CURRENCIES.USD]: <Dollars />,
  [CURRENCIES.EUR]: <Euros />,
  [CURRENCIES.USDT]: <UsdtIcon />,
  [CURRENCIES.TRX]: <TrxIcon />,
  [CURRENCIES.USDT_ERC20]: <UsdtERC20 />,
  [CURRENCIES.USDC]: <Usdc />,
};

export const EXCHANGER_ICONS = {
  BINANCE: {
    SM: <BinanceIconSmall />,
    LG: <BinanceIcon />,
  },
  KRAKEN: {
    SM: <KrakenIconSmall />,
    LG: <KrakenIcon />,
  },
  HUOBI: {
    SM: <HuobiIconSmall />,
    LG: <HuobiIcon />,
  },
};
