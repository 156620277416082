import styled from 'styled-components';

import { COLORS } from 'constants/styles';

interface ActiveTabLineProps {
  width: number | undefined;
  position: number | undefined;
}

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -24px 24px -24px;
  position: relative;
`;

export const Content = styled.div`
  height: 100%;
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

export const ActiveTabLine = styled.div<ActiveTabLineProps>`
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  bottom: 0;
  left: ${({ position }) => `${position}px`};
  background: ${COLORS.BLUE_300};
  transition: 0.3s;
  width: ${({ width }) => `${width}px`};
`;
