import React from 'react';

import { CustomButton } from './styled';

interface TabTitleProps {
  title: string;
  activeTab: boolean;
  setSelectedTab: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonRefs: (instance: HTMLButtonElement | null) => void;
}

const TabTitle: React.FC<TabTitleProps> = ({
  title,
  setSelectedTab,
  buttonRefs,
  activeTab,
}) => {
  return (
    <CustomButton
      activeTab={activeTab}
      type='button'
      onClick={setSelectedTab}
      ref={buttonRefs}
    >
      {title}
    </CustomButton>
  );
};

export default TabTitle;
