export const COLORS = {
  GRAY_800: '#858585',
  GRAY_700: '#3E6778',
  GRAY_600: '#477e94',
  GRAY_500: '#87ABB8',
  GRAY_300: '#CCDDE0',
  GRAY_150: 'rgba(62, 103, 120, 0.15)',
  GRAY_200: '#E4E7EC',
  GRAY_250: '#F1F8F9',
  GRAY_50: 'rgba(62, 103, 120, 0.05)',

  GRAY_LIGHT_500: 'rgba(196, 218, 222, 0.5)',
  GRAY_LIGHT_400: 'rgba(196, 218, 222, 0.4)',
  GRAY_LIGHT_300: 'rgba(196, 218, 222, 0.3)',
  GRAY_LIGHT_250: 'rgba(204, 221, 224, 0.2)',
  GRAY_LIGHT_200: 'rgba(196, 218, 222, 0.2)',
  GRAY_LIGHT_150: 'rgba(196, 218, 222, 0.15)',
  GRAY_LIGHT_100: 'rgba(138, 174, 187, 0.1)',
  GRAY_LIGHT_50: 'rgba(138, 174, 187, 0.05)',

  BLUE_500: '#0D6FD6',
  BLUE_400: '#058EE1',
  BLUE_300: '#0AA0FA',
  BLUE_200: '#36BDF8',
  BLUE_100: '#86E2FF',
  BLUE_50: '#9EDBFF',

  GREEN_300: '#4cd4b1',
  GREEN_400: '#42B396',
  GREEN_500: '#399e84',
  GREEN_600: '#1E4D57',
  GREEN_700: '#264349',
  GREEN_800: '#122F35',

  RED_300: '#fff0f5',
  RED_500: 'rgba(255, 121, 145, 0.5)',
  RED_600: '#FF7991',
  RED_700: '#F74F6B',
  RED_800: '#F44056',

  YELLOW_300: '#FFFDDD',
  YELLOW_350: '#efac70',
  YELLOW_400: '#F2994A',
  YELLOW_500: 'orange',
  YELLOW_600: '#EC9C00',
  YELLOW_700: `#FFBE5B`,

  WHITE: '#FFFFFF',
  WHITE_100: 'rgba(255,255,255,0.1)',
  WHITE_200: '#F1F1F1',
  WHITE_0: 'rgba(255,255,255,0)',

  LIGHT_SURFACE: 'rgba(255, 255, 255, 0.1)',
  LIGHT_SURFACE_1: 'rgba(138, 174, 187, 0.1)',
  LIGHT_SURFACE_3: 'rgba(196, 218, 222, 0.3)',
  LIGHT_SURFACE_5: 'rgba(196, 218, 222, 0.5)',
};

export enum SHADOWS {
  MAIN = '0px 2px 5px 0px rgba(135, 171, 184, 0.5)',
  SECONDARY = '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
  TERTIARY = '0px 10px 25px rgba(135, 171, 184, 0.2)',
  QUATERNARY = '0px 3px 10px rgba(62, 103, 120, 0.15)',
}

export enum GRADIENTS {
  MAIN = 'linear-gradient(135.37deg, #1E4D57 4.04%, #0C2024 99.91%)',
  SECONDARY = 'linear-gradient(135.37deg, #4F9CAD 4.04%, #0C2024 99.91%), #FFFFFF;',
  TERTIARY = 'linear-gradient(90deg, #1F95EA 0.29%, #4EBBF9 98.44%)',
  QUATERNARY = 'linear-gradient(117.47deg, #98ECE7 12.39%, #5AE3EC 77.53%)',
}
