import styled from 'styled-components';

import { Button } from 'components';

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  & > svg {
    margin: 0 25px 0 12px;
    height: 70px;
  }
`;

export const AppsContainer = styled.div`
  margin-top: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  width: 100%;
`;

export const QRCodeHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > a {
    margin-top: 8px;
  }
`;

export const NextBtn = styled(Button)`
  max-width: 180px;
  width: 100%;
  padding-left: 30px;
  align-self: flex-end;
`;
