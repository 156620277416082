import React from 'react';

import { IAppliedFilter } from '@private/components';
import debounce from 'lodash.debounce';

import { DEFAULT_SORTING } from 'constants/common';
import { useSaveInURL, useUpdateEffect } from 'hooks';
import { IAllPageFilters, TSortOrder } from 'utils/types';

interface IUsePageNavigationResult {
  pageFilters: IAllPageFilters;
  onFilterChange: (appliedFilters: IAppliedFilter[]) => void;
  onPerPageChange: (count: number) => void;
  onPageChange: (page: number) => void;
  setPageFilters: React.Dispatch<React.SetStateAction<IAllPageFilters>>;
  globalSearchTerm: string;
  onChangeSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  onSortingChange: (
    props: { orderColumn: string; orderDirection: TSortOrder } | null
  ) => void;
  onChangeHideZeroBalances: (isChecked: boolean) => void;
  onHideExchangeBanner: (isOpened: boolean) => void;
  onTabChange: (activeTab: string) => void;
}

interface IConfig {
  searchKeys?: string[];
  disableSaveUrl?: boolean;
}

const usePageNavigation = (
  initialFilters: IAllPageFilters,
  config: IConfig = {}
): IUsePageNavigationResult => {
  const { disableSaveUrl } = config;

  const { savedData: savedFilters, onSave: onSaveFilters } =
    useSaveInURL<IAllPageFilters>('pageFilters');

  const [pageFilters, setPageFilters] = React.useState<IAllPageFilters>(
    savedFilters && !disableSaveUrl ? savedFilters : initialFilters
  );

  const [globalSearchTerm, setGlobalSearchTerm] = React.useState('');

  const onQuerySearch = React.useCallback(
    () =>
      debounce(() => {
        setPageFilters((state) => ({
          ...state,
          currentPage: 1,
          searchQuery: {
            ...pageFilters.searchQuery,
            globalSearchTerm,
          },
        }));
      }, 500),
    [globalSearchTerm, pageFilters]
  );

  const onChangeFilters = React.useCallback(
    () =>
      debounce((filters: IAppliedFilter[]) => {
        setPageFilters((state) => ({
          ...state,
          searchQuery: {
            ...pageFilters.searchQuery,
            filters,
          },
          currentPage: 1,
        }));
      }, 500),
    []
  );

  const onFilterChange = onChangeFilters();
  const onSearchQuery = onQuerySearch();

  React.useEffect(() => {
    return () => {
      onFilterChange.cancel();
      onSearchQuery.cancel();
    };
  }, []);

  useUpdateEffect(() => {
    onSearchQuery();
    return () => onSearchQuery.cancel();
  }, [globalSearchTerm]);

  const changeState = React.useCallback((key, value) => {
    setPageFilters((state) => ({ ...state, [key]: value }));
  }, []);

  const onPerPageChange = React.useCallback((count) => {
    setPageFilters((state) => ({
      ...state,
      numRecordsPerPage: count,
      currentPage: 1,
    }));
  }, []);

  const onPageChange = React.useCallback((page) => {
    changeState('currentPage', page);
  }, []);

  const onSortingChange = React.useCallback((props) => {
    if (props) {
      const { orderColumn, orderDirection } = props;
      setPageFilters((state) => ({ ...state, orderColumn, orderDirection }));
    } else {
      setPageFilters((state) => ({ ...state, ...DEFAULT_SORTING }));
    }
  }, []);

  const onChangeHideZeroBalances = React.useCallback((isChecked) => {
    setPageFilters((state) => ({
      ...state,
      hideZeroBalances: isChecked,
    }));
  }, []);

  const onHideExchangeBanner = React.useCallback((isOpened) => {
    changeState('isHiddenExchangeTab', isOpened);
  }, []);

  const onTabChange = React.useCallback((tab: string) => {
    changeState('activeTab', tab);
  }, []);

  useUpdateEffect(() => {
    if (!disableSaveUrl) {
      onSaveFilters(pageFilters);
    }
  }, [pageFilters]);

  return {
    pageFilters,
    onFilterChange,
    onPerPageChange,
    onPageChange,
    setPageFilters,
    globalSearchTerm,
    onChangeSearchTerm: setGlobalSearchTerm,
    onSortingChange,
    onChangeHideZeroBalances,
    onTabChange,
    onHideExchangeBanner,
  };
};

export default usePageNavigation;
