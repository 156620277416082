import styled from 'styled-components';

import { Button } from 'components';
import { COLORS } from 'constants/styles';

export const StateSuccess = styled.span`
  padding-left: 10px;
  color: ${COLORS.GREEN_400};
  font-size: 13px;
  font-weight: bold;
  line-height: 24px;
`;

export const StateFailure = styled.span`
  padding-left: 10px;
  color: ${COLORS.RED_600};
  font-size: 16px;
  line-height: 24px;
`;

export const StyledButtonDelete = styled(Button)`
  margin-left: 10px;

  svg {
    width: 10px;
    height: 10px;
  }
`;
