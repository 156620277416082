import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const Container = styled.div<{ index?: number }>`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  background-color: ${({ index }) =>
    index && index % 2 !== 0 ? COLORS.WHITE : COLORS.GRAY_250};
  border-bottom: 1px solid ${COLORS.GRAY_200};
  overflow-x: hidden;
`;

export const FlexContainer = styled.div<{
  isClickable?: boolean;
  bold?: boolean;
  center?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 5px;
  flex-shrink: 0;
  flex-grow: 1;
  min-height: 100%;
  padding: 16px 12px;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  color: ${COLORS.GRAY_700};
`;

export const Text = styled.div`
  overflow: hidden;
  min-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
`;
