import React from 'react';

import { useField } from 'formik';

import { MultilineInput } from 'components';
import { IStyledInputProps } from 'components/Controls/MultilineInput/styled';

const FormikMultilineInput: React.FC<IStyledInputProps> = ({
  name,
  showError = true,
  ...rest
}) => {
  const [field, meta] = useField(name || '');

  return (
    <MultilineInput
      {...rest}
      {...field}
      value={field.value || ''}
      showError={showError}
      error={!!(meta.error && meta.touched && showError)}
      errorMessage={meta.error}
    />
  );
};

export default FormikMultilineInput;
