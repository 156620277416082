export default class AppCache<TStorage> {
  protected key: string;

  protected storage: typeof window.localStorage;

  constructor(cacheKey: string) {
    this.key = cacheKey;
    this.storage = window.localStorage;
  }

  getStorage(): TStorage {
    const emptyStorage = {} as TStorage;
    try {
      const storage = this.storage.getItem(this.key);
      if (!storage) {
        this.storage.setItem(this.key, JSON.stringify({}));
        return emptyStorage;
      }
      return JSON.parse(storage);
    } catch (e) {
      // eslint-disable-next-line
      console.error('ERROR [cache.ts getMainStorage]:', e);
      return emptyStorage;
    }
  }

  upsertToStorage(value: Partial<TStorage>): TStorage {
    const storage = this.getStorage();
    try {
      const updatedStorage = { ...storage, ...value };
      this.storage.setItem(this.key, JSON.stringify(updatedStorage));
      return updatedStorage;
    } catch (e) {
      // eslint-disable-next-line
      console.error('ERROR [cache.ts getItem]:', e);
      return storage;
    }
  }

  replaceStorage(value: Partial<TStorage>): void {
    try {
      this.storage.setItem(this.key, JSON.stringify(value));
    } catch (e) {
      // eslint-disable-next-line
      console.error('ERROR [cache.ts removeItem]:', e);
    }
  }

  clear(): void {
    try {
      this.storage.removeItem(this.key);
    } catch (e) {
      // eslint-disable-next-line
      console.error('ERROR [cache.ts clear]:', e);
    }
  }
}
