import React from 'react';

import { useLogger } from 'queries/logger';

export const KEY = 'log';

interface ISendLogData {
  errors: unknown;
}

interface IUseLogger {
  saveEndpoint: (data: unknown) => void;
  getEndpoint: () => string;
  sendLogData: (data: ISendLogData) => void;
  remove: () => void;
  stringify: (data: unknown) => string;
}

const useLoggerControl = (): IUseLogger => {
  const { mutate: setLogData } = useLogger();

  const saveEndpoint = React.useCallback((data: unknown) => {
    localStorage.setItem(KEY, JSON.stringify(data));
  }, []);

  const getEndpoint = React.useCallback((): string => {
    return JSON.parse(localStorage.getItem(KEY) || '');
  }, []);

  const remove = React.useCallback(() => {
    localStorage.removeItem(KEY);
  }, []);

  const stringify = React.useCallback((data: unknown): string => {
    return JSON.stringify(data);
  }, []);

  const sendLogData = React.useCallback((data) => {
    const message = stringify({
      endpoint: getEndpoint(),
      errors: data.errors,
    });

    setLogData({ message });
    remove();
  }, []);

  return {
    saveEndpoint,
    getEndpoint,
    sendLogData,
    remove,
    stringify,
  };
};

export default useLoggerControl;
