import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ROLES } from 'api/auth/constants';
import { Loader } from 'components';
import { useAuthentication, useSessionWatcher } from 'queries/session';

import { IRoutesConfigItem, ROUTES_CONFIG } from './config';
import { LoaderHolder } from './styled';

const Maintainance = React.lazy(() => import('routes/UnauthorizedStack/Maintainance'));

const getComponent = (component: React.ReactElement | React.FC): React.ReactElement => {
  if (React.isValidElement(component)) {
    return component;
  }

  const Component = component;

  return <Component />;
};

const getRoute = (
  { path, component, children, disableLazy = false }: IRoutesConfigItem,
  key: number,
  role?: ROLES
) => {
  const getRouteWithRole = (item: IRoutesConfigItem, index: number) => {
    return getRoute(item, index, role);
  };

  return (
    <Route
      key={key}
      path={path}
      element={
        disableLazy ? (
          getComponent(component)
        ) : (
          <Suspense fallback={null}>{getComponent(component)}</Suspense>
        )
      }
    >
      {!!children &&
        children
          .filter((item) => (item.roles && role ? item.roles.includes(role) : true))
          .map(getRouteWithRole)}
    </Route>
  );
};

const IndexRoute = (): React.ReactElement => {
  useSessionWatcher();
  const { isInitialized, isAuthorized, role } = useAuthentication();

  const getRouteWithRole = React.useCallback(
    (item: IRoutesConfigItem, index: number) => {
      return getRoute(item, index, role);
    },
    [role]
  );

  if (!isInitialized) {
    return (
      <LoaderHolder>
        <Loader size={100} />
      </LoaderHolder>
    );
  }

  if (global.CONFIG.MAINTENANCE_MODE) {
    return (
      <Suspense fallback={null}>
        <Routes>
          <Route
            path='*'
            element={
              <React.Suspense>
                <Maintainance />
              </React.Suspense>
            }
          />
        </Routes>
      </Suspense>
    );
  }

  return (
    <Routes>
      {ROUTES_CONFIG.filter((item) => {
        if (!isAuthorized) {
          return !item.isPrivate;
        }
        return item.isPrivate && (item.roles && role ? item.roles.includes(role) : true);
      }).map(getRouteWithRole)}
    </Routes>
  );
};

export default IndexRoute;
