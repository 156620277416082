import { appReactMemo } from 'hocs';

import { StepNumber, StepTitle, StepTitleContainer } from './styled';

export interface IStepInfo {
  stepNumber: number;
  stepTitle: string;
}

const StepInfo = ({ stepNumber, stepTitle }: IStepInfo): React.ReactElement => {
  return (
    <StepTitleContainer>
      <StepNumber data-stepnumber={stepNumber} />
      <StepTitle data-steptitle>{stepTitle}</StepTitle>
    </StepTitleContainer>
  );
};

export default appReactMemo(StepInfo);
