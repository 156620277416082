import styled from 'styled-components';

import { COLORS } from 'constants/styles';

interface Props {
  activeTab: boolean;
}

export const CustomButton = styled.button<Props>`
  background: none;
  border: none;
  color: ${({ activeTab }) => (activeTab ? `${COLORS.GRAY_700}` : `${COLORS.GRAY_500}`)};
  letter-spacing: 0.01em;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  padding: 0 0 16px 0;
  margin-left: 24px;
  margin-right: 24px;
  &::before {
    display: block;
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 278px;
    background: ${({ activeTab }) =>
      activeTab ? `${COLORS.BLUE_300}` : `${COLORS.WHITE_0}`};
    transition: width 0.3s;
    width: ${({ activeTab }) => (activeTab ? '50px' : '0')};
  }
`;
