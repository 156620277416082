interface IFormatDateOptions {
  withSecond: boolean;
}

export const getCurrentTimezone = (): string => {
  const offset = new Date().getTimezoneOffset();
  if (offset === 0) {
    return 'UTC';
  }

  const mins = offset * -1;
  const hours = Math.trunc(mins / 60);
  const minutes = mins % 60;

  let formatedHours = '';

  if (hours > 0) {
    formatedHours = `0${hours}`.slice(-2);
    formatedHours = `+${formatedHours}`;
  } else {
    formatedHours = `0${Math.abs(hours)}`.slice(-2);
    formatedHours = `-${formatedHours}`;
  }
  const formatedMinutes = `0${minutes}`.slice(-2);
  return `(UTC${formatedHours}:${formatedMinutes})`;
};

const formatDate = (date: string, customOptions?: IFormatDateOptions): string => {
  const { withSecond } = customOptions || {};

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };

  if (withSecond) {
    options.second = 'numeric';
  }

  // safari error
  // const dateWithUTCIndex = date.slice(-1).toUpperCase() === 'Z' ? date : `${date}Z`;

  return new Intl.DateTimeFormat('default', options)
    .format(new Date(date))
    .replaceAll('/', '.')
    .replace(',', '');
};

export const setDayTime = (
  originalDate: string,
  hours = 0,
  min = 0,
  sec = 0,
  ms = 0
): string => {
  const date: Date = new Date(originalDate);
  date.setUTCHours(hours, min, sec, ms);
  return date.toISOString();
};

export default formatDate;

export function diffByDatetime(dateTimeFrom: number, dateTimeTo: number): number {
  const diff = (dateTimeFrom - dateTimeTo) / 1000 / 60;
  return Math.abs(Math.round(diff));
}

/**
 * date format:
 * 01.01.0000T01:01:01
 * 01.01.0000T01:01:01.000Z
 */
export const readableDateTimeFormat = (date: string): string => {
  const [d, t] = date.split('T');
  return `${d.split('-').reverse().join('.')} ${t.split('.')[0]}`;
};

interface IGetTodayDate {
  addDayNumber?: number;
  addHourNumber?: number;
  withTime?: boolean;
}

const doubleNum = (number: number): number | string => {
  if (number < 10) {
    return `0${number}`;
  }

  return number;
};

export const getTodayDate = (props: IGetTodayDate): string => {
  const { addDayNumber = 0, addHourNumber = 0, withTime = false } = props;

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate() + addDayNumber;

  const hours = date.getHours() + addHourNumber;
  const minutes = date.getMinutes();
  // const seconds = date.getSeconds();

  const currentDate = `${year}-${doubleNum(month)}-${doubleNum(day)}`;
  const currentTime = `${doubleNum(hours)}:${doubleNum(minutes)}`;

  if (withTime) {
    return `${currentDate}T${currentTime}`;
  }

  return currentDate;
};
