import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useModal } from '@private/modals';

import { ModalContainer } from 'components';
import { ICONS_MAP } from 'constants/icons';
import { ROUTES } from 'constants/routes';

import {
  SendPaymentsMessage,
  SendPaymentsMessageContainer,
  SendPaymentsWarning,
} from './styled';

interface IShowPaymentProcessingModalProps {
  isWithoutWarning?: boolean;
  isWithoutNavigate?: boolean;
}

export interface IPaymentProcessingModalProps extends IShowPaymentProcessingModalProps {
  onClose: () => void;
}

const PaymentProcessingModal = ({
  onClose,
  isWithoutWarning,
  isWithoutNavigate,
}: IPaymentProcessingModalProps): React.ReactElement => {
  const navigate = useNavigate();

  const onConfirmClick = React.useCallback(() => {
    onClose();

    if (!isWithoutNavigate) {
      navigate(ROUTES.MULTISENDER_HISTORY.PATH);
    }
  }, []);

  const rightBtnText = React.useMemo(() => {
    if (isWithoutNavigate) {
      return 'Ok';
    }

    return 'Go to Multisender History';
  }, []);

  return (
    <ModalContainer
      title='Payments in process...'
      rightBtnText={rightBtnText}
      rightBtnVariant='primary'
      onDismiss={onClose}
      onLeftAction={onClose}
      onRightAction={onConfirmClick}
    >
      <SendPaymentsMessageContainer>
        <SendPaymentsMessage>
          <p>The payment process has started and will take some time.</p>
          <p>Track the status of payments in Multisender History.</p>
        </SendPaymentsMessage>
        {!isWithoutWarning && (
          <SendPaymentsWarning>
            <ICONS_MAP.WarningRed />
            <p>This wallet will be replaced</p>
          </SendPaymentsWarning>
        )}
      </SendPaymentsMessageContainer>
    </ModalContainer>
  );
};

type TUseWithdrawalModalResult = [() => void];

export const usePaymentProcessingModal = (
  data?: IShowPaymentProcessingModalProps
): TUseWithdrawalModalResult => {
  const { closeModal, showModal } = useModal();

  const showPaymentProcessingModal = React.useCallback(() => {
    showModal(
      <PaymentProcessingModal
        isWithoutWarning={data?.isWithoutWarning}
        isWithoutNavigate={data?.isWithoutNavigate}
        onClose={closeModal}
      />
    );
  }, []);

  return [showPaymentProcessingModal];
};

export default PaymentProcessingModal;
