import React from 'react';
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

import { AxiosError } from 'axios';

import api from 'api';
import {
  IExport,
  IGetMultisenderWithdrawalInfo,
  IGetSystemWalletInfoResult,
  IMultisenderTransactionDataResult,
  IMultisenderWithdrawal,
  IPreparedMultisenderTransaction,
  IPreparedMultisenderWithdrawalTransaction,
  IPrepareMultisenderTransactionPayload,
  IPrepareWithdrawalToWallet,
  ISendMultisenderTransactionPayload,
  ISendWithdrawalToWalletTransactionPayload,
  IValidateWalletsPayload,
  IValidateWalletsResult,
} from 'api/multisender/types';
import { IGetBackupSeedResult, TError } from 'api/types';
import { CURRENCIES } from 'api/wallets/constants';
import { ICurrency } from 'api/wallets/types';
import { IBaseAxiosError } from 'constants/types';
import { useAppSelector } from 'hooks';
import { downloadCSV } from 'utils/csv';
import { NOTIF_TYPES, notificationService } from 'utils/notifications';

import { useAppQuery } from '../utils';

import { MULTISENDER_KEYS } from './constants';

export const useMultisenderExportWalletsExample = (): UseMutationResult<
  IExport,
  AxiosError,
  unknown,
  { notifID: string }
> => {
  return useMutation(api.multisender.exportWalletsCSVExample, {
    onMutate: () => {
      const notifID = notificationService.show({
        type: NOTIF_TYPES.LOADING,
        message: 'Exporting wallets example...',
      });
      return { notifID };
    },
    onError: (_error, _variables, context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message: 'Failed to export wallets example',
        updateId: context?.notifID,
      });
    },
    onSuccess: (data, _variables, context) => {
      notificationService.show({
        type: NOTIF_TYPES.SUCCESS,
        message: 'Successfully exported wallets example',
        updateId: context?.notifID,
      });

      downloadCSV(data.content, data.fileName);
    },
  });
};

export const useWalletsValidation = (): UseMutationResult<
  IValidateWalletsResult,
  AxiosError,
  IValidateWalletsPayload
> =>
  useMutation(api.multisender.validateForWithdrawal, {
    onError: (_error, _variables) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message: 'Wrong structure of the table, check again.',
      });
    },
  });

export type TUseSystemWalletQueryConfig = UseQueryOptions<
  IGetSystemWalletInfoResult,
  AxiosError,
  IGetSystemWalletInfoResult,
  string[]
>;

export const useSystemWalletInfo = (
  currencySymbol: CURRENCIES,
  config?: TUseSystemWalletQueryConfig
): UseQueryResult<IGetSystemWalletInfoResult> => {
  const getSystemWalletInfo = React.useCallback(() => {
    return api.multisender.getSystemWalletInfo(currencySymbol);
  }, [currencySymbol]);

  return useAppQuery({
    queryKey: [MULTISENDER_KEYS.WALLET, currencySymbol],
    queryFn: getSystemWalletInfo,
    cacheTime: Infinity,
    onError: () => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message: 'Could not fetch the balance',
      });
    },
    ...config,
  });
};

export type TUseMultisenderHistoryQueryConfig = UseQueryOptions<
  IMultisenderWithdrawal[],
  AxiosError,
  IMultisenderWithdrawal[],
  string[]
>;

export const useMultisenderHistoryQuery = (payload: {
  currencySymbol: string;
  config?: TUseMultisenderHistoryQueryConfig;
}): UseQueryResult<IMultisenderWithdrawal[]> => {
  return useAppQuery({
    queryKey: [MULTISENDER_KEYS.HISTORY, payload.currencySymbol],
    queryFn: () => api.multisender.getMultisenderHistory(payload.currencySymbol),
    ...payload?.config,
  });
};

export type TUseMultisenderTransactionsQueryConfig = UseQueryOptions<
  IMultisenderTransactionDataResult,
  AxiosError,
  IMultisenderTransactionDataResult,
  string[]
>;

export const useMultisenderTransactionsQuery = (payload: {
  id: string;
  config?: TUseMultisenderTransactionsQueryConfig;
}): UseQueryResult<IMultisenderTransactionDataResult> => {
  const withdrawalId = payload.id;

  const getTransactions = React.useCallback(() => {
    return api.multisender.getMultisenderTransactions(`${withdrawalId}`);
  }, [withdrawalId]);

  return useAppQuery({
    queryKey: [MULTISENDER_KEYS.TRANSACTIONS, withdrawalId],
    queryFn: getTransactions,
    ...payload?.config,
  });
};

export const usePrepareTransaction = (): UseMutationResult<
  IPreparedMultisenderTransaction,
  TError,
  IPrepareMultisenderTransactionPayload
> => {
  return useMutation(api.multisender.getWithdrawalTransaction);
};

export type TUseRetryWithdrawalQueryConfig = UseQueryOptions<
  IGetMultisenderWithdrawalInfo,
  AxiosError,
  IGetMultisenderWithdrawalInfo,
  string[]
>;

export const useGetWithdrawalRetryInfo = (payload: {
  activityId: string;
  currencySymbol: string;
  config?: TUseRetryWithdrawalQueryConfig;
}): UseQueryResult<IGetMultisenderWithdrawalInfo> => {
  const { activityId, currencySymbol } = payload;

  const getWithdrawalInfo = React.useCallback(() => {
    return api.multisender.getWithdrawalInfo({
      activityId,
      currencySymbol: currencySymbol as CURRENCIES,
    });
  }, [activityId, currencySymbol]);

  return useAppQuery({
    queryKey: [MULTISENDER_KEYS.WITHDRAWAL_INFO, activityId],
    queryFn: getWithdrawalInfo,
    ...payload?.config,
  });
};

export const usePrepareWithdrawToWalletTransaction = (): UseMutationResult<
  IPreparedMultisenderWithdrawalTransaction,
  TError,
  IPrepareWithdrawalToWallet
> => {
  return useMutation(api.multisender.getWithdrawalToWalletTransaction);
};

interface ISendTransactionMutationProps extends ISendMultisenderTransactionPayload {
  notifID: string;
}

export const useSendTransaction = (): UseMutationResult<
  void,
  AxiosError,
  ISendTransactionMutationProps
> => {
  const queryClient = useQueryClient();
  const currency = useAppSelector(
    (state) => state.userSettings.selectedMultisenderCurrency
  );
  const sendTrx = React.useCallback((payload: ISendTransactionMutationProps) => {
    const { notifID: _notifID, ...rest } = payload;
    return api.multisender.sendWithdrawalTransaction(rest);
  }, []);

  return useMutation(sendTrx, {
    onMutate: () => {},
    onSuccess: (_data, _context) => {
      queryClient.invalidateQueries([MULTISENDER_KEYS.WALLET, currency]);
      queryClient.invalidateQueries([MULTISENDER_KEYS.HISTORY]);
      queryClient.invalidateQueries([MULTISENDER_KEYS.TRANSACTIONS]);
      queryClient.resetQueries([MULTISENDER_KEYS.WITHDRAWAL_INFO, _context.activityId]);
    },
    onError: (_error, { notifID }, _context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message:
          (_error as IBaseAxiosError)?.response?.data?.message || 'Transaction failed',
        updateId: notifID,
      });
    },
  });
};

export const useSendRemainsToWalletTransaction = (): UseMutationResult<
  void,
  AxiosError,
  ISendWithdrawalToWalletTransactionPayload
> => {
  const queryClient = useQueryClient();
  const sendTrx = React.useCallback(
    (payload: ISendWithdrawalToWalletTransactionPayload) => {
      const { ...rest } = payload;
      return api.multisender.sendWithdrawalToWalletTransaction(rest);
    },
    []
  );

  return useMutation(sendTrx, {
    onMutate: () => {},
    onSuccess: (_data, _context) => {
      queryClient.invalidateQueries([MULTISENDER_KEYS.HISTORY]);
      queryClient.invalidateQueries([MULTISENDER_KEYS.TRANSACTIONS]);
      queryClient.invalidateQueries([MULTISENDER_KEYS.WITHDRAWAL_INFO]);
    },
    onError: (_error, _context) => {
      notificationService.show({
        type: NOTIF_TYPES.ERROR,
        message:
          (_error as IBaseAxiosError)?.response?.data?.message || 'Transaction failed',
      });
    },
  });
};

export type TUseMultisenderCurrencies = UseQueryOptions<
  ICurrency[],
  AxiosError,
  ICurrency[],
  string[]
>;

export const useGetMultisenderCurrencies = (payload?: {
  config?: TUseMultisenderCurrencies;
}): UseQueryResult<ICurrency[]> => {
  const getCurrencies = React.useCallback(() => {
    return api.multisender.getMultisenderCurrencies();
  }, []);

  return useAppQuery({
    queryKey: [MULTISENDER_KEYS.CURRENCIES],
    queryFn: getCurrencies,
    ...payload?.config,
  });
};

export const useGetMultisenderBackupSeed = (): UseQueryResult<IGetBackupSeedResult> => {
  return useAppQuery({
    queryKey: [MULTISENDER_KEYS.BACKUP_SEED],
    queryFn: api.auth.getMultisenderBackupSeed,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
