import { DropzoneProps, useDropzone } from 'react-dropzone';

import { appReactMemo } from 'hocs';
import { fileConfig } from 'utils/csv';

import { BlueContent, DropzoneContent, DropzoneStyled } from './styled';

interface CustomDropzoneProps extends DropzoneProps {
  dropzoneText?: string;
}

const DropZone = ({
  onDrop,
  dropzoneText = 'Drag&Drop .csv file with the addresses list',
}: CustomDropzoneProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: fileConfig.type,
    maxSize: fileConfig.maxSize * 1024 * 1024,
  });

  return (
    <DropzoneStyled {...getRootProps()}>
      <input {...getInputProps()} />
      <DropzoneContent>
        {dropzoneText}
        <br />
        or
        <BlueContent> Upload a file</BlueContent>
      </DropzoneContent>
    </DropzoneStyled>
  );
};

export default appReactMemo(DropZone);
