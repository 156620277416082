import React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { appReactMemo } from 'hocs';
import { ellipsisText } from 'utils/ellipsisText';

import { AppLink, CopyButton, StyledLink, Text, TextWrapper } from './styled';

interface ICopyTextProps {
  text: string;
  link?: string;
  disableCopy?: boolean;
  linkType?: 'app' | 'browser';
  disableShrink?: boolean;
  shrinkStart?: number;
  shrinkEnd?: number;
  reverse?: boolean;
  className?: string;
}

const CopyText = ({
  text,
  link,
  linkType = 'app',
  disableCopy = false,
  disableShrink = false,
  shrinkStart = 5,
  shrinkEnd = 5,
  reverse = false,
  className,
}: ICopyTextProps) => {
  const onClick = React.useCallback(() => {
    navigator.clipboard.writeText(text);
  }, [text]);

  const getComponent = React.useCallback(() => {
    const displayText = disableShrink ? text : ellipsisText(text, shrinkStart, shrinkEnd);
    if (!link) {
      return <Text>{displayText}</Text>;
    }

    if (linkType === 'app') {
      return <AppLink to={link}>{displayText}</AppLink>;
    }

    const parsedLink =
      link.includes('https://') || link.includes('http://') ? link : `https://${link}`;
    return (
      <StyledLink href={parsedLink} target='_blank' rel='noopener noreferrer'>
        {displayText}
      </StyledLink>
    );
  }, [link, linkType, disableShrink, text]);

  return (
    <TextWrapper className={className} reverse={reverse}>
      {!disableCopy && (
        <CopyButton
          reverse={reverse}
          variant='iconSolid'
          tooltip='Copied'
          onClick={onClick}
          tooltipOnClick
          closeIn={200}
          startIcon={<ContentCopyIcon />}
        />
      )}
      {getComponent()}
    </TextWrapper>
  );
};

export default appReactMemo(CopyText);
