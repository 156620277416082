import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Paper, { paperClasses } from '@mui/material/Paper';
import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const StyledAutocomplete = styled(Autocomplete)`
  label {
    color: ${COLORS.GRAY_700} !important;
    font-weight: 800 !important;
  }

  & .${outlinedInputClasses.root} {
    height: 40px;
    overflow: auto;
    border: 1px solid ${COLORS.GRAY_300};
    border-radius: 8px;
  }
  & .${outlinedInputClasses.root} fieldset {
    display: none;
  }
  & .${outlinedInputClasses.root}.Mui-disabled {
    background-color: ${COLORS.GRAY_LIGHT_100};
  }
  & .${outlinedInputClasses.root} .${autocompleteClasses.input} {
    font-family: inherit;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: ${COLORS.GRAY_700};
    padding: 0px 0px 0px 0px;
  }
  & .${autocompleteClasses.clearIndicator}, .${autocompleteClasses.popupIndicator}:hover {
    background-color: transparent !important;
  }
` as typeof Autocomplete;

export const StyledCancelIcon = styled(CancelIcon)`
  color: ${COLORS.RED_600};
  font-size: 18px !important;

  &:hover {
    color: ${COLORS.RED_700};
  }
`;

export const StyledDropdownIcon = styled(ArrowDropDownIcon)`
  color: ${COLORS.GRAY_300};
`;

export const StyledPaper = styled(Paper)`
  &.${paperClasses.root} {
    box-shadow: 0px 3px 10px ${COLORS.GRAY_LIGHT_50},
      0px 3px 10px ${COLORS.GRAY_LIGHT_500};
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &.${menuItemClasses.root} {
    background-color: transparent;
    padding: 4px 0px;
    color: ${COLORS.GRAY_700};
    font-family: inherit;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
  }

  &.${autocompleteClasses.option}[aria-selected="true"] {
    background-color: transparent;
  }

  &.${autocompleteClasses.option}[aria-selected="true"]:last-child {
    background-color: transparent;
    border-bottom: 1px solid ${COLORS.GRAY_LIGHT_400};
    padding-bottom: 15px;
    margin-bottom: 10px;
  }

  label {
    margin-right: 0;
  }
`;
