import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CURRENCIES } from 'api/wallets/constants';
import { ISelectedExchangerPlatform } from 'reducers/types';

interface IUserState {
  selectedCurrency: CURRENCIES;
  selectedSystemCurrency: CURRENCIES;
  selectedMultisenderCurrency: CURRENCIES;
  selectedExchangerCurrency: CURRENCIES;
  selectedExchangerPlatformCurrency: CURRENCIES;
  selectedExchangerPlatform: ISelectedExchangerPlatform;
  userState: object;
}

const initialState: IUserState = {
  selectedCurrency: CURRENCIES.BTC,
  selectedSystemCurrency: CURRENCIES.USDT,
  selectedMultisenderCurrency: CURRENCIES.USDT,
  selectedExchangerCurrency: CURRENCIES.BTCTEST,
  selectedExchangerPlatformCurrency: CURRENCIES.BTC,
  selectedExchangerPlatform: {},
  userState: {},
};

export const userSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    selectCurrency: (state, action: PayloadAction<CURRENCIES>) => {
      state.selectedCurrency = action.payload;
    },
    selectSystemCurrency: (state, action: PayloadAction<CURRENCIES>) => {
      state.selectedSystemCurrency = action.payload;
    },
    selectMultisenderCurrency: (state, action: PayloadAction<CURRENCIES>) => {
      state.selectedMultisenderCurrency = action.payload;
    },
    selectExchangerCurrency: (state, action: PayloadAction<CURRENCIES>) => {
      state.selectedExchangerCurrency = action.payload;
    },
    selectExchangerPlatformCurrency: (state, action: PayloadAction<CURRENCIES>) => {
      state.selectedExchangerPlatformCurrency = action.payload;
    },
    selectExchangerPlatform: (
      state,
      action: PayloadAction<ISelectedExchangerPlatform>
    ) => {
      state.selectedExchangerPlatform = action.payload;
    },
    setUserState: (state, action: PayloadAction<object>) => {
      state.userState = action.payload;
    },
  },
});

export const {
  selectCurrency,
  selectSystemCurrency,
  selectMultisenderCurrency,
  selectExchangerCurrency,
  selectExchangerPlatformCurrency,
  selectExchangerPlatform,
  setUserState,
} = userSlice.actions;
