import React from 'react';

import { TextFieldProps } from '@mui/material/TextField';

import { appReactMemo } from 'hocs';

import { StyledTextField } from './styled';

const TextField = ({ ...props }: TextFieldProps): React.ReactElement => (
  <StyledTextField {...props} />
);

export default appReactMemo(TextField);
