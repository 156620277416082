import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { autocompleteSelectClasses } from '@private/components';
import styled from 'styled-components';

import { Select } from 'components';
import Button from 'components/Button';
import { COLORS, GRADIENTS } from 'constants/styles';

import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';

interface IContainerProps extends React.ComponentProps<'div'> {
  isOpen?: boolean;
}

const SIDE_BAR_WIDTH = 265;

export const Container = styled.div<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: ${({ isOpen = true }) => (isOpen ? `${SIDE_BAR_WIDTH}px` : '36px')};

  padding: 24px 16px 0 16px;

  background: ${GRADIENTS.MAIN};

  transition: 0.3s ease-in-out;
`;

export const ContentContainer = styled.div<IContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  height: 100%;
  width: ${SIDE_BAR_WIDTH}px;

  transition: 0.3s ease-out;

  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : `translateX(-${SIDE_BAR_WIDTH}px)`};
`;

interface ILinkProps extends LinkProps {
  $isActive?: boolean;
}
export const StyledLink = styled(Link)<ILinkProps>`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  gap: 8px;

  white-space: nowrap;
  overflow: hidden;

  &:nth-of-type(1) {
    margin-top: 40px;
  }

  width: 100%;
  padding: 14px 27px;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  color: ${({ $isActive }) => ($isActive ? COLORS.BLUE_400 : COLORS.GRAY_300)};
  background: ${({ $isActive }) => ($isActive ? COLORS.WHITE : 'transparent')};
`;

export const StyledSelect = styled(Select)`
  position: relative;
  margin-left: 12px;
  padding-left: 12px;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 20px;
    top: 50%;
    left: -1px;
    transform: translateY(-50%);
    background: ${COLORS.GRAY_700};
  }

  ${autocompleteSelectClasses.label} {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: ${COLORS.GRAY_300};
  }

  ${autocompleteSelectClasses.inputWrapper} {
    min-width: 165px;
    max-width: 165px;
    border: 1px solid ${COLORS.GRAY_LIGHT_400};
    background: transparent;

    &:hover {
      background: ${COLORS.GREEN_600};
    }

    &[data-is-focused='true'] {
      background: ${COLORS.GREEN_800};
    }

    &[disabled],
    &[disabled] [role='button'] {
      cursor: not-allowed;
    }
  }

  ${autocompleteSelectClasses.arrowButton} {
    color: ${COLORS.GRAY_LIGHT_400};
  }

  ${autocompleteSelectClasses.optionsWrapper} {
    background: ${COLORS.GREEN_800};
    box-shadow: 0px 4px 16px ${COLORS.GRAY_50};
    border-radius: 5px;
  }

  ${autocompleteSelectClasses.option}:hover {
    background: ${COLORS.GREEN_700};
  }

  ${autocompleteSelectClasses.checkIconWrapper} {
    color: ${COLORS.GREEN_700};
  }
`;

export const StyledLogoutBtn = styled(Button)`
  position: absolute;
  bottom: 30px;
  right: auto;
  color: ${COLORS.GRAY_300};
  width: 100%;
  justify-content: flex-start;
  padding-left: 30px;
`;

export const StyledLogoutIcon = styled(LogoutIcon)`
  margin-right: 15px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;
