import sortByDate from '../../utils/sortByDate';
import { IExport } from '../headquarters/types';
import { CURRENCIES, CURRENCIES_TYPE } from '../wallets/constants';
import { ICurrency, Networks } from '../wallets/types';

import {
  IDuplicatesAmountCounters,
  IDuplicatesCounters,
  IGetSystemWalletInfoResponse,
  IGetSystemWalletInfoResult,
  IMultisenderCurrency,
  IMultisenderTransactionDataResult,
  IMultisenderTransactionResponse,
  IMultisenderTransactionResult,
  IMultisenderTransactionsResponse,
  IMultisenderWithdrawal,
  IValidateWalletsResult,
} from './types';

type TServerData = Record<string, string>;

export function normalizeExport(data: TServerData): IExport {
  return {
    fileName: data.fileName || '',
    content: data.content || data.fileContent || '',
  };
}

export function normalizeWalletsValidation(
  data: IValidateWalletsResult
): IValidateWalletsResult {
  const duplicatesCounters: IDuplicatesCounters[] = [];
  const duplicatesAmountCounters: IDuplicatesAmountCounters[] = [];

  if (data.duplicatesCounters) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [wallet, duplicatedCount] of Object.entries(data.duplicatesCounters)) {
      duplicatesCounters.push({ wallet, duplicatedCount: Number(duplicatedCount) });
    }
  }

  if (data.duplicatesAmountCounters) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [wallet, amountData] of Object.entries(data.duplicatesAmountCounters)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [amount, duplicatedCount] of Object.entries(amountData)) {
        duplicatesAmountCounters.push({
          wallet,
          duplicatedCount: Number(duplicatedCount),
          amount: Number(amount),
        });
      }
    }
  }

  return {
    id: data.id,
    address: data.address,
    invalid: data.invalid || [],
    lackOfAmount: data.lackOfAmount,
    lackOfTokens: data.lackOfTokens,
    amount: data.amount,
    amountFee: data.amountFee,
    systemBalance: data.systemBalance,
    balance: data.balance,
    pages: data.pages,
    count: data.count,
    walletDetails: data.walletDetails,
    duplicatesCounters,
    duplicatesAmountCounters,
  };
}

export function normalizeWalletInfo(
  data: IGetSystemWalletInfoResponse
): IGetSystemWalletInfoResult {
  const info = data[0];

  return {
    balance: info.balance,
    systemBalance: info.systemBalance,
    currency: info.currency.symbol,
    systemSymbol: info.currency.systemSymbol,
  };
}

export function normalizeMultisenderHistoryItem(
  data: IMultisenderWithdrawal
): IMultisenderWithdrawal {
  return {
    id: data.id,
    createdAt: data.createdAt,
    systemWallet: data.systemWallet,
    currencySymbol: data.currencySymbol,
    successBlockchainTransactionsCount: data.successBlockchainTransactionsCount || 0,
    pendingBlockchainTransactionsCount: data.pendingBlockchainTransactionsCount || 0,
    failedBlockchainTransactionsCount: data.failedBlockchainTransactionsCount || 0,
    balance: data.balance || 0,
    systemBalance: data.systemBalance || 0,
  };
}

export function normalizeMultisenderHistory(
  data: IMultisenderWithdrawal[]
): IMultisenderWithdrawal[] {
  const normalizedData = data.map((item) => normalizeMultisenderHistoryItem(item));
  return sortByDate(normalizedData as [], 'createdAt');
}

export function normalizeMultisenderTransactionItem(
  data: IMultisenderTransactionResponse,
  currencySymbol: CURRENCIES,
  systemWallet: string
): IMultisenderTransactionResult {
  return {
    id: data.id,
    amount: data.amount,
    hash: data.hash,
    status: data.status,
    createdAt: data.createdAt,
    onAddress: data.withdrawalWalletUsages[0].address,
    title: data.withdrawalWalletUsages[0].title,
    amountUsd: data.withdrawalWalletUsages[0].amountUsd,
    currencySymbol,
    systemWallet,
  };
}

export function normalizeMultisenderTransactions(
  data: IMultisenderTransactionsResponse
): IMultisenderTransactionDataResult {
  const normalizedData = data.blockchainTransactions.map((item) =>
    normalizeMultisenderTransactionItem(
      item,
      data.withdrawalActivity.currencySymbol as CURRENCIES,
      data.withdrawalActivity.systemWallet
    )
  );

  const blockchainTransactions = sortByDate(normalizedData as [], 'createdAt');

  return {
    blockchainTransactions,
    withdrawalActivity: data.withdrawalActivity,
    balance: data.balance,
  };
}

export function normalizeMultisenderCurrency(
  currencyData: IMultisenderCurrency
): ICurrency {
  return {
    index: currencyData.index,
    network: currencyData.network as Networks,
    explorerUrl: currencyData.explorerUrl,
    type: currencyData.type as CURRENCIES_TYPE,
    systemDecimals: currencyData.systemDecimals,
    systemSymbol: currencyData.systemSymbol as CURRENCIES,
    contractAddress: currencyData.contractAddress,
    currency: currencyData.symbol as CURRENCIES,
    decimals: currencyData.decimals || 2,
  };
}

export function normalizeMultisenderCurrencies(
  data: IMultisenderCurrency[]
): ICurrency[] {
  return data.map((item) => normalizeMultisenderCurrency(item));
}
