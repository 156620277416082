import { INotificationProps as IBaseNtfProps, NOTIF_TYPES } from '@private/notifications';

import Loader from 'components/Loader';
import { ICONS_MAP } from 'constants/icons';
import { appReactMemo } from 'hocs';

import { Close, Column, Container, IconContainer, Message, Title } from './styled';

const NOTIFICATION_ICONS = {
  [NOTIF_TYPES.SUCCESS]: <ICONS_MAP.Success />,
  [NOTIF_TYPES.INFO]: <ICONS_MAP.Info />,
  [NOTIF_TYPES.ERROR]: <ICONS_MAP.Error />,
  [NOTIF_TYPES.LOADING]: <Loader size={32} />,
};

const NOTIFICATION_TITLES = {
  [NOTIF_TYPES.SUCCESS]: 'Success',
  [NOTIF_TYPES.INFO]: 'Info',
  [NOTIF_TYPES.ERROR]: 'Error',
  [NOTIF_TYPES.LOADING]: 'Loading...',
};

interface INotificationProps extends IBaseNtfProps {
  id?: string;
  closeBtn?: boolean;
}

const Notification = ({
  type = NOTIF_TYPES.INFO,
  message = '',
  onClose,
  closeBtn = true,
}: INotificationProps) => {
  return (
    <Container data-testid='notification' type={type}>
      <IconContainer>{NOTIFICATION_ICONS[type]}</IconContainer>
      {closeBtn && <Close onClick={onClose} />}

      <Column>
        <Title type={type}>{NOTIFICATION_TITLES[type]}</Title>
        <Message>{message}</Message>
      </Column>
    </Container>
  );
};

export default appReactMemo(Notification);
