import React from 'react';

import { CURRENCIES } from 'api/wallets/constants';
import { CURRENCIES_ICONS } from 'constants/icons';

import { Container, Icon, Text } from './styled';

interface ICurrencyIconProps {
  currency?: CURRENCIES;
  children?: React.ReactNode;
}

const CurrencyIcon = ({
  currency = CURRENCIES.BTC,
  children,
}: ICurrencyIconProps): React.ReactElement => {
  return (
    <Container>
      <Icon data-icon>{CURRENCIES_ICONS[currency]}</Icon>
      {children && <Text data-children>{children}</Text>}
    </Container>
  );
};

export default CurrencyIcon;
