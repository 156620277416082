import React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IInputProps } from '@private/components';

import { appReactMemo } from 'hocs';

import Input from '../Input';

const InputCopy = ({ value, ...rest }: IInputProps) => {
  const onCopy = React.useCallback(() => {
    const text = value?.toString() || '';
    navigator.clipboard.writeText(text);
  }, [value]);

  return (
    <Input
      {...rest}
      onIconClick={onCopy}
      value={value}
      tooltip='Copied'
      tooltipOnClick
      endIcon={<ContentCopyIcon />}
    />
  );
};

export default appReactMemo(InputCopy);
