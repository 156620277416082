export enum CURRENCIES {
  BTC = 'BTC',
  ETH = 'ETH',
  BTCTEST = 'BTCTEST',
  ETHTEST = 'ETHTEST',
  USDT = 'USDT',
  TRX = 'TRX',
  DAI = 'DAI',
  DAITEST = 'DAITEST',
  USD = 'USD',
  EUR = 'EUR',
  USDT_ERC20 = 'USDT_ERC20',
  USDC = 'USDC',
}

export enum CURRENCIES_TYPE {
  UTXO = 'utxo',
  NONCE = 'nonce',
}
