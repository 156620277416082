import React from 'react';

import { IInputProps } from '@private/components';
import { useField } from 'formik';

import { Input } from 'components';
import { appReactMemo } from 'hocs';

const FormikInput: React.FC<IInputProps> = ({ name = '', showError = true, ...rest }) => {
  const [field, meta] = useField(name);

  const onBlur = React.useCallback((e) => {
    field.onBlur(e);
    rest.onBlur?.(e);
  }, []);

  return (
    <Input
      {...field}
      {...rest}
      onBlur={onBlur}
      value={field.value ?? ''}
      showError={showError}
      error={!!(meta.error && meta.touched && showError)}
      errorMessage={meta.error}
    />
  );
};

export default appReactMemo(FormikInput);
