import React from 'react';

import styled from 'styled-components';

interface TabProps {
  title: string;
}

export const Container = styled.div`
  padding-bottom: 60px;
  height: 100%;
`;

const Tab = ({ children }: React.PropsWithChildren<TabProps>): React.ReactElement => {
  return <Container>{children}</Container>;
};

export default Tab;
