import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { FormControlLabel } from '@mui/material';
import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const StyledCheckBoxIcon = styled(CheckBoxIcon)`
  color: ${COLORS.BLUE_300};
`;

export const StyledCheckBoxOutlineBlankIcon = styled(CheckBoxOutlineBlankIcon)`
  color: ${COLORS.GRAY_300};
`;

export const StyledLabel = styled(FormControlLabel)`
  color: ${COLORS.GRAY_700};
  height: 17px;

  & span {
    font-family: inherit;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
  }
`;
