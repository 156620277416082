export enum ROLES {
  ADMIN = 'admin',
  FINANCE = 'finance',
}

export enum STATUSES {
  AUTHENTICATION = 401,
  AUTHORIZATION = 403,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  DATA_ERROR = 422,
}
