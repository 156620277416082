import { Loader } from '@private/components';

import { CURRENCIES } from 'api/wallets/constants';
import { CurrencyIcon } from 'components';
import { appReactMemo } from 'hocs';

import { RestAmount, RestInfo, WithdrawalBox } from './styled';

interface IWithdrawalInfoBox {
  balanceData: {
    currencySymbol: CURRENCIES;
    balance: number;
  }[];
  actionContent: React.ReactElement;
  isLoading: boolean;
  title: string;
}

const WithdrawalInfoBox = ({
  balanceData,
  actionContent,
  isLoading,
  title,
}: IWithdrawalInfoBox): React.ReactElement => {
  return (
    <WithdrawalBox>
      <RestInfo>
        {!isLoading ? (
          <>
            <p>{title}</p>
            <RestAmount>
              {balanceData.map((item, key) => (
                <CurrencyIcon key={key} currency={item.currencySymbol}>
                  {item.balance}
                  &nbsp;
                  {item.currencySymbol}
                </CurrencyIcon>
              ))}
            </RestAmount>
          </>
        ) : (
          <Loader />
        )}
      </RestInfo>
      {actionContent}
    </WithdrawalBox>
  );
};

export default appReactMemo(WithdrawalInfoBox);
