import React from 'react';

import api from 'api';
import { CURRENCIES, CURRENCIES_TYPE } from 'api/wallets/constants';
import {
  IPreparedTransactionBTC,
  IPreparedTransactionNONCE,
  ISendTransactions,
  TPreparedTransaction,
} from 'api/wallets/types';
import { IBaseAxiosError } from 'constants/types';
import useLoggerControl from 'hooks/useLogger';
import { useSecurityModal } from 'modals/SecurityModal';
import { useWithdrawalModal } from 'modals/WithdrawalDetails';
import { useGetSeed } from 'queries/session/useBackup';
import { usePrepareTransaction, useSendTransaction } from 'queries/transactions';
import { useCurrenciesQuery } from 'queries/wallets';
import { waitFor } from 'utils/common';
import { getSeedFromSecret } from 'utils/crypto/backups';
import { signEthTransaction } from 'utils/crypto/transactions-nonce';
import { signUSDTTransaction } from 'utils/crypto/transactions-usdt';
import HdTx from 'utils/crypto/transactions-utxo';
import { NOTIF_TYPES, notificationService } from 'utils/notifications';

import { useAppSelector } from '../useStore';

type TUseWithdrawalResult = {
  startWithdrawal: (headquarterID: string) => void;
  isPreparing: boolean;
};

interface IHash {
  rawTransaction: string;
  fee: number | string;
}

const useWithdrawal = (): TUseWithdrawalResult => {
  const [showModal, closeModal] = useSecurityModal();
  const [showWithdrawalModal] = useWithdrawalModal();

  const { sendLogData } = useLoggerControl();

  const currency = useAppSelector((state) => state.userSettings.selectedCurrency);
  const { data: currencies } = useCurrenciesQuery();
  const currentCurrency = React.useMemo(
    () => currencies?.find((item) => item.currency === currency),
    [currencies, currency]
  );

  const { mutateAsync: getSeed } = useGetSeed();
  const { isLoading: isPreparing, mutateAsync: prepareTransaction } =
    usePrepareTransaction();
  const { mutateAsync: sendTransaction } = useSendTransaction();

  const getPreparedTransactions = React.useCallback(
    async ({
      groupID,
      seed,
      initialTrx,
    }: {
      groupID: string;
      seed: string;
      initialTrx: TPreparedTransaction;
    }) => {
      let lastTransaction = false;
      let processedInputs = 0;
      const arrayOfHashes: IHash[] = [];
      const systemTransactions: ISendTransactions[] = [];
      while (!lastTransaction) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const transaction = !processedInputs
            ? initialTrx
            : await prepareTransaction({
                groupID,
                skipAmount: processedInputs,
                currencySymbol: currency,
                currencyType: currentCurrency?.type || CURRENCIES_TYPE.UTXO,
              });

          // Handling BTC transactions (utxo)
          if (currentCurrency?.currency.includes(CURRENCIES.BTC)) {
            const castedTrx = transaction as IPreparedTransactionBTC;
            const blockchainTrx = new HdTx(
              castedTrx,
              currentCurrency?.network,
              currentCurrency?.index
            );
            const rawTransaction = blockchainTrx.generateTx(seed);
            arrayOfHashes.push({ rawTransaction, fee: castedTrx.transactionFee });
          }

          // Handling ETH transactions (nonce)
          if (currentCurrency?.type === CURRENCIES_TYPE.NONCE) {
            const castedTrx = transaction as IPreparedTransactionNONCE;
            let signFn = signEthTransaction;
            if (
              currentCurrency.systemSymbol?.includes(CURRENCIES.ETH) ||
              currentCurrency.currency.includes(CURRENCIES.ETH)
            ) {
              signFn = signEthTransaction;
            } else if (
              currentCurrency.systemSymbol?.includes(CURRENCIES.TRX) ||
              currentCurrency.currency.includes(CURRENCIES.USDT)
            ) {
              signFn = signUSDTTransaction;
            }
            const signedTransactions = signFn({
              seed,
              transactions: castedTrx.hdWallets,
              currencyIndex: currentCurrency?.index,
              network: currentCurrency?.network,
            });
            const signedSystemTransactions = signFn({
              seed,
              transactions: castedTrx.systemWallets,
              currencyIndex: currentCurrency?.index,
              network: currentCurrency?.network,
            });
            arrayOfHashes.push(...signedTransactions);
            systemTransactions.push(...signedSystemTransactions);
          }

          processedInputs += transaction.inputsCount;
          lastTransaction = !transaction.isExistMore;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          return { data: null, error: e?.response?.data?.message || e.message };
        }
      }
      return { data: arrayOfHashes, systemTransactions, error: null };
    },
    [prepareTransaction, currentCurrency]
  );

  const startSecurityVerification = React.useCallback(
    (groupID: string, preparedTrx: TPreparedTransaction) => {
      let seedRemaining = '';
      showModal({
        onOtpSend: async ({ otp, onError, goSeedStep }) => {
          const response = await getSeed(
            { otp },
            {
              onError,
              onSuccess: goSeedStep,
            }
          );
          seedRemaining = response.seed;
        },
        onSeedSend: async ({ seed, onError }) => {
          const notifID = notificationService.show({
            type: NOTIF_TYPES.LOADING,
            message: 'Processing transaction',
          });

          const errorNotifConfig = {
            type: NOTIF_TYPES.ERROR,
            message: 'Transaction failed',
            updateId: notifID,
          };

          const composedSeed = getSeedFromSecret(seed, seedRemaining);
          const signedTransactions = await getPreparedTransactions({
            groupID,
            seed: composedSeed,
            initialTrx: preparedTrx,
          });

          if (signedTransactions.error) {
            notificationService.show({
              ...errorNotifConfig,
              message: signedTransactions.error,
            });

            onError?.();

            sendLogData({
              errors: signedTransactions.error,
            });

            return;
          }

          if (!signedTransactions.data?.length) {
            notificationService.show(errorNotifConfig);
            return;
          }

          if (signedTransactions.systemTransactions.length) {
            try {
              await sendTransaction({
                notifID,
                groupId: Number(groupID),
                currencyType: currentCurrency?.type || CURRENCIES_TYPE.UTXO,
                currencySymbol: currency,
                transactions: signedTransactions.systemTransactions,
                isSystem: true,
              });

              const isStatusOk = await waitFor({
                callback: () =>
                  api.wallets.getSystemTransactionStatus({
                    groupID,
                    currencySymbol: currentCurrency?.currency || CURRENCIES.BTCTEST,
                  }),
                predicate: (data) => data.isCompleted,
                onError: (error) => {
                  const errorMsg =
                    (error as IBaseAxiosError)?.response?.data?.message || '';
                  notificationService.show({
                    type: NOTIF_TYPES.ERROR,
                    message: `Could not fetch system transaction status. ${errorMsg}`,
                    updateId: notifID,
                  });
                },
              });
              if (!isStatusOk) {
                return;
              }
              notificationService.show({
                type: NOTIF_TYPES.LOADING,
                message:
                  'System transactions processed. Processing the withdrawal transactions...',
                updateId: notifID,
              });
            } catch {
              return;
            }
          }

          try {
            await sendTransaction(
              {
                notifID,
                groupId: Number(groupID),
                currencyType: currentCurrency?.type || CURRENCIES_TYPE.UTXO,
                currencySymbol: currency,
                transactions: signedTransactions.data,
              },
              {
                onSettled: closeModal,
              }
            );
          } catch {
            return undefined;
          }
        },
      });
    },
    [getPreparedTransactions]
  );

  const startWithdrawal = React.useCallback(
    async (groupID: string) => {
      showWithdrawalModal({
        groupID,
        onConfirm: (preparedTrx) => startSecurityVerification(groupID, preparedTrx),
        currency,
        currencyType: currentCurrency?.type || CURRENCIES_TYPE.UTXO,
      });
    },
    [startSecurityVerification, currency, currentCurrency]
  );

  return {
    startWithdrawal,
    isPreparing,
  };
};

export default useWithdrawal;
