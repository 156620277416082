export const downloadCSV = (content: string, fileName: string): void => {
  const a = document.createElement('a');
  const mimeType = 'text/csv;encoding:utf-8';

  const blob = new Blob([content], {
    type: mimeType,
  });

  // eslint-disable-next-line
  // @ts-ignore
  if (window.navigator.msSaveBlob) {
    // IE10
    // eslint-disable-next-line
    // @ts-ignore
    window.navigator.msSaveBlob(blob, fileName);
  } else if (URL && 'download' in a) {
    // html5 A[download]
    a.href = URL.createObjectURL(blob);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    // only this mime type is supported
    window.location.href = `data:application/octet-stream,${encodeURIComponent(content)}`;
  }
};

export const fileConfig = {
  maxSize: 10,
  type: '.csv',
};
