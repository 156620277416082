import { axiosInstance } from '../base';

import { IAppHealth } from './type';

const commonAPI = {
  async checkHealth(): Promise<IAppHealth> {
    const result = await axiosInstance.get('/health', { timeout: 15000 });
    return result.data;
  },
};

export default commonAPI;
