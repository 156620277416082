import React from 'react';

import { CURRENCIES } from 'api/wallets/constants';
import { CopyText } from 'components';
import { useConfirmModal } from 'modals/ConfirmModal';
import { useSecurityModal } from 'modals/SecurityModal';
import { useGetSeed } from 'queries/session/useBackup';
import { useCurrenciesQuery } from 'queries/wallets';
import { getSeedFromSecret } from 'utils/crypto/backups';
import { getLeafNode } from 'utils/crypto/leafNode';
import { ellipsisText } from 'utils/ellipsisText';
import { NOTIF_TYPES, notificationService } from 'utils/notifications';

import { StyledMessage } from './styled';

interface IUseGetWalletPrivateKey {
  address: string;
  currencySymbol: CURRENCIES;
  firstIndexToDerive: number;
  secondIndexToDerive: number;
  onGetPrivateKey?: (privateKey: string) => void;
}

interface IUseGetWalletPrivateKeyResult {
  startPreparingPrivateKey: (data: IUseGetWalletPrivateKey) => void;
}

const useWalletPrivateKey = (): IUseGetWalletPrivateKeyResult => {
  const [showModal] = useSecurityModal();
  const [showConfirmModal] = useConfirmModal();
  const { mutateAsync: getSeed } = useGetSeed();
  const { data: currencies } = useCurrenciesQuery();

  const startPreparingPrivateKey = React.useCallback(
    ({
      address,
      currencySymbol,
      firstIndexToDerive,
      secondIndexToDerive,
      onGetPrivateKey,
    }: IUseGetWalletPrivateKey): string | void => {
      const currentCurrency = currencies?.find(
        (item) => item.currency === currencySymbol
      );

      let seedRemaining = '';

      showModal({
        onOtpSend: async ({ otp, onError, goSeedStep }) => {
          const response = await getSeed(
            { otp },
            {
              onError,
              onSuccess: goSeedStep,
            }
          );

          seedRemaining = response.seed;
        },
        onSeedSend: async ({ seed }) => {
          if (!currentCurrency) {
            notificationService.show({
              type: NOTIF_TYPES.ERROR,
              message: 'Preparing private key is failed',
            });

            return;
          }

          const composedSeed = getSeedFromSecret(seed, seedRemaining);
          let privateKey;

          const leafNode = getLeafNode({
            currencyIndex: currentCurrency.index,
            firstIndexToDerive,
            secondIndexToDerive,
            seed: composedSeed,
          });

          if (leafNode.privateKey) {
            privateKey = Buffer.from(leafNode.privateKey).toString('hex');
          }

          if (onGetPrivateKey) {
            onGetPrivateKey(privateKey);

            return;
          }

          const message = (
            <div>
              <StyledMessage>
                Wallet&nbsp;
                {ellipsisText(address, 5, 5)}
                &nbsp;has the following private key
              </StyledMessage>
              <CopyText disableShrink text={privateKey} />
            </div>
          );

          showConfirmModal({
            title: 'Private Key',
            message,
            onConfirm: () => {},
            isHideRightButton: true,
          });
        },
      });
    },
    [currencies]
  );

  return { startPreparingPrivateKey };
};

export default useWalletPrivateKey;
