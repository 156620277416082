import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { TButtonTypes } from '@private/components';

import Button from 'components/Button';
import { appReactMemo } from 'hocs';

import {
  ButtonStyled,
  Content,
  ContentContainer,
  FooterStyled,
  HeaderStyled,
  StyledLoader,
  TitleStyled,
} from './styled';

export interface IModalContainerProps {
  title: string;
  leftBtnText?: string;
  leftBtnVariant?: TButtonTypes;
  rightBtnText?: string;
  rightBtnVariant?: TButtonTypes;
  disableLeftBtn?: boolean;
  disableRightBtn?: boolean;
  onLeftAction: () => void;
  onRightAction: () => void;
  onDismiss: () => void;
  enableLoadingIndicator?: boolean;
  isLoading?: boolean;
  isHideRightButton?: boolean;
}

const ModalContainer = ({
  title,
  onLeftAction,
  onRightAction,
  onDismiss,
  disableLeftBtn,
  disableRightBtn,
  leftBtnText = 'Close',
  leftBtnVariant = 'transparent',
  rightBtnText = 'Next',
  rightBtnVariant = 'primary',
  children,
  enableLoadingIndicator = false,
  isLoading = false,
  isHideRightButton = false,
}: React.PropsWithChildren<IModalContainerProps>) => (
  <Content>
    {enableLoadingIndicator && <StyledLoader isVisible={isLoading} />}
    <ContentContainer>
      <HeaderStyled>
        <TitleStyled>{title}</TitleStyled>
        <Button variant='iconSolid' onClick={onDismiss} startIcon={<CloseIcon />} />
      </HeaderStyled>
      {children}
      <FooterStyled>
        <ButtonStyled
          variant={leftBtnVariant}
          disabled={disableLeftBtn}
          onClick={onLeftAction}
        >
          {leftBtnText}
        </ButtonStyled>
        {!isHideRightButton && (
          <ButtonStyled
            variant={rightBtnVariant}
            disabled={disableRightBtn}
            onClick={onRightAction}
          >
            {rightBtnText}
          </ButtonStyled>
        )}
      </FooterStyled>
    </ContentContainer>
  </Content>
);

export default appReactMemo(ModalContainer);
