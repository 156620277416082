import React from 'react';

export const SecurityContext = React.createContext({
  goNext: () => {},
  goBack: () => {},
  secondFactorSecret: '',
});

export const useSecurityContext = (): React.ContextType<typeof SecurityContext> =>
  React.useContext(SecurityContext);
